class AppConf{


    configs(){
        const variables = {
            //"appurl":"http://localhost:6755/",
            "appurl":"https://api.tecgeoservice.com.br/",
        }
        return variables;
    }

    checkAcl(rule, acls){

        if(acls){
            let permitido = acls.filter((acl) => {
                return acl.codigo_recurso === rule;
            })
    
            if(permitido.length > 0){
                return "yes";
            }else{
                return "no";
            }
        }else{
            return "no";
        }
        
    }

    urlFull(relativePath) {
        // const url = '/'; 
        const url = `/geoitbi/${relativePath}`; 
        return url;
    }
   
}
  
  const appConfig = new AppConf();
  export default appConfig;