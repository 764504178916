import React from 'react';
import {Modal, Button}  from "react-bootstrap";
import './style.css'

class Sobre extends React.Component {

    /*constructor(props) {
        super(props);
    }*/

    render() {
    
        return (
            <>
                <Modal
                    show={true}
                    onHide={e => this.props.openSobre(e)}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Sobre</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <h5>Geoportal de ITBI da Prefeitura Municipal de João Pessoa</h5>
                        <a href="https://www.joaopessoa.pb.gov.br/" rel="noopener noreferrer" target="_blank">
                            <img src="/geoitbi/logoapp.png" width="147" height="77" alt="logo pmjp"/>
                        </a>

                        <p className="p-2">
                            Este é o Geoportal de ITBI 
                            um sistema de informações geográficas que viabiliza a
                            espacialização das operações de ITBI no munícipio.
                            Possui ferramentas específicas para Avaliação do Valor do Imóvel,
                            Pesquisas de Mercado e Análise de Transmissões além de outros
                            widgets no suporte ao georreferenciamento das informações.
                        </p>
                        <hr/>
                       
                        <h6> <strong>Desenvolvimento:</strong> <a href="http://tecgeobr.com.br" target="_blank" rel="noopener noreferrer">Tecgeo | Tecnologia em Geoprocessamento.</a></h6>
                        <a href="http://tecgeobr.com.br" target="_blank" rel="noopener noreferrer">
                            <img src="/geoitbi/logoTecgeo.png" alt="logo tecgeo"/>
                        </a>

                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={e => this.props.openSobre(e)}>
                        Fechar
                    </Button>
                    
                    </Modal.Footer>
                </Modal>
            </>
        );
    }  
}


export default Sobre;