import appconfig from "../../config";

const popupservices = [
    {
        title: "ZEIS",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/9",
        geometry: "esriGeometryPoint",
        order: 9
    },
    {
        title: "Setores",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/8",
        geometry: "esriGeometryPoint",
        order: 8
    },
    {
        title: "Quadras",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/7",
        geometry: "esriGeometryPoint",
        order: 7
    },
    {
        title: "Perímetro Urbano Rural",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/6",
        geometry: "esriGeometryPoint",
        order: 6
    },
    {
        title: "Parques Municipais",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/5",
        geometry: "esriGeometryPoint",
        order: 5
    },
    {
        title: "Lotes",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/4",
        geometry: "esriGeometryPoint",
        order: 1
    },
    {
        title: "Logradouros",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/3",
        geometry: "esriGeometryEnvelope",
        order: 3
    },
    {
        title: "Comunidades",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/2",
        geometry: "esriGeometryPoint",
        order: 2
    },{
        title: "Zonas Homogeneas Setorizacao",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/ITBI_Mapas/FeatureServer/2",
        geometry: "esriGeometryPoint",
        order: 100
    },
    {
        title: "Bairros",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/1",
        geometry: "esriGeometryPoint",
        order: 4
    },
    {
        title: "Sistema Ciclo Viário",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/16",
        geometry: "esriGeometryEnvelope",
        order: 16
    },
    {
        title: "Paradas de Ônibus",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/15",
        geometry: "esriGeometryEnvelope",
        order: 15
    },
    {
        title: "Itinerários de Ônibus",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/14",
        geometry: "esriGeometryPolyline",
        order: 14
    },
    {
        title: "Faixa Exclusiva de Ônibus",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/13",
        geometry: "esriGeometryEnvelope",
        order: 13
    },
    {
        title: "Equipamento Eletrônicos",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/12",
        geometry: "esriGeometryEnvelope",
        order: 12
    },
    {
        title: "Corredores de Ônibus",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/11",
        geometry: "esriGeometryEnvelope",
        order: 11
    },
    {
        title: "Feature Layer",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/29",
        geometry: "esriGeometryEnvelope",
        order: 29
    },
    {
        title: "Feature Layer",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/28",
        geometry: "esriGeometryEnvelope",
        order: 28
    },
    {
        title: "Equipamentos de Saúde",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/27",
        geometry: "esriGeometryEnvelope",
        order: 27
    },
    {
        title: "Equipamentos de Educação",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/26",
        geometry: "esriGeometryEnvelope",
        order: 26
    },
    {
        title: "Cemitério",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/25",
        geometry: "esriGeometryEnvelope",
        order: 25
    },
    {
        title: "Zoneamento Fev/2012",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/38",
        geometry: "esriGeometryPoint",
        order: 38
    },
    {
        title: "Barreira Cabo Branco",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/37",
        geometry: "esriGeometryPoint",
        order: 37
    },
    {
        title: "Solos",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/36",
        geometry: "esriGeometryPoint",
        order: 36
    },
    {
        title: "Orla Marítima 500m 2011/2012",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/35",
        geometry: "esriGeometryEnvelope",
        order: 35
    },
    {
        title: "Macrozoneamento Fev/2012",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/34",
        geometry: "esriGeometryPoint",
        order: 34
    },
    {
        title: "JP Geologia",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/33",
        geometry: "esriGeometryPoint",
        order: 33
    },
    {
        title: "Centro Histórico IPHAN",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/32",
        geometry: "esriGeometryPoint",
        order: 32
    },
    {
        title: "Centro Histórico IPHAEP",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/31",
        geometry: "esriGeometryPoint",
        order: 31
    },
    {
        title: "Corais Oceano",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/18",
        geometry: "esriGeometryPoint",
        order: 18
    },
    {
        title: "Curvas de Nível 1m 1998",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/19",
        geometry: "esriGeometryPolyline",
        order: 19
    },
    {
        title: "Potenciais Nascentes",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/20",
        geometry: "esriGeometryEnvelope",
        order: 20
    },
    {
        title: "Bacias Hidrográficas",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/21",
        geometry: "esriGeometryPoint",
        order: 21
    },
    {
        title: "Rios Municipais",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/22",
        geometry: "esriGeometryEnvelope",
        order: 22
    },
    {
        title: "Sub Bacias Hidrográficas",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/23",
        geometry: "esriGeometryPoint",
        order: 23
    },
    {
        title: "SEDES",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/28",
        geometry: "esriGeometryEnvelope",
        order: 28
    },
    {
        title: "SEJER",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/29",
        geometry: "esriGeometryEnvelope",
        order: 29
    },
    {
        title: "Zonas Homogêneas Setorização",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/ITBI_Mapas/FeatureServer/2",
        geometry: "esriGeometryPoint",
        order: 100
    },
    {
        title: "Rendimento Médio Mensal - Censo 2010",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/ITBI_Mapas/FeatureServer/1",
        geometry: "esriGeometryPoint",
        order: 101
    },
    {
        title: "Pesquisas de Mercado Ofertas",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/amostras_ofertas_14122021/FeatureServer/0",
        geometry: "esriGeometryEnvelope",
        order: 102
    },
    {
        title: "Curvas_de_Nivel_Geral_JP",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA_2021/FeatureServer/0",
        geometry: "esriGeometryEnvelope",
        order: 103
    },
    {
        title: "Limites das Plantas de Loteamentos",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/Plantas_Loteamento/FeatureServer/0",
        geometry: "esriGeometryEnvelope",
        order: 105
    },
    {
        title: "Vilas",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/39",
        geometry: "esriGeometryPoint",
        order: 106
    },
    {
        title: "teste_360",
        url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/teste_360/FeatureServer/0",
        geometry: "esriGeometryPoint",
        geometry: "esriGeometryEnvelope",
        order: 110
    }
];
const config = appconfig.configs();
async function getListLayers () {
	const layers = await fetch(`${config.appurl}lotes/listar/camadas`, {
		method: 'POST'
	}).then( resp => resp.json() )
	return layers
}

export default getListLayers;