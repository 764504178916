import React, {useState} from 'react';

export const ViewContext = React.createContext({});

export const ViewProvider = (props) => {

    const [view] = useState(null)

    return (
        <ViewContext.Provider value={view}>
            {props.children}
        </ViewContext.Provider>
    )

}