import React from 'react';
// import './styles.css'

class Dashboard extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			panUrl: 'https://experience.arcgis.com/experience/3f248a5b58864fe481e16dd564a4c530/?draft=true',
		}
	}


	render() {

		return (
			<iframe title="GoogleStreetView"  allowtransparency="yes" src={this.state.panUrl} width="100%" height="100%"></iframe>
		);

	}
}

export default Dashboard;