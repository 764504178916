import { loadModules } from 'esri-loader';
import React from 'react';
import { Button, ButtonGroup, InputGroup } from "react-bootstrap";
import { MdClose } from 'react-icons/md';
import { BsArrowsMove } from 'react-icons/bs';
import Config from '../../config';
import './styles.css'

class Photoview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			panUrl: null,
			streetView: false
		}

		this.modal = null;
		this.modalBodySV = null;
		this.moveWindowSV = null;
		this.viewDiv = null;
		this.centerLon = null;
		this.centerLat = null;
		this.pointGraphic = null;


		//Configuracoes
		this.config = Config.configs()
	}

	async componentDidMount() {

		try {
			this.modal = document.querySelector(".svwindow");
			this.modalBodySV = document.getElementById("modalBodySV");
			this.moveWindowSV = document.getElementById("moveWindowSV");
			this.viewDiv = document.getElementById("tecgeo__viewDiv");

			//Actions to opne the popup
			//this.props.view.graphics = null;
			this.modal.style.display = 'none';

			this.popupElement(this.modal, 12, 12);

			let lat = -7.0492535;
			let lng = -34.8490157;
			const images = [
				"http://162.214.66.188:3355/public/224578_511367250_00219828306420.jpg",
				"http://162.214.66.188:3355/public/224578_511367250_00219824008797.jpg",
				"http://162.214.66.188:3355/public/224578_511367250_00219828423544.jpg",
				"http://162.214.66.188:3355/public/224578_511367250_00219823202781.jpg"
			]

			this.setState({
				panUrl: "photoview.html?left=" + images[0] + "&front=" + images[1] + "&right=" + images[2] + "&back=" + images[3]
			});


			this.props.view.on("click", async (event) => {
				//console.log(event)

				this.centerLon = event.mapPoint.longitude;
				this.centerLat = event.mapPoint.latitude;
				const xmin = Number(event.mapPoint.x) - 1
				const ymin = Number(event.mapPoint.y) - 1
				const xmax = Number(event.mapPoint.x)
				const ymax = Number(event.mapPoint.y)
				const envelope = xmin + "," + ymin + "," + xmax + "," + ymax;
				this.showPoint(event.mapPoint);
				console.log({ envelope })
				const body = {
					inSR: 102100,
					outSR: 102100,
					geometryType: "esriGeometryEnvelope",
					geometry: envelope,
					url: "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/teste_360/FeatureServer/4/query",
					title: "Mapeamento Móvel 360",
					email: localStorage.getItem('email')
				};

				const settings = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				};
				const url = `${this.config.appurl}lotes/mostrar/camada`;
				const response = await fetch(url, settings)
					.then((res) => {
						return res.json()
					})
				console.log(response)
				if (response.length === 0) {
					return;
				}
				const images = [
					`http://162.214.66.188:3355/public/${response[0][1].value}`,
					`http://162.214.66.188:3355/public/${response[0][2].value}`,
					`http://162.214.66.188:3355/public/${response[0][3].value}`,
					`http://162.214.66.188:3355/public/${response[0][4].value}`,
				]
				console.log(images)


				this.setState({
					panUrl: "photoview.html?left=" + images[0] + "&front=" + images[1] + "&right=" + images[3] + "&back=" + images[2]
				});


				//if(!this.pointGraphic){
				// this.showPoint(event.mapPoint);
				//}


			})

			window.addEventListener('message', this.handleIframeLoading, false);

			this.viewDiv.style.cursor = "crosshair";
			// this.props.view.graphics = null;
			// this.props.view.graphics.remove(this.pointGraphic);
			//this.pointGraphic = null;


		} catch (e) {
			console.log("Não foi possivel carregar o popup " + e)
		}
	}

	popupElement = (el, cx, cy) => {
		//console.log(el)

		if (!el.classList.contains("anim")) {
			el.classList.add("anim");
		}

		this.modalBodySV.style.display = "block";

		el.style.display = 'block';

		let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

		el.style.top = cy + "px";
		el.style.left = cx + "px";

		//This action avoid that Window Screen overflow
		let view = document.getElementById("root")
		let viewWidth = view.clientWidth;
		let viewHeight = view.clientHeight;

		//console.log(el.offsetTop)
		if (el.offsetTop > (viewHeight - el.clientHeight)) {
			//bottom
			el.style.top = (viewHeight - el.clientHeight - 3) + "px";
		}

		if (el.offsetTop < 0) {
			//top
			el.style.top = "2px";
		}

		//console.log(el.offsetLeft)
		if (el.offsetLeft > (viewWidth - el.clientWidth)) {
			//right
			el.style.left = (viewWidth - el.clientWidth - 3) + "px";
		}

		if (el.offsetLeft < 0) {
			//left
			el.style.left = "2px";
		}



		if (this.moveWindowSV) {
			this.moveWindowSV.onmousedown = dragMouseDown;
		} else {
			el.onmousedown = dragMouseDown
		}

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closepopupElement;
			document.onmousemove = elementDrag;
		}
		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;

			el.style.top = (el.offsetTop - pos2) + "px";
			el.style.left = (el.offsetLeft - pos1) + "px";
			el.style.opacity = 0.85

			//Avoid Modal screen overflow
			if (el.offsetTop > (viewHeight - el.clientHeight)) {
				//bottom
				el.style.top = (viewHeight - el.clientHeight - 3) + "px";
			}

			if (el.offsetTop < 0) {
				//top
				el.style.top = "2px";
			}

			if (el.offsetLeft > (viewWidth - el.clientWidth)) {
				//right
				el.style.left = (viewWidth - el.clientWidth - 3) + "px";
			}

			if (el.offsetLeft < 0) {
				//left
				el.style.left = "2px";
			}

		}
		function closepopupElement() {
			document.onmouseup = null;
			document.onmousemove = null;
			el.style.opacity = 1
		}
	}

	showPoint = async (mapPoint) => {
		try {
			this.props.view.graphics = null;

			const [Graphic] = await loadModules([
				"esri/Graphic",
			]);

			//console.log(mapPoint)

			var point = {
				type: "point",
				hasZ: true,
				hasM: true,
				x: mapPoint.x,
				y: mapPoint.y,
				spatialReference: { wkid: mapPoint.spatialReference.wkid }
			};

			var pointGraphic = new Graphic({
				geometry: point,
				symbol: {
					type: "simple-marker",
					size: 8,
					color: "#212529",
					outline: {
						color: "rgb(33, 37, 41)",
						width: 5
					}
				}
			});

			this.props.view.graphics.remove(pointGraphic);
			this.props.view.graphics.add(pointGraphic);
		} catch (e) {
			console.log("Não foi possível adicionar o ponto")
		}

	}

	handleIframeLoading = (event) => {
		try {
			//console.log(event)
			var lat = event.data[0];
			var lng = event.data[1];
			var foto = event.data[2];

			var point = {
				type: "point", // autocasts as new Point()
				latitude: lat,
				longitude: lng
			};
			if (this.pointGraphic) {
				//this.props.view.center = [lng, lat];
				this.pointGraphic.symbol.url = foto;
				this.pointGraphic.geometry = point;
			} else {
				this.showPoint({
					mapPoint: {
						latitude: lat,
						longitude: lng,
					}
				})

			}
		} catch (e) {
			console.log("handleIframeLoading - Não foi possível carregar")
		}

	}


	componentWillUnmount() {
		this.props.view.graphics = null;
		this.setState = (state, callback) => {
			return;
		};
		this.viewDiv.style.cursor = "default";

		//this.pointGraphic = null
	}

	render() {

		return (
			<>
				<section className="svwindow anim">
					<div id="modalHeaderSV">
						<ButtonGroup size="sm" as={InputGroup.Prepend}>
							<Button id="moveWindowSV" variant="secondary" title="Mover Janela" ><BsArrowsMove size={16} color="#fafafa" /> </Button>
							<Button variant="secondary" title="Fechar" onClick={this.props.closeSV} ><MdClose size={16} color="#fafafa" /> </Button>
						</ButtonGroup>
					</div>

					<div id="modalBodySV">
						<iframe title="GoogleStreetView" frameBorder="0" allowtransparency="yes" scrolling="no" src={this.state.panUrl} width="100%" height="100%"></iframe>;
					</div>
				</section>
			</>
		);

	}
}

export default Photoview;