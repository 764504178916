import React from 'react';
import { loadModules } from 'esri-loader';
import './style.css';

//import esrilayers from './layers.js';
//import webmapjson from './webmap_json';
//import esrisearch from './search.js';
import esrilayerlist from './layerlist.js';
import Photoview from '../photoview';
import esribasemap from './basemap.js';
import esriprint from './print.js';
import esricoordinates from './coordinates.js';
import esrimeasurement from './measurement.js';

import Perfil from '../Perfil';
import Sobre from '../Sobre';
import Sair from '../Sair';
import AvaliacaoImovel from '../AvaliacaoImovel';
import PesquisaMercado from '../PesquisaMercado';
import Transmissoes from '../Transmissoes';
// import ElevationProfile from '../ElevationProfile';
import StreetView from '../StreetView';
import Dashboard from '../Dashboard';

import Popup from '../Popup';
import Header from '../Header';
import PesquisaRapida from '../PesquisaRapida';

import {Navbar, Nav, Button, ButtonGroup, InputGroup, FormControl, DropdownButton, Dropdown}  from "react-bootstrap";
import {MdFullscreen, MdClose,  MdFormatShapes,  MdLayers, MdAssessment, MdDashboard } from 'react-icons/md';
import {FaRuler, FaTrashAlt, FaList} from "react-icons/fa";

import { BiListCheck } from "react-icons/bi";
import ScaleLoader from "react-spinners/ScaleLoader";
import Config from '../../config';
import AvaliacaodeImovelPorIa from '../AvaliacaodeImovelPorIa';

//import { ViewContext } from './context';

class EsriMap extends React.Component {

      constructor(props) {
        super(props);
        this.mapRef = React.createRef();

        this.tecgeo__centered = React.createRef();
        this.tecgeo__loadingMap = React.createRef();
        this.sidebarWidgets = React.createRef();
        this.sidebarLayers = React.createRef();

        this.layers_wb_container = React.createRef();
        this.layers_switch_btn = React.createRef();

        this.collapseList = React.createRef();
        this.collapseLegend = React.createRef();
        this.collapseBasemap = React.createRef();
        this.collapsePrint = React.createRef();
        this.collapseCoordinates = React.createRef();
        this.collapseMeasurement = React.createRef();
        this.measurementTools = React.createRef();
        this.collapsePanel = React.createRef();

        this.showLayers = this.showLayers.bind(this);
        this.showLegend = this.showLegend.bind(this);
        this.showBasemaps = this.showBasemaps.bind(this);
        this.showPrint = this.showPrint.bind(this);
        this.showCoordinates = this.showCoordinates.bind(this);
        this.showMeasurement = this.showMeasurement.bind(this);
        this.showAvaliacaoImovel = this.showAvaliacaoImovel.bind(this);
        this.showPesquisaMercado = this.showPesquisaMercado.bind(this);
        this.showTransmissoes = this.showTransmissoes.bind(this);
        this.showStreetView = this.showStreetView.bind(this);
        this.showPhotoview = this.showPhotoview.bind(this);

        //Filtrar Layers
        this.filterLayer = this.filterLayer.bind(this);
        this.layerOn = this.layerOn.bind(this);
        this.layerOff = this.layerOff.bind(this);
        this.dontExpand = this.dontExpand.bind(this);
        this.expand = this.expand.bind(this);

        this.basem = null;
        this.printer = null;
        this.coordinates = null;
        this.measurement = null;
        this.avaliacaoImovel = false;
        this.pesquisaMercado = false;
        this.transmissoes = false;
        this.avaliacaodeImovelPorIA = null;

        this.srch = null;
        this.view = null;


        this.pesquisaRapidaWidget = null;

        this.state = {
          perfilAction: false,
          sobreAction:false,
          layerLegendTabs:'legend',
          wtitle: "Painel",
          cdtitle: "Camadas",
          fullscreenOn: false,
          classMax: 'sidenav',
          classMaxLayers: 'sidenavLayers',
          maxStatus: false,
          maxLayersStatus: false,
          dashboard: false,
          dashboard1: false,
          sairAction:false,
          username:localStorage.getItem('nome'),
          dsqf:null,
          popup:false,
          popupMapPoint:null,
          streetView:false,
          photoview: false,
          streetViewAcl:false,
          streetViewClass:null,
          popupMapPointSV:null,
          tableDataResults: [],
        };

        //Configuracoes
        this.config = Config.configs()

      }

      async componentDidMount(){

        /** ESRI */
        const [WebMap, MapView, watchUtils, Home, ScaleBar, Legend] = await loadModules([
          "esri/WebMap",
          'esri/views/MapView',
          'esri/core/watchUtils',
          'esri/widgets/Home',
          'esri/widgets/ScaleBar',
          'esri/widgets/Legend'
        ], { css: true });

        //const layersm = await esrilayers();
        // const webmapj = await webmapjson.services();
        // const map = WebMap.fromJSON(webmapj);

        // map.basemap = 'streets-navigation-vector';

        const map = new WebMap({
          // basemap: 'osm-streets-relief',
          // basemap: 'streets-vector',
          portalItem: {
            id: "de718e89e65c4649854c97d99cc7fd1c",
            portal: {
              url: "http://arcgis.tecgeobr.com.br/portal"
            }
          }
        });

        /**
         * 
         * popup: {
            defaultPopupTemplateEnabled: true,
            collapseEnabled:false,
            highlightEnabled:true,
            dockEnabled: false,
            dockOptions: {
                buttonEnabled: false,
                breakpoint: false
            }
          },
         */


        this.view = new MapView({
          container: this.mapRef.current,
          map: map,
          center: [-34.856307, -7.163830],
          zoom: 12,
          popup: {
            defaultPopupTemplateEnabled: false,
            collapseEnabled:false,
            autoOpenEnabled: false
          },
          navigation: {
            momentumEnabled: false
          },
          
        });

        //Init Popup Window Drag
        //Popup(this.view)
        //this.viw = this.context;
        //this.viw = this.view
        //console.log(this.viw)

        this.setState({ myview: this.view })
        

        //Init / Loading App
        this.view.when( async () => {

          watchUtils.whenTrueOnce(this.view, "updating", () => {
            this.tecgeo__centered.current.style.display = "block"; 
          });
    
          // Hide the loading indicator when the view stops updating
          watchUtils.whenFalseOnce(this.view, "updating", async() => {
            this.tecgeo__centered.current.style.display = "none";
          });
    
          /*//Display the loading indicator when the view is updating
          watchUtils.whenTrue(this.view, "updating", () => {
            this.tecgeo__loadingMap.current.style.display = "block";
            this.view.ui.add("tecgeo__loadingMap", "top-left");
          });
    
          // Hide the loading indicator when the view stops updating
          watchUtils.whenFalse(this.view, "updating", () => {
            this.tecgeo__loadingMap.current.style.display = "none";
          });*/

          //Layers and Legends

          this.lay = await esrilayerlist(this.view, 'collapseList');
          this.sidebarWidgets.current.style.display = "none";
          this.layers_wb_container.current.style.display = "none";

          let CheckLayers = Config.checkAcl("Layers",this.props.acls) === "yes" ? true : false;
          let CheckLegend = Config.checkAcl("Legend",this.props.acls) === "yes" ? true : false;
          if(CheckLayers && CheckLegend){
            this.collapseLegend.current.style.display =  "none";
            this.collapseList.current.style.display = "block";
          }


          this.setState({ 
            streetViewAcl: Config.checkAcl("StreetView",this.props.acls) === "yes" ? true : false,
            streetViewClass: Config.checkAcl("StreetView",this.props.acls) === "yes" ? "esri-widget--button esri-widget esri-interactive" : "esri-widget--button esri-widget esri-interactive has-hidden"
          })

          this.measurementTools.current.style.display = "none";

          const homeBtn = new Home({view: this.view});
          const scaleBar = new ScaleBar({view: this.view, unit: "dual"});
          new Legend({container:'collapseLegend',view: this.view})

          //this.srch = await esrisearch(this.view);
          this.view.ui.padding= { top: 8, left: 8, right: 8, bottom: 2 };
          this.view.ui.add([
            {
              component: "tecgeo__SearchBar",
              position: "top-left",
              index: 0
            },
            {
              component: homeBtn,
              position: "top-left",
              index: 1
            },
            {
              component: "zoom",
              position: "top-left",
              index: 2
            },
            {
              component: "streetview-switch-btn",
              position: "top-left",
              index: 3
            },
            {
              component: "photoview-switch-btn",
              position: "top-left",
              index: 4
            },
            {
              component: "dashboard-switch-btn",
              position: "top-left",
              index: 5
            },
            // {
            //   component: "dashboard1-switch-btn",
            //   position: "top-left",
            //   index: 6
            // },
            {
              component: scaleBar,
              position: "bottom-left",
              index: 0
            },
            {
              component: "tecgeo__logoTecgeoDiv",
              position: "bottom-right",
              index: 1
            }
          ]);

          this.view.on("click", async (event) => {

            if (event.mapPoint) {

              if (this.state.popup && this.state.popup !== "widget") {
                this.setState({popup: false})
                this.setState({
                  popup: true,
                  popupMapPoint: event,
                })
              }

              if (!this.state.popup && this.state.popup !== "widget") {
                this.setState({
                  popup: true,
                  popupMapPoint: event,
                })
              }
    
              if (!this.state.streetView || this.state.streetView !== "widget") {
                this.setState({
                  popupMapPointSV: event,
                })
              }

              if (!this.state.photoview || this.state.photoview !== "widget") {
                this.setState({
                  popupMapPointSV: event,
                })
              }
            }

          })


        });


      }

    
      openPanel = (panel) =>{
        this.sidebarWidgets.current.style.display = "block";
        this.collapseBasemap.current.style.display = panel === "basemaps" ? 'block' : 'none';
        this.collapsePrint.current.style.display = panel === "print" ? 'block' : 'none';
        this.collapseCoordinates.current.style.display = panel === "coordinates" ? 'block' : 'none';
        this.collapseMeasurement.current.style.display = panel === "measurement" ? 'block' : 'none';
        this.measurementTools.current.style.display = panel === "measurement" ? 'block' : 'none';
        this.collapsePanel.current.style.display = panel === "widgetpanels" ? 'block' : 'none';
        this.avaliacaoImovel = panel === "avaliacaoimovel" ? true : false;
        this.pesquisaMercado = panel === "pesquisamercado" ? true : false;
        this.transmissoes = panel === "transmissoes" ? true : false;
        this.avaliacaodeImovelPorIA = panel === "avaliacaodeImovelPorIA" ? true : false;
        //this.streetView = panel === "streetview" ? true : false;

        if(this.coordinates){
          this.coordinates.mode = "live";
        }
      }

      showLayers = async(title) =>{
        this.sidebarLayers.current.style.display = "block";
        this.layers_wb_container.current.style.display = "none";
        this.collapseLegend.current.style.display = "none";
        this.collapseList.current.style.display = "block";
        this.setState({ cdtitle: title })
      }

      showLegend = async(title) =>{
        this.collapseLegend.current.style.display = "block";
        this.collapseList.current.style.display = "none";
        this.setState({ cdtitle: title })
      }
    
      showBasemaps = async(title, mobile) =>{
        this.openPanel('basemaps');
        this.setState({ wtitle: title })

        if(!this.basem){
          this.basem = await esribasemap(this.view, "collapseBasemap");
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showPrint = async(title, mobile) =>{
        this.openPanel('print');
        this.setState({ wtitle: title })

        if(!this.printer){
          this.printer = await esriprint(this.view, "collapsePrint");
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showAvaliacaodeImovelPorIa = async(title, mobile) =>{
        this.openPanel('avaliacaodeImovelPorIA');
        this.openPanel('widgetpanels');
        
        this.setState({ wtitle: title });

        this.setState({ 
          popup:'widget'
        });
        
       
        if(!this.avaliacaodeImovelPorIA){
          this.avaliacaodeImovelPorIA = true;
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showCoordinates = async(title, mobile) =>{
        this.openPanel('coordinates');
        this.setState({ wtitle: title })

        if(!this.coordinates){
          this.coordinates = await esricoordinates(this.view, "collapseCoordinates");
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showMeasurement = async(title, mobile) =>{
        this.openPanel('measurement');
        this.setState({ wtitle: title })

        if(!this.measurement){
          this.measurement = await esrimeasurement(this.view, "collapseMeasurement");
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showAvaliacaoImovel = async(title, mobile) =>{
        this.openPanel('avaliacaoimovel');
        this.openPanel('widgetpanels');
        
        this.setState({ wtitle: title })

        if(!this.avaliacaoImovel){
          this.avaliacaoImovel = true
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showPesquisaMercado = async(title, mobile) =>{
        this.openPanel('pesquisamercado');
        this.openPanel('widgetpanels');
        
        this.setState({ wtitle: title })

        this.setState({ 
          popup:'widget'
        })

        if(!this.pesquisaMercado){
          this.pesquisaMercado = true
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showTransmissoes = async(title, mobile) =>{
        this.openPanel('transmissoes');
        this.openPanel('widgetpanels');
        
        this.setState({ wtitle: title })

        this.setState({ 
          popup:'widget'
        })
        
       
        if(!this.transmissoes){
          this.transmissoes = true
        }

        if(mobile){
          this.closeMobileMenu();
        }
      }

      showStreetView = async(action) =>{
        //title, mobile
        //console.log(this.StreetView)
        
        if(this.state.streetViewAcl){
          //console.log(action)
          this.setState({ streetView: action })

          if(action){
            this.setState({ 
              popup:'widget'
            })
          }else{
            this.setState({ 
              popup:false
            })
          }
        }


        /*this.openPanel('streetview');
        this.openPanel('widgetpanels');
        
 
        if(!this.streetView){
          this.streetView = true
        }

        if(mobile){
          this.closeMobileMenu();
        }*/
      }

      showPhotoview = async (action) => {
        //title, mobile
        //console.log(this.StreetView)
    
        if (this.state.streetViewAcl) {
          //console.log(action)
          
          this.setState({ photoview: action })
    
          if (action) {
            this.setState({
              popup: 'widget'
            })
          } else {
            this.setState({
              popup: false
            })
          }
        }
    
      }

      showDashboard = async (action) => {
        //title, mobile
        //console.log(this.StreetView)
    
        if (this.state.streetViewAcl) {
          //console.log(action)
          
          this.setState({ dashboard: action })
    
          if (action) {
            this.setState({
              popup: 'widget'
            })
            // window.open("https://experience.arcgis.com/experience/3f248a5b58864fe481e16dd564a4c530/?draft=true", '_blank');
            window.open(Config.urlFull("dash"), '_blank');
          } else {
            this.setState({
              popup: false
            })
          }
        }
    
      }

      showDashboard1 = async (action) => {
        //title, mobile
        //console.log(this.StreetView)
    
        if (this.state.streetViewAcl) {
          //console.log(action)
          
          this.setState({ dashboard1: action })
    
          if (action) {
            this.setState({
              popup: 'widget'
            })
            window.open(Config.urlFull("dash1"), '_blank');
          } else {
            this.setState({
              popup: false
            })
          }
        }
    
      }

      

      hidePanel = () =>{
        this.sidebarWidgets.current.style.display = "none";
        if(this.coordinates){
          this.coordinates.mode = "live";
        }

        this.view.graphics.removeAll();

        // const { map } = this.view;
        // const getLayerAndSublayers = map.layers.flatten(function (item) {
        //     return item.layers || item.sublayers;
        // })
        // const layerAmostraDePesquisa = getLayerAndSublayers.find(function (layer) {
        //     return layer.title === "Agrupamento das Pesquisas de Mercado";
        // });
        // const layerPoligonoProximidade = getLayerAndSublayers.find(function(layer){
        //     return layer.title === "Polígono de Proximidade";
        // });
        // this.view.map.remove(layerAmostraDePesquisa);
        // this.view.map.remove(layerPoligonoProximidade);

        /*if(this.streetView){
          this.setState({ 
            popup:false
          })
        }*/

        if(this.transmissoes){
          this.setState({ 
            popup:false
          })
        }

        if(this.pesquisaMercado){
          this.setState({ 
            popup:false
          })
        }

        if(this.avaliacaodeImovelPorIA) {
          this.avaliacaodeImovelPorIA = null;
        }

      }

      hideLayersPanel = () =>{
        this.sidebarLayers.current.style.display = "none";
        this.view.ui.add("layers-wb-container", "top-left");
        this.layers_wb_container.current.style.display = "block";
      }

      //Fullscreen Widget
      handleOpenFullscreen = () => document.documentElement.requestFullscreen();
      handleExitFullscreen = () => document.exitFullscreen();
      toggleFullscreen = (action) =>{
        this.setState({ fullscreenOn: action })

        if(action){
          this.handleOpenFullscreen()
        }else{
          if(document.fullscreenElement){
            this.handleExitFullscreen()
          }
        }
      }

      //fullwindow Handler
      toggleMax = (action) =>{
        this.setState({ maxStatus: action })
        action ? this.setState({ classMax: 'sidenav100' }) : this.setState({ classMax: 'sidenav' })
       
      }
      //fullwindow Handler
      toggleLayersMax = (action) =>{
        this.setState({ maxLayersStatus: action })
        action ? this.setState({ classMaxLayers: 'sidenav100' }) : this.setState({ classMaxLayers: 'sidenavLayers' })
      }

      distanceMeasurement = () =>{
        const type = this.view.type;
        this.measurement.activeTool = type.toUpperCase() === "2D" ? "distance" : "direct-line";
        this.measurement.linearUnit='meters'
        this.setState({ 
          popup:'widget'
        })
      }

      areaMeasurement = () =>{
        this.measurement.activeTool = "area";
        this.measurement.areaUnit = "square-meters"
        console.log(this.measurement)
        this.setState({ 
          popup:'widget'
        })
      }

      clearMeasurements = () =>{
        this.measurement.clear();
        this.setState({ 
          popup:false
        })
      }

      openPerfil = (action) =>{
        this.setState({ perfilAction: action })
      }

      openSobre = (action) =>{
        this.setState({ sobreAction: action })
      }

      closeSair = (action) =>{
        //console.log(action)
        this.setState({ sairAction: action })
      }

      popupOpened = (action) =>{
        //console.log(action)
        this.view.graphics = null;
        this.setState({ popup: action })
      }

      dontExpand() {
        const getList = this.collapseList.current.querySelector('section>ul');
        const allLayers = Array.from(getList.children);
        allLayers.forEach(item => {
          if (!item.classList.value.includes('has-hidden')) {
            console.log();
            const expandElements = Array.from(item.querySelectorAll('[aria-label=Recolher]'));
            expandElements.forEach( element => element.click());
          }
        });
      }
    
      expand() {
        const getList = this.collapseList.current.querySelector('section>ul');
        const allLayers = Array.from(getList.children);
        allLayers.forEach(item => {
          if (!item.classList.value.includes('has-hidden')) {
            console.log();
            const expandElements = Array.from(item.querySelectorAll('[aria-label=Expandir]'));
            expandElements.forEach( element => element.click());
          }
        });
      }
    
      layerOff() {
        const getList = this.collapseList.current.querySelector('section>ul');
        const allLayers = Array.from(getList.children);
        allLayers.forEach(item => {
          if (!item.classList.value.includes('has-hidden')) {
            // seleciona o primeiro elemento que é a div
            const [getFirstElement] = item.children
    
            const children = getFirstElement.querySelector('.esri-icon-visible');
            if (children !== null) {
              children.click();
            }
          }
        });
      }
    
      layerOn() {
        const getList = this.collapseList.current.querySelector('section>ul');
        const allLayers = Array.from(getList.children);
        allLayers.forEach(item => {
          if (!item.classList.value.includes('has-hidden')) {
            // seleciona o primeiro elemento que é a div
            const [getFirstElement] = item.children
    
            const children = getFirstElement.querySelector('.esri-icon-non-visible');
            if (children !== null) {
              children.click();
            }
          }
        });
      }
    
      filterLayer(event) {
        // console.log(event.target.value.trim())
        const text = event.target.value.trim().toLowerCase();
        const getAllLi = this.collapseList.current.querySelectorAll('li');
        const allLayers = Array.from(getAllLi);
    
        allLayers
          .filter(item => !item.textContent.toLowerCase().includes(text))
          .forEach(item => {
            item.classList.add('has-hidden');
          });
    
        allLayers
          .filter(item => item.textContent.toLowerCase().includes(text))
          .forEach(item => {
            item.classList.remove('has-hidden');
          });
    
      }
      getResumoMercado = () => {
        /*const dataForm = {
            uemail:localStorage.getItem('email'), 
            usalt:localStorage.getItem('salt')
        };*/
    
        this.setState(
          {
            tableDataResults: []
          }
        );
    
      }
      componentWillUnmount() {
        if (this.view) {
          // destroy the map view
          this.view.destroy();
        }
      }

      render() {

        this.pesquisaRapidaWidget = <PesquisaRapida view={this.view} />
        const dataChart1 = {
          labels: [
            'APARTAMENTO', 'CASA', 'TELHEIRO', 'SALA/CONJUNTO', 'LOJA', 'OUTROS'
          ],
          datasets: [
            {
              label: 'Tipologia',
              data: [
                this.state.tableDataResults.APARTAMENTO,
                this.state.tableDataResults.CASA,
                this.state.tableDataResults.TELHEIRO,
                this.state.tableDataResults.SALA_CONJUNTO,
                this.state.tableDataResults.LOJA,
                this.state.tableDataResults.OUTROS,
              ],
              backgroundColor: [
                'rgba(13, 179, 158, 0.2)',
                'rgba(249, 65, 68, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 25, 19, 0.2)',
                'rgba(135, 19, 255, 0.2)',
                'rgba(45, 45, 45, 0.2)',
    
              ],
              borderColor: [
                'rgba(13, 179, 158, 1)',
                'rgba(249, 65, 68, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 25, 19, 1)',
                'rgba(135, 19, 255, 1)',
                'rgba(45, 45, 45, 1)',
    
              ],
              borderWidth: 1,
            },
          ],
        }
    
        const dataChart2 = {
          labels: ['Tipo de Imóvel'],
          datasets: [
            {
              label: 'PREDIAL',
              data: [this.state.tableDataResults.PREDIAL],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
              ],
              borderWidth: 1
            },
            {
              label: 'TERRITORIAL',
              data: [this.state.tableDataResults.TERRITORIAL],
              backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 1
            }
    
          ],
        }
        const optionsCharts = {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }
        
        let CheckLayers = Config.checkAcl("Layers",this.props.acls) === "yes" ? true : false;
        let CheckLegend = Config.checkAcl("Legend",this.props.acls) === "yes" ? true : false;
        let LayersLegends = CheckLayers && CheckLegend ? <div id="sidebarLayers" ref={this.sidebarLayers} className={this.state.classMaxLayers}>
          <Navbar bg="danger" variant="dark">
              <Navbar.Brand className="mobileFont">
                {this.state.cdtitle}
              </Navbar.Brand>
              <Nav className="ml-auto">
                  <Nav.Link id="handleResList" onClick={(e) => this.toggleLayersMax(!this.state.maxLayersStatus)}><MdFullscreen className="btnSidebarWidget" size={14} color="#fafafa" /></Nav.Link>
                  <Nav.Link onClick={this.hideLayersPanel}><MdClose className="btnSidebarWidget" size={14} color="#fafafa" /></Nav.Link>
              </Nav>
          </Navbar>

          <br/>
          
          <InputGroup>
              <ButtonGroup size="sm" as={InputGroup.Prepend}>
                <Button variant="secondary" onClick={() => this.showLayers("Camadas")}><MdLayers size={16} color="#fafafa" /> </Button>
                <Button variant="danger" onClick={() => this.showLegend("Legendas")} ><FaList size={16} color="#fafafa"/> </Button>
              </ButtonGroup>
            
              <FormControl
                  size="sm"
                  placeholder="Pesquisar Camadas"
                  onInput={this.filterLayer}
              />
              <DropdownButton variant="danger" as={InputGroup.Append} size="sm" title={<BiListCheck size={16} color="#fafafa" />}>
                  <Dropdown.Item
                  href="#"
                  onClick={this.layerOn}
                  >Ativar todas as camadas</Dropdown.Item>
                  <Dropdown.Item
                  href="#"
                  onClick={this.layerOff}
                  >Desativar todas as camadas</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                  href="#"
                  onClick={this.expand}
                  >Expandir todas as camadas</Dropdown.Item>
                  <Dropdown.Item
                  href="#"
                  onClick={this.dontExpand}  
                  >Recolher todas as camadas</Dropdown.Item>
              </DropdownButton>
          </InputGroup>
          <hr/>

          <section id="collapseList" ref={this.collapseList}></section>
          <section id="collapseLegend" ref={this.collapseLegend}></section>
      </div> : "";


        return (
          <>
            <Header
              showAvaliacaoImovel ={() => this.showAvaliacaoImovel('Avaliação do Imóvel')}
              showPesquisaMercado ={() => this.showPesquisaMercado('Pesquisa de Mercado')}
              showTransmissoes ={() => this.showTransmissoes('Transmissões')}
              showAvaliacaodeImovelPorIa ={() => this.showAvaliacaodeImovelPorIa('Avaliação por IA')}
              
              showBasemaps ={() => this.showBasemaps('Galeria de Mapa Base')}
              showMeasurement ={() => this.showMeasurement('Ferramenta de Medição')}
              showCoordinates ={() => this.showCoordinates('Coordenadas')}
              showPrint ={() => this.showPrint('Ferramenta de Impressão')}

              toggleFullscreen={() => this.toggleFullscreen(!this.state.fullscreenOn)}
              openPerfil={() => this.openPerfil(!this.state.PerfilAction)}
              closeSair={() => this.closeSair(!this.state.sairAction)}
              openSobre={() => this.openSobre(!this.state.sobreAction)}
            />
            
          
          <div className="rowP">

            {LayersLegends}
            
            <div id="tecgeo__viewDiv" ref={this.mapRef}>
                  
                <div className="tecgeo__centered" ref={this.tecgeo__centered} >
                  <div id="tecgeo__loader">

                    <div className="scaleLoader_panel">
                      <ScaleLoader size={22} color={"#c00812"} />
                    </div>
                  
                  </div>
                </div>
        
                {/*<div id="tecgeo__loadingMap" className="esri-widget--button esri-widget esri-interactive" ref={this.tecgeo__loadingMap} >
                  <img src="/loader.gif" className="img32" alt="loading" />
                </div>*/}

                <div id="layers-wb-container" ref={this.layers_wb_container}>
                  <button id="layers-switch-btn" className="esri-widget--button esri-widget esri-interactive" title="Abrir Lista de Camadas" onClick={() => this.showLayers("Camadas")} ref={this.layers_switch_btn}>
                    <img src="/geoitbi/001-layers.png" alt="switch-layers" width="18" height="18" />
                  </button>
                </div>

                <button id="streetview-switch-btn" className={this.state.streetViewClass} title="Abrir Street View" onClick={() => this.showStreetView(!this.state.streetView)} >
                  <img src="/geoitbi/008-street-view.png" alt="switch-layers" width="20" height="20" />
                </button>

                <button id="photoview-switch-btn" style={{border: 0}} className={this.state.streetViewClass} title="Abrir  PhotoView" onClick={() => this.showPhotoview(!this.state.photoview)} >
                  <img src="/geoitbi/photo360.png" alt="switch-layers" width="20" height="20" />
                </button>

                <button id="dashboard-switch-btn" style={{border: 0}} className={this.state.streetViewClass} title="Abrir o Dashboard" onClick={() => this.showDashboard(!this.state.dashboard)} >
                  <MdAssessment size={20} color="#c00812"/>
                </button>

                {/* <button id="dashboard1-switch-btn" style={{border: 0}} className={this.state.streetViewClass} title="Abrir o Dashboard" onClick={() => this.showDashboard1(!this.state.dashboard1)} >
                  <MdDashboard size={20} color="#c00812"/>
                </button> */}
                
                <div id="tecgeo__logoTecgeoDiv">
                  <a href="http://tecnologiageo.com.br" target="_blank" rel="noopener noreferrer">
                    <img src="/geoitbi/logoTecgeo.png" alt="Logo Tecgeo" />
                  </a>
                </div>

                <div id="tecgeo__SearchBar">
                  {this.pesquisaRapidaWidget}
                </div>

                <div id="sidebarWidgets" ref={this.sidebarWidgets} className={this.state.classMax}>
                
                  <Navbar bg="danger" variant="dark">
                      <Navbar.Brand className="mobileFont">
                        {this.state.wtitle}
                      </Navbar.Brand>
                    
                      <Nav className="ml-auto">
                          <Nav.Link id="handleRes" onClick={(e) => this.toggleMax(!this.state.maxStatus)}><MdFullscreen className="btnSidebarWidget" size={14} color="#fafafa" /></Nav.Link>
                          <Nav.Link onClick={this.hidePanel}><MdClose className="btnSidebarWidget" size={14} color="#fafafa" /></Nav.Link>
                      </Nav>
                  </Navbar>
                  
                  <section id="collapseBasemap" ref={this.collapseBasemap}></section>
                  <section id="collapsePrint" ref={this.collapsePrint}></section>
                  <section id="collapseCoordinates" ref={this.collapseCoordinates}></section>
                  <section id="collapseMeasurement" ref={this.collapseMeasurement}>
                    <div id="measurementTools" ref={this.measurementTools}>
                        <Button onClick={this.distanceMeasurement} variant="outline-primary" size="sm" block><FaRuler size={16} color="#b6def3" /> {' '}Distância</Button>{' '}
                        <Button onClick={this.areaMeasurement} variant="outline-success" size="sm" block><MdFormatShapes size={16} color="#a7cd55" /> {' '}Área</Button>{' '}
                        <Button onClick={this.clearMeasurements} variant="outline-danger" size="sm" block><FaTrashAlt size={16} color="#de8989" />  {' '}Limpar</Button>
                    </div>
                  </section>

                  <section id="collapsePanel" ref={this.collapsePanel}>
                    {this.avaliacaoImovel ? <AvaliacaoImovel view={this.view} /> : null}
                    {this.pesquisaMercado ? <PesquisaMercado view={this.view} /> : null}
                    {this.transmissoes ? <Transmissoes view={this.view} /> : null}
                    {this.avaliacaodeImovelPorIA ? <AvaliacaodeImovelPorIa view={this.view} /> : null}
                    
                  </section>

                  {this.state.sairAction ? <Sair closeSair={(e) => this.closeSair(!this.state.sairAction)} /> : null}
                  {this.state.perfilAction ? <Perfil openPerfil={() => this.openPerfil(!this.state.perfilAction)} /> : null}
                  {this.state.sobreAction ? <Sobre openSobre={() => this.openSobre(!this.state.sobreAction)} /> : null}
                  
                  
                </div>
                
              </div>


          </div>
          {<div id="tableContainer" className="tablecontainer has-hidden"></div>}
          {this.state.popup === true ? <Popup view={this.view} popupOpened={() => this.popupOpened(!this.state.popup)} mappoint={this.state.popupMapPoint} /> : null}
          {this.state.streetView ? <StreetView view={this.view} closeSV={() => this.showStreetView(!this.state.streetView)} /> : null}
          {this.state.photoview ? <Photoview view={this.view} closeSV={() => this.showPhotoview(!this.state.photoview)} /> : null}
          {/*this.state.dashboard ? <Dashboard view={this.view} toggleFullscreen={() => this.toggleFullscreen(!this.state.fullscreenOn)} fullscreen={this.state.fullscreenOn} closeSV={() => this.showDashboard(!this.state.dashboard)} /> : null*/}
          </>
          
        );
      }
}

//EsriMap.contextType = ViewContext;
export default EsriMap;