//import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

const EsriPrint = async (view, cont) => {

    const [Print] = await loadModules([
        'esri/widgets/Print'
    ]);

    const print = new Print({
        view: view,
        container: cont,
        printServiceUrl:"https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"
        //printServiceUrl:"http://irlanda.pmjg.lan:6080/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task"
    })
       
    return print;

}

export default EsriPrint;