import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import NotFoundPage from './pages/NotFound';
import PrintPdf from './components/PrintPdf';
import Dashboard from './components/Dashboard';
import Dashboard1 from './components/Dashboard1';

import config from './config';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={LoginPage} exact path={config.urlFull("")} />
        <Route component={DashboardPage} exact path={config.urlFull("dashboard")} />
        <Route component={PrintPdf} exact path={config.urlFull("relatorio")} />
        <Route path={config.urlFull("dash")} exact component={Dashboard} />
        <Route path={config.urlFull("dash1")} exact component={Dashboard1} />
        <Route component={NotFoundPage} exact={true} path={config.urlFull("*")} />
        
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
