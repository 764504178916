import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ViewProvider } from './components/EsriMap/context'

ReactDOM.render(
    <ViewProvider>
        <App />
    </ViewProvider>, 
    document.getElementById('root')
);

