import React from 'react';
// import './styles.css'

class Dashboard1 extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			panUrl: 'https://dashboardtecgeo.maps.arcgis.com/apps/dashboards/cb17bc346b14468999bb5ebbf2b1f900',
		}
	}


	render() {

		return (
			<iframe title="GoogleStreetView" frameBorder="0" allowtransparency="yes" scrolling="no" src={this.state.panUrl} width="100%" height="100%"></iframe>
		);

	}
}

export default Dashboard1;