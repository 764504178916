import React from 'react';
import './style.css'
import ScaleLoader from "react-spinners/ScaleLoader";

class PageLoading extends React.Component {

    render() {
        return (
            <>
                <div className="loading__centered" >
                    <ScaleLoader size={25} color={"#fafafa"} />
                </div>
            </>
        );
    }
     
}


export default PageLoading;


