import React from 'react';
import {Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip}  from "react-bootstrap";
import {MdPrint, MdEditLocation, MdFormatShapes, MdMap, MdFileUpload, MdAspectRatio, MdExitToApp, MdMenu, MdClose} from 'react-icons/md';
import {FaUserCircle, FaInfoCircle, FaSearchLocation, FaMoneyBillWave} from "react-icons/fa";
import { GiObservatory } from "react-icons/gi";
import {BiBuildingHouse} from "react-icons/bi"
import Config from '../../config';
//import './style.css';

class Header extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          username:localStorage.getItem('nome'),
          acls:[]
        };

        this.mobileMenuPanel = React.createRef();
        this.mobileMenuPanelBtnOpen = React.createRef();
        this.mobileMenuPanelBtnClose = React.createRef();

        //Configuracoes
        this.config = Config.configs()
    }

   
    componentDidMount() {

        fetch(this.config.appurl+"geoportal/logged", {
          method: 'POST',
          body: JSON.stringify({email:localStorage.getItem('email'), salt:localStorage.getItem('salt')}),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json())
        .then(
          //loading
          this.setState(
            {
              logged: "loading"
            }
          )
        )
        .then(
          (response) => {
            if(response.error){
              this.setState(
                {
                  logged: "no"
                }
              )
              //limpar a sessão
              localStorage.clear();
            }else{
              this.setState(
                {
                  logged: "yes",
                  acls: response.usuario.acls
                }
              )
            }
          }
        )
    
      }

    showMobileMenu = () =>{
        this.mobileMenuPanel.current.style.display = "block";
        this.mobileMenuPanelBtnOpen.current.style.display = "none";
        this.mobileMenuPanelBtnClose.current.style.display = "block";
    }

    closeMobileMenu = () =>{
        this.mobileMenuPanel.current.style.display = "none";
        this.mobileMenuPanelBtnOpen.current.style.display = "block";
        this.mobileMenuPanelBtnClose.current.style.display = "none";
    }

    render() {

        //Listar features se possui a permissão
        //console.log(this.state.acls)
        //console.log(Config.checkAcl("CalcAvaliacaoImovel",this.state.acls))
        let CheckCalcAvaliacaoImovel = Config.checkAcl("CalcAvaliacaoImovel",this.state.acls) === "yes" ? true : false;
        let CalcAvaliacaoImovel = CheckCalcAvaliacaoImovel ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Cálculo da Avaliação do Imóvel</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showAvaliacaoImovel('Avaliação do Imóvel')}><BiBuildingHouse size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';

        let CheckFiltroPesq = Config.checkAcl("FiltroPesq",this.state.acls) === "yes" ? true : false;
        let FiltroPesq = CheckFiltroPesq ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Pesquisa de Mercado</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showPesquisaMercado('Pesquisa de Mercado')}><FaSearchLocation size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';

        let CheckFiltroTrans = Config.checkAcl("FiltroTrans",this.state.acls) === "yes" ? true : false;
        let FiltroTrans = CheckFiltroTrans ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Transmissões</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showTransmissoes('Transmissões')}><MdFileUpload size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';

        /*let CheckStreetView = Config.checkAcl("StreetView",this.state.acls) === "yes" ? true : false;
        let StreetView = CheckStreetView ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Google Street View</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showStreetView('Google Street View')}><FaStreetView size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';*/

        let CheckBasemapGallery = Config.checkAcl("BasemapGallery",this.state.acls) === "yes" ? true : false;
        let BasemapGallery = CheckBasemapGallery ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Galeria de Mapa Base</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showBasemaps('Galeria de Mapa Base')}><MdMap size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';

        let CheckMeasurement= Config.checkAcl("Measurement",this.state.acls) === "yes" ? true : false;
        let Measurement = CheckMeasurement ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Ferramenta de Medição</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showMeasurement('Ferramenta de Medição')}><MdFormatShapes size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';

        let CheckCoordinate = Config.checkAcl("Coordinate",this.state.acls) === "yes" ? true : false;
        let Coordinate = CheckCoordinate ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Conversor de Coordenadas</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showCoordinates('Coordenadas')}><MdEditLocation size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';

        let CheckPrint = Config.checkAcl("Print",this.state.acls) === "yes" ? true : false;
        let Print = CheckPrint ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Ferramenta de Impressão</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showPrint('Ferramenta de Impressão')}><MdPrint size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';


        let CheckAvaliacaoPorIa = true;
        let AvaliacaoPorIa = CheckAvaliacaoPorIa ? <OverlayTrigger placement={'left'} overlay={<Tooltip>Avaliação de Imóvel Por IA (BETA)</Tooltip>}>
            <Nav.Link className="btnMenu" onClick={(e) => this.props.showAvaliacaodeImovelPorIa('Avaliação de Imóvel Por IA (BETA)')}><FaMoneyBillWave size={18} color="#fafafa" /></Nav.Link>
        </OverlayTrigger> : '';
      let chkObeservatorio= true;
        let observatorio = <OverlayTrigger placement={'left'} overlay={<Tooltip>Observatório</Tooltip>}>
        <Nav.Link className="btnMenu" onClick={(e) => window.open('https://experience.arcgis.com/experience/333ed73d33fb4329894687d26ef318dd/?draft=true', '_blank')}><GiObservatory size={18} color="#fafafa" /></Nav.Link>
    </OverlayTrigger>

   
        let CalcAvaliacaoImovelMobile = CheckCalcAvaliacaoImovel ? <NavDropdown.Item onClick={(e) => this.props.showAvaliacaoImovel('Avaliação do Imóvel', true)}><BiBuildingHouse size={16} color="#007bff" /> Avaliação do Imóvel</NavDropdown.Item> : '';
        let FiltroPesqMobile = CheckFiltroPesq ? <NavDropdown.Item onClick={(e) => this.props.showPesquisaMercado('Pesquisa de Mercado', true)}><FaSearchLocation size={16} color="#007bff" /> Pesquisa de Mercado</NavDropdown.Item> : '';
        let FiltroTransMobile = CheckFiltroTrans ? <NavDropdown.Item onClick={(e) => this.props.showTransmissoes('Transmissões', true)}><MdFileUpload size={16} color="#007bff" /> Transmissões</NavDropdown.Item> : '';
        //let StreetViewMobile = CheckStreetView ? <NavDropdown.Item onClick={(e) => this.props.showStreetView('Google Street View', true)}><FaStreetView size={16} color="#007bff" /> Google Street View </NavDropdown.Item> : '';
        let BasemapGalleryMobile = CheckBasemapGallery ? <NavDropdown.Item onClick={(e) => this.props.showBasemaps('Galeria de Mapa Base', true)}><MdMap size={16} color="#007bff" /> Galeria de Mapa Base </NavDropdown.Item> : '';
        let MeasurementMobile = CheckMeasurement ? <NavDropdown.Item onClick={(e) => this.props.showMeasurement('Ferramenta de Medição', true)}><MdFormatShapes size={16} color="#007bff" /> Ferramenta de Medição </NavDropdown.Item> : '';
        let CoordinateMobile = CheckCoordinate ? <NavDropdown.Item onClick={(e) => this.props.showCoordinates('Coordenadas', true)}><MdEditLocation size={16} color="#007bff" /> Coordenadas</NavDropdown.Item> : '';
        let PrintMobile = CheckPrint ? <NavDropdown.Item onClick={(e) => this.props.showPrint('Ferramenta de Impressão', true)}><MdPrint size={16} color="#007bff" /> Ferramenta de Impressão</NavDropdown.Item> : '';
        
        return (
            <>
                <Navbar bg="danger" variant="dark">
                    <Navbar.Brand onClick={ function retFalse(){return false} } >
                        <img 
                            src="/geoitbi/logoapp2.png" 
                            alt="Logo PMJP"
                            width="90"
                            height="40"
                            className="d-inline-block align-center"
                        />
                        {' '}
                        Geoportal de ITBI
                    </Navbar.Brand>
                    
                    <Nav className="mr-auto defaultMenu">
                        <NavDropdown title={this.state.username} id="collasible-nav-dropdown">
                            <NavDropdown.Item onClick={e => this.props.openPerfil(e)}><FaUserCircle size={16} color="#c00812" /> Meu Perfil</NavDropdown.Item>
                            <NavDropdown.Item onClick={e => this.props.closeSair(e)}><MdExitToApp size={16} color="#c00812" /> Sair da Aplicação</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={e => this.props.openSobre(e)}><FaInfoCircle size={16} color="#c00812" /> Sobre</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="ml-auto defaultMenu">
                        {CalcAvaliacaoImovel}
                        {FiltroPesq}
                        {FiltroTrans}
                        {/*StreetView*/}
                        {BasemapGallery}
                        {Measurement}
                        {Coordinate}
                        {Print}
                        {AvaliacaoPorIa}
                        {observatorio}
                        <OverlayTrigger placement={'left'} overlay={<Tooltip>Ampliar Tela</Tooltip>}>
                            <Nav.Link className="btnMenu" onClick={e => this.props.toggleFullscreen(e)}><MdAspectRatio size={18} color="#fafafa" /></Nav.Link>
                        </OverlayTrigger>
                        {' '}
                        {' '}
                        
                    </Nav>

                    <Nav className="ml-auto mobileMenu">
                        <Nav.Link className="btnMenu" onClick={e => this.showMobileMenu(e)} id="mobileMenuPanelBtnOpen" ref={this.mobileMenuPanelBtnOpen}>
                            <MdMenu size={18} color="#fafafa" />
                        </Nav.Link>
                        <Nav.Link className="btnMenu" onClick={e => this.closeMobileMenu(e)} id="mobileMenuPanelBtnClose" ref={this.mobileMenuPanelBtnClose}>
                            <MdClose size={18} color="#fafafa"/>
                        </Nav.Link>
                    </Nav>
                
                </Navbar>

                <section id="mobileMenuPanel" ref={this.mobileMenuPanel} className="sidenav100">
                    {CalcAvaliacaoImovelMobile}
                    {FiltroPesqMobile}
                    {FiltroTransMobile}    
                    {/*StreetViewMobile*/}
                    {BasemapGalleryMobile}
                    {MeasurementMobile}
                    {CoordinateMobile}
                    {PrintMobile}
                    

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={e => this.props.openPerfil(e)}><FaUserCircle size={16} color="#007bff" /> {this.state.username} (Ver Perfil)</NavDropdown.Item>
                    <NavDropdown.Item onClick={e => this.props.closeSair(e)}><MdExitToApp size={16} color="#007bff" /> Sair da Aplicação</NavDropdown.Item>
                    <NavDropdown.Item onClick={e => this.props.openSobre(e)}><FaInfoCircle size={16} color="#007bff" /> Sobre</NavDropdown.Item>
                  
                </section>
            </>
        );
    }  
}


export default Header;


