import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/static/css/global.css';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';

import { setDefaultOptions } from 'esri-loader';

const browserHistory = createBrowserHistory();

setDefaultOptions({ version: '4.23' })

function App() {
  return (
    <Router history={browserHistory}>
      <Routes />
    </Router>
  );
}

export default App;
