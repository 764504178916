//import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

const EsriCoordinates = async (view, cont) => {

    const [CoordinateConversion] = await loadModules([
        'esri/widgets/CoordinateConversion'
    ]);

    const coordinates = new CoordinateConversion({
        view: view,
        container: cont
    })
       
    return coordinates;

}

export default EsriCoordinates;