//import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

const EsriLayerList = async (view, cont) => {

    const [LayerList,FeatureTable, FeatureLayer, Query, Graphic] = await loadModules([
        'esri/widgets/LayerList',
        'esri/widgets/FeatureTable',
        'esri/layers/FeatureLayer',
        'esri/tasks/support/Query',
        'esri/Graphic'
    ]);

    
    
    const layerlist = new LayerList({
        view: view,
        container: cont,
        listItemCreatedFunction: function (event) {
            const item = event.item;

            if (item.layer.type !== "group") {
            if (typeof item.layer.id === "string") {
              // don't show legend twice
              /*item.panel = {
                content: "legend",
                open: false
              };*/

              

              item.actionsSections = [
              
                [
                  {
                    title: "Menos Transparência",
                    className: "esri-icon-minus",
                    id: "increase-opacity"
                  },
                  {
                    title: "Mais Transparência",
                    className: "esri-icon-plus",
                    id: "decrease-opacity"
                  },
                  {
                    title: "Tabela de Dados",
                    className: "esri-icon-table",
                    id: "table",
                    type: "toggle"
                  }
                ]
              ];
            }else{
              item.actionsSections = [
                [
                  {
                    // This allows the user to toggle the table on/off within the FeatureTable widget
                    title: "Tabela de Dados",
                    className: "esri-icon-table",
                    id: "table",
                    type: "toggle"
                  }
                  
                ]
              ];
            }

           
        }}
    });

    layerlist.selectionEnabled = true;
    const tableContainer = document.getElementById("tableContainer");
    
	let featureTable;
    async function createTable(url, id) {
      console.log(url)
      console.log(id)
      const chamada = `${url}/${id}`
      const tableDiv = document.createElement("div");
      tableContainer.appendChild(tableDiv);
      let layerTable = new FeatureLayer({
        url: chamada
      });
      //layerTable.load()
      view.graphics = null;
      featureTable = new FeatureTable({
        view: view,
        layer: layerTable,
        container: tableDiv,
        pageSize:2,
        highlightOnRowSelectEnabled:true
      });
      //console.log("createTable")
      //console.log(featureTable)
      if(featureTable){
          featureTable.on("selection-change", function (event) {
            event.removed.forEach((item) => {
              //console.log(item)
              view.graphics = null;
            });
            
            event.added.forEach((item) => {
              //console.log(item)
              //Query por Geocoding - Dentro da View.When
              var fillSymbol = {
                  type: "simple-fill", // autocasts as new SimpleFillSymbol()
                  color: [102, 153, 255, 0.7],
                  outline: {
                      color: [51, 119, 255],
                      width: 3
                  }
              };
              const query = new Query();
              query.where = "";
              query.geometry = item.feature.geometry;
              query.outSpatialReference = view.spatialReference;
              query.returnGeometry = true;
              query.spatialRelationship = "contains";
              //query.returnQueryGeometry  = true;
              layerTable.queryFeatures(query).then(function(results){
                  results.features.map(function(feature) {
                      //Create a polygon geometry
                      var polygon = {
                          type: "polygon", // autocasts as new Polygon()
                          hasZ: true,
                          hasM: true,
                          rings: feature.geometry.rings[0],
                          spatialReference: { wkid: feature.geometry.spatialReference.wkid }
                      };
                      var polygonGraphic = new Graphic({
                          geometry: polygon,
                          symbol: fillSymbol
                      });
                      view.graphics.remove(polygonGraphic);
                      view.graphics.add(polygonGraphic);
                      return true;
                  });
              });
      
              //Zoom nos resultados
              layerTable.queryExtent(query).then(function(results){
                  view.goTo({target:results.extent},{ duration: 1000 });
              });
              
            });
          });
           
      }
    }

    // Toggle feature table on/off
    function toggleFeatureTable(showTable) {
      // Check if the table is displayed, if so, toggle off. If not, display.
      showTable ? tableContainer.classList.remove("has-hidden") : tableContainer.classList.add("has-hidden");
      view.graphics = null;
    }

    layerlist.on("trigger-action", function (event) {
      //The layer visible in the view at the time of the trigger.
      //var visibleLayer = USALayer.visible ? USALayer : censusLayer;

      let rlayer = event.item.layer;

      // Capture the action id.
      var id = event.action.id;

      if (id === "increase-opacity") {
        //console.log("increase-opacity")

        if (rlayer.opacity < 1) {
          rlayer.opacity += 0.25;
        }
      }
      
      if (id === "decrease-opacity") {

        //console.log("decrease-opacity")
        if (rlayer.opacity > 0) {
          rlayer.opacity -= 0.25;
        }
      }

      if (id === "increase-opacity") {
        //console.log("increase-opacity")
        if (rlayer.opacity < 1) {
          rlayer.opacity += 0.25;
        }
      } else if (id === "decrease-opacity") {
        //console.log("decrease-opacity")
        if (rlayer.opacity > 0) {
          rlayer.opacity -= 0.25;
        }
      } else if (id === "table") {
        // Toggle the feature table widget based on the selected table,
        // If the toggled on
        
        if (event.action.value) {
          // Check if the Feature Table is already created if so, don't recreate
          if (featureTable) {
            
            // If the table is already created, make sure that the featuretable toggle reflects the correct layer.
            // If toggling on a different layer, create the featuretable for that layer and toggle on
            if (rlayer.title !== featureTable.layer.title) {
              // destroy the featuretable and recreate with new tablediv
              featureTable.destroy();
              view.graphics = null;
              // Load the FeatureTable based on whatever is clicked
              console.log("console 1 ",rlayer)
              createTable(rlayer.url,  rlayer.layerId).then(function () {
                toggleFeatureTable(true);
              });
            } else {
              // if the table is the same one stored in featureTable, i.e. toggling on/of the same table, no need to recreate.
              toggleFeatureTable(true);
            }
            // If the featuretable is not already created, create a new one and toggle it on
          } else {
            // Create the table if not already created
            console.log('console 2: ', rlayer)
            createTable(rlayer.url, rlayer.layerId).then(function () {
              toggleFeatureTable(true);
            });
          }
        } else {
          // Toggle the table off
          toggleFeatureTable(false);
        }
        // If the information button is clicked, open the layer's url in a separate page
      }
        
    });


    return layerlist;
 
}

export default EsriLayerList;