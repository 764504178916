import pt from 'date-fns/locale/pt';
import { loadModules } from 'esri-loader';
import React from 'react';
import { Alert, Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Bar, Pie } from 'react-chartjs-2';
import { CSVLink } from "react-csv";
import CurrencyFormat from 'react-currency-format';
import DataTable from 'react-data-table-component';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-export-excel";
import ReactHtmlParser from 'react-html-parser';
import { FaChartBar, FaDrawPolygon, FaFileCsv, FaPlusCircle, FaSearchLocation, FaTrashAlt } from "react-icons/fa";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import Moment from 'react-moment';
import ClipLoader from "react-spinners/BarLoader";
import WidgetLoading from '../../components/WidgetLoading';
import Config from '../../config';
import './style.css';

class Transmissoes extends React.Component {

    constructor(props) {
        super(props);

        let today = new Date();
        //let day = today.getDate() > 9 ? today.getDate() : "0"+today.getDate();
        //let month = today.getMonth() > 9 ? today.getMonth()+1 : "0"+ Number(today.getMonth()+1);
        //let gettoday = day +"/"+month+ "/"+ today.getFullYear();
        //let gettoday = "10/03/2018";

        registerLocale('pt', pt)

        this.state = {
            logged: "loading",
            tableDataResults: [],
            tableDataFilter: [],
            totalRows: 0,
            isLoaded: true,
            btnDisabled: false,

            formAction: '',
            alertType: 'primary',
            alertMessageStatus: false,
            alertMessage: '',
            setValidated: false,

            valor: '',
            datainicial: today,
            datafinal: today,

            viewFeature: null,
            doneButtonFP: true,

            resultsFP: [],
            sum_areas: 0,
            sum_ponderada: 0,
            ponderada: 0,

            modalGraficos: false,

            topologyValueSelectBox: [],

            poligonosProximidade: [],

            alertMessageResult: false,
        };

        this.panelTransmissoes = React.createRef();
        this.panelFilter = React.createRef();

        this.showTransmissoes = this.showTransmissoes.bind(this);
        this.showFilter = this.showFilter.bind(this);
        this.filtroProximidade = this.filtroProximidade.bind(this);

        this.polycoord = [];
        this.sketch = null;
        this.startButtonsFP = React.createRef();
        this.actionButtonsFP = React.createRef();
        //this.resultsFP = [];

        this.columnsResults = [
            /*{
                name: 'Ver',
                selector: 'ver',
                sortable: false,
                cell: row => <Button data-tag="allowRowEvents" onClick={() => this.handleZoom("geocoding = '"+row.de_geocodigo+"' ",row.de_inscricao_imobiliaria)} variant={this.state.viewFeature === row.de_inscricao_imobiliaria ? 'danger' : 'primary'} size='sm' style={{padding:'2px'}}><FaEye size={12} color="white" /></Button>
            },*/
            { name: 'Cod. Imov', selector: 'Codi_Imov', sortable: true, wrap: true },
            { name: 'Tipo do Imóvel', selector: 'Tipo_Imovel', sortable: true },
            { name: 'Tipologia', selector: 'Classificacao', sortable: true },
            {
                name: 'Data', selector: 'Data_Guia', sortable: true,
                cell: row => <Moment date={row.Data_Guia} format="DD/MM/YYYY" />
            },
            {
                name: 'Área do Terreno (m²)', selector: 'Area_Terreno', sortable: true, wrap: true,
                cell: row => isNaN(row.Area_Terreno) ? 0 : <strong>{Number(row.Area_Terreno).toFixed(2)} m²</strong>,
                style: { backgroundColor: '#e6ffc8' }
            },
            {
                name: 'Área do Imóvel (m²)', selector: 'Area_Predial', sortable: true, wrap: true,
                cell: row => isNaN(row.Area_Predial) ? 0 : <strong>{Number(row.Area_Predial).toFixed(2)} m²</strong>,
                style: { backgroundColor: '#e6ffb3' }
            },
            {
                name: 'Valor da Avaliação', selector: 'valo_laud', sortable: true, wrap: true,
                cell: row => isNaN(row.valo_laud) ? 0 :
                    <CurrencyFormat value={Number(row.valo_laud).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />,
                style: { backgroundColor: '#cce0ff' }
            },
            {
                name: 'Valor m²', selector: 'valor_m2', sortable: true, wrap: true,
                cell: row => isNaN(Number(row.valo_laud / row.Area_Predial)) ||
                    !isFinite(Number(row.valo_laud / row.Area_Predial)) ? 0 :
                    <CurrencyFormat value={Number(row.valo_laud / row.Area_Predial).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />,
                style: { backgroundColor: '#e0ccff' }
            },
            {
                name: 'Ponderação', selector: 'ponderacao', sortable: true, wrap: true,
                cell: row => isNaN(Number((row.valo_laud / row.Area_Predial) * row.Area_Predial)) ||
                    !isFinite(Number((row.valo_laud / row.Area_Predial) * row.Area_Predial)) ? 0 :
                    <CurrencyFormat value={Number((row.valo_laud / row.Area_Predial) * row.Area_Predial).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />,
                style: { backgroundColor: '#ffd6cc' }
            },
            { name: 'Padrão', selector: 'Padrao', sortable: true },
            { name: 'Idade', selector: 'Idade', sortable: true },
            { name: 'Conservação', selector: 'Estado_Conservacao', sortable: true },
            { name: 'Pavimentos', selector: 'Qtde_Pavimentos', sortable: true },
            { name: 'ID da Transmissão', selector: 'nume_guia', sortable: true, wrap: true }
        ];


        this.columnsFilter = [
            { name: 'Coluna', selector: 'coluna', sortable: false, wrap: true },
            { name: 'Operador', selector: 'operador', sortable: false, wrap: true },
            { name: 'Valor', selector: 'valor', sortable: false, wrap: true },
            {
                name: 'Excluir',
                selector: 'acoes',
                sortable: false,
                right: true,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                cell: row => <Button onClick={() => this.excludeExpression(row.id)} variant='danger' size='sm' style={{ padding: '2px' }}><FaTrashAlt size={12} color="white" /></Button>

            },
        ];

        this.customStylesResults = {
            header: {
                style: {
                    minHeight: '20px',
                    fontSize: '11px'
                },
            },
            rows: {
                style: {
                    fontSize: '12px', // override the row height
                }
            },
        };

        //Configuracoes
        this.config = Config.configs()

    }

    async componentDidMount() {
        this.setState({
            isLoaded: false
        });
        //definindo o painel padrão (Inicial)
        this.panelTransmissoes.current.style.display = "none";
        this.filtroProximidade();
        const responseTopology = await this.loadingAllTopoly();
        if (responseTopology.error) {
            console.log(responseTopology.error);
        }
        this.setState({
            topologyValueSelectBox: responseTopology.rows,
        });
    }

    loadingAllTopoly = async () => {
        const uri = `${this.config.appurl}admin/transmissoes/classificacao`;
        const settings = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        };
        const getAllTopology = await fetch(uri, settings);
        return getAllTopology.json()
    }

    handleChange = (event) => {
        if (event.target.name === 'coluna') {
            this.setState({
                valor: ''
            });
        }
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );
    }

    handleDateChange = (data, campo) => {
        this.setState({ [campo]: data });
    }

    includeExpressions = async (event) => {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            // form is invalid! so we show error messages
            this.setState({ setValidated: true });
            return;
        }
        // form is valid! We can parse and submit data
        this.setState({ setValidated: false });

        //{expressao:"Tipologia Igual a CASA",acoes:1},

        let setid = 1;
        if (this.state.tableDataFilter.length > 0) {
            setid = this.state.tableDataFilter.length + 1;
        }

        const new_exression = {
            id: setid,
            coluna: this.state.coluna,
            operador: this.state.operador,
            valor: this.state.valor
        }

        this.setState({
            tableDataFilter: [...this.state.tableDataFilter, new_exression]
        });
    }

    removeGraphic = (id) => {
        const findLayer = this.props.view.map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        }).find(function (layer) {
            //console.log(layer.title)
            return layer.title === "Polígono de Proximidade";
        });
        const indexIvalid = -1;
        const poligonos = this.state.tableDataFilter.filter(({ coluna }) => coluna === 'poligono');
        const poligonyIndex = poligonos.findIndex(({ id: index }) => index === id);
        const notFound = poligonyIndex === indexIvalid;
        if (notFound) {
            console.log('Não foi encontrado o poligono');
            return;
        }
        const poligony = findLayer.graphics.items.find((_, index) => index === poligonyIndex);
        findLayer.graphics.remove(poligony);
    }

    excludeExpression = async (id) => {
        if (this.state.tableDataFilter.length === 0) {
            return;
        }
        const { coluna } = this.state.tableDataFilter.filter(item => item.id === id)[0];
        if (coluna === 'poligono') {
            this.removeGraphic(id);
        }
        this.setState({
            tableDataFilter: this.state.tableDataFilter.filter(item => item.id !== id),
            //resultsFP: []
        });
    }

    handleFilter = async () => {
        let today = new Date();
        let day = today.getDate() >= 9 ? today.getDate() : "0" + today.getDate();
        let month = today.getMonth() >= 9 ? today.getMonth() + 1 : "0" + Number(today.getMonth() + 1);

        let geti;

        if (this.state.datainicial) {
            let dayi = this.state.datainicial.getDate() >= 9 ? this.state.datainicial.getDate() : "0" + this.state.datainicial.getDate();
            let monthi = this.state.datainicial.getMonth() >= 9 ? this.state.datainicial.getMonth() + 1 : "0" + Number(this.state.datainicial.getMonth() + 1);

            geti = dayi + "/" + monthi + "/" + this.state.datainicial.getFullYear();
        } else {
            geti = day + "/" + month + "/" + today.getFullYear();
        }

        let getf;
        if (this.state.datafinal) {
            let dayf = this.state.datafinal.getDate() >= 9 ? this.state.datafinal.getDate() : "0" + this.state.datafinal.getDate();
            let monthf = this.state.datafinal.getMonth() >= 9 ? this.state.datafinal.getMonth() + 1 : "0" + Number(this.state.datafinal.getMonth() + 1);
            getf = dayf + "/" + monthf + "/" + this.state.datafinal.getFullYear();
        } else {
            getf = day + "/" + month + "/" + today.getFullYear();
        }

        const dataForm = {
            datainicial: geti,
            datafinal: getf,
            filtro: this.state.tableDataFilter,
            uemail: localStorage.getItem('email'),
            usalt: localStorage.getItem('salt')
        };

        fetch(this.config.appurl + 'admin/transmissoes/filtrar', {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                this.setState({
                        alertMessageStatus: true,
                        alertMessage: <section><strong>Carregando...</strong><br /><ClipLoader size={28} color={"#2a3850"} /></section>,
                        alertType: 'primary',
                        btnDisabled: true
                })
            )
            .then(
                (response) => {
                    if (response.error) {
                        this.setState(
                            {
                                alertMessageStatus: true,
                                alertMessage: ReactHtmlParser(response.message),
                                alertType: 'danger',
                                btnDisabled: false
                            }
                        );
                    } else {
                        this.setState(
                            {
                                alertMessageStatus: true,
                                alertMessage: ReactHtmlParser("<strong>Resultados Gerados com Sucesso!</strong>"),
                                alertType: 'success',
                                btnDisabled: false,
                                tableDataResults: response.transmissoes,
                                sum_areas: response.sum_areas,
                                sum_ponderada: response.sum_ponderada,
                                ponderada: response.ponderada
                            }
                        );

                        console.log(response)

                        setTimeout(() => {
                            this.setState({ alertMessageStatus: false })
                        }, 3000);

                        this.showTransmissoes(response.transmissoes);

                    }
                },
                (error) => {
                    this.setState({
                        alertMessageStatus: true,
                        alertMessage: "Ops aconteceu um erro na operação: " + error,
                        alertType: 'danger'
                    });
                }
            )
    }

    filtroProximidade = async () => {
        try {
            /**
             * Incluir ferramenta de desenho
             * Se transmissões tiverem os geocodigos na região desenhada
             */

            /** ESRI */
            const [SketchViewModel, GraphicsLayer] = await loadModules([
                "esri/widgets/Sketch/SketchViewModel",
                "esri/layers/GraphicsLayer"
            ], { css: true });

            //Desenhar Poligono
            this.proximidade = new GraphicsLayer({ "title": "Polígono de Proximidade" });

            this.sketch = new SketchViewModel({
                layer: this.proximidade,
                view: this.props.view,
                polygonSymbol: {
                    type: "simple-fill",
                    style: "cross",
                    color: "#c00812",
                    outline: {
                        width: 3,
                        style: "solid",
                        color: "#c00812"
                    }
                }
            });

            this.polycoord = [];

            this.sketch.on("create", this.onGraphicCreate);
            //this.sketch.on("update", this.onGraphicUpdate);

            this.startButtonsFP.current.style.display = "block";
            this.actionButtonsFP.current.style.display = "none";
        } catch (e) {
            console.log("O filtro Proximidade está processando")
        }
    }

    onGraphicCreate = async (event) => {
        if (event.state !== "complete") {
            return;
        }
        this.polycoord.push(event);
        this.startButtonsFP.current.style.display = "none";
        this.actionButtonsFP.current.style.display = "block";
        this.setState({ doneButtonFP: false });

        if (event.graphic) {
            this.selectFeaturesFiltroProximidade(event.graphic.geometry)
        }
    }

    selectFeaturesFiltroProximidade = async (geometry) => {
        /** ESRI */
        const [FeatureLayer, Query] = await loadModules([
            'esri/layers/FeatureLayer',
            "esri/tasks/support/Query",
        ], { css: true });

        let lotes = new FeatureLayer({
            url: "https://arcgis.tecgeobr.com.br/server/rest/services/CARTOGRAFIA_TESTE/MapServer/4",
        });

        lotes.load();

        this.polycoord = [];

        const query = new Query();
        query.where = "";
        query.geometry = geometry;
        query.outFields = ["codi_cart"];

        const finalResult = await lotes.queryFeatures(query);
        const geocodesFP = finalResult.features
            .map(({ attributes }) => attributes.codi_cart);

        this.setState({
            resultsFP: [
                ...this.state.resultsFP,
                geocodesFP
            ]
        });
    }

    drawPolygonFiltroProximidade = () => {
        //recupera todas as layers e sublayers
        let findLayer = this.props.view.map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        }).find(function (layer) {
            //console.log(layer.title)
            return layer.title === "Polígono de Proximidade";
        });

        //console.log(findLayer)

        if (findLayer) {
            this.proximidade = findLayer;
        } else {
            this.props.view.map.add(this.proximidade);
        }
        if (this.polycoord.length >= 1) {
            this.sketch.cancel();
        } else {
            this.sketch.create("polygon");
        }
        this.startButtonsFP.current.style.display = "none";
        this.actionButtonsFP.current.style.display = "block";
        this.setState({
            doneButtonFP: true,
        });
    }

    cancelFiltroProximidade = () => {
        this.sketch.cancel();
        //this.props.view.map.remove(this.proximidade)
        //this.proximidade.remove(event.graphics[0]);
        this.startButtonsFP.current.style.display = "block";
        this.actionButtonsFP.current.style.display = "none";

    }

    doneFiltroProximidade = () => {

        let setid = 1;
        if (this.state.tableDataFilter.length > 0) {
            setid = this.state.tableDataFilter.length + 1;
        }

        const new_exression = {
            id: setid,
            coluna: "poligono",
            operador: "=",
            valor: this.state.resultsFP[this.state.resultsFP.length - 1].length + " Feições",
            geocodigos: this.state.resultsFP[this.state.resultsFP.length - 1]
        }

        this.setState({
            tableDataFilter: [...this.state.tableDataFilter, new_exression]
        });

        this.sketch.complete();
        this.startButtonsFP.current.style.display = "block";
        this.actionButtonsFP.current.style.display = "none";
    }


    handleZoom = async (where) => {
        /**
         * Zoom nos resultados da pesquisa
         * Pintar todos os lotes pesquisados
         * Posicionar extent na pesquisa
         */

        /** ESRI */
        const [FeatureLayer, Query, Graphic] = await loadModules([
            'esri/layers/FeatureLayer',
            "esri/tasks/support/Query",
            "esri/Graphic"
        ], { css: true });

        //Query and Highligth
        const that = this;
        this.props.view.when(function (vi) {
            const lotes = new FeatureLayer({
                url: "https://arcgis.tecgeobr.com.br/server/rest/services/CARTOGRAFIA_TESTE/MapServer/4",
            })

            lotes.load();

            //Query por Geocoding - Dentro da View.When
            var fillSymbol = {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [102, 153, 255, 0.7],
                outline: {
                    color: [51, 119, 255],
                    width: 3
                }
            };

            const query = new Query();
            query.where = where;
            query.outSpatialReference = vi.spatialReference;
            query.returnGeometry = true;
            query.returnQueryGeometry = true;
            query.outFields = ["*"];
            query.maxRecordCount = 100;
            query.exceededTransferLimit = true;
            // console.log({
            //     where, 
            //     qtd: where.split('OR').length
            // });
            lotes.queryFeatures(query).then(function (results) {
                const lengthFeature = results.features.length;
                if (lengthFeature === 0) {
                    that.showAlertResult();
                } else {
                    that.props.view.graphics = null;
                }
                // console.log({lengthFeature});
                results.features.map(function (feature) {

                    //console.log(JSON.stringify(feature, undefined, 2))

                    //Create a polygon geometry
                    var polygon = {
                        type: "polygon", // autocasts as new Polygon()
                        hasZ: true,
                        hasM: true,
                        rings: feature.geometry.rings[0],
                        spatialReference: { wkid: feature.geometry.spatialReference.wkid }
                    };

                    var polygonGraphic = new Graphic({
                        geometry: polygon,
                        symbol: fillSymbol
                    });

                    vi.graphics.remove(polygonGraphic);
                    vi.graphics.add(polygonGraphic);

                    return true;
                });
                if (lengthFeature > 0) {
                    lotes.queryExtent(query).then(function (results) {
                        vi.goTo({ target: results.extent }, { duration: 200 });
                    });
                }
            });
        });


    }

    handleExport = async () => {
        console.log("Exportar resultados para CSV")
    }

    showTransmissoes = async (dados) => {
        this.panelTransmissoes.current.style.display = "block";
        this.panelFilter.current.style.display = "none";

        /**
        * Pegar todos os resultados da pesquisa e pintar os lotes
        */
        if (typeof dados === "undefined") {
            return;
        }
        if (dados.length === 0) {
            return;
        }
        const makeWhere = dados.map(({ Codi_Cart }) => `Codi_Cart='${Codi_Cart}'`).join(' OR ')
        this.handleZoom(makeWhere);
    }

    removeAllProximidade = () => {
        const { map } = this.props.view;
        const getLayerAndSublayers = map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        });
        const layerPoligonoProximidade = getLayerAndSublayers.find(function (layer) {
            return layer.title === "Polígono de Proximidade";
        });
        layerPoligonoProximidade.graphics.removeAll();
    }

    clearFilters = async () => {
        this.props.view.graphics = null;
        this.removeAllProximidade();
        this.setState(
            {
                tableDataResults: [],
                tableDataFilter: [],
                sum_areas: 0,
                sum_ponderada: 0,
                ponderada: 0

            }
        );
    }

    showFilter = async () => {
        this.panelTransmissoes.current.style.display = "none";
        this.panelFilter.current.style.display = "block";
    }

    closeAlertMessage = () => {
        this.setState({
            alertMessageStatus: false
        });
    }

    closeAlertMessageResult = () => {
        this.setState({
            alertMessageResult: false,
        });
    }

    showAlertResult = () => {
        this.setState({
            alertMessageResult: true,
        })
    }

    openModalGraficos = (action) => {
        this.setState({ modalGraficos: action })
    }

    tableRowClicked = (rows) => {
        if (typeof rows === "undefined") {
            return;
        }
        const makeWhere = rows
            .map(({Codi_Cart}) => `codi_cart= '${Codi_Cart}'`)
            .join(' OR ');
        this.handleZoom(makeWhere);
    }

    componentWillUnmount() {
        if (!this.props.view) {
            return;
        }
        const { map } = this.props.view;
        this.props.view.graphics.removeAll();
        const getLayerAndSublayers = map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        });
        const layerPoligonoProximidade = getLayerAndSublayers.find(function (layer) {
            return layer.title === "Polígono de Proximidade";
        });
        this.props.view.map.remove(layerPoligonoProximidade);
    }

    render() {

        let valorInput;

        const years = [];
        let today = new Date();
        let end = today.getFullYear() + 1;
        let step = 1;
        for (let i = 1990; i < end; i += step) {
            years.push(i);
        }

        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];

        //Exportar para CSV e XLS
        const csvData = [
            [
                "Codi_Imov",
                "Data_Guia",
                "Classificacao",
                "Tipo_Imovel",
                "Atividade",
                "valo_laud",
                "Idade",
                "Area_Terreno",
                "Area_Predial",
                "Estado_Conservacao",
                "Padrao",
                "Qtde_Pavimentos",
                "Proprietario",
                "Ende_Comp",
                "nume_guia",
                "Codi_Cart"
            ]
        ];

        let excelData = [];
        let tipologiasCount = {
            APARTAMENTO: [],
            CASA: [],
            TERRENO: [],
            BARRACO: [],
            TELHEIRO: [],
            SALA: [],
            INDUSTRIA: [],
            LOJA: [],
            DEPOSITO: [],
            COM_RESIDENCIA: [],
            OUTROS: [],
            TERRITORIAL: [],
            PREDIAL: []
        }
        if (this.state.tableDataResults.length > 0) {
            for (let v = 0; v < this.state.tableDataResults.length; v++) {
                csvData.push([
                    this.state.tableDataResults[v].Codi_Imov,
                    this.state.tableDataResults[v].Data_Guia,
                    this.state.tableDataResults[v].Classificacao,
                    this.state.tableDataResults[v].Tipo_Imovel,
                    this.state.tableDataResults[v].Atividade,
                    this.state.tableDataResults[v].valo_laud,
                    this.state.tableDataResults[v].Idade,
                    this.state.tableDataResults[v].Area_Terreno,
                    this.state.tableDataResults[v].Area_Predial,
                    this.state.tableDataResults[v].Estado_Conservacao,
                    this.state.tableDataResults[v].Padrao,
                    this.state.tableDataResults[v].Qtde_Pavimentos,
                    this.state.tableDataResults[v].Proprietario,
                    this.state.tableDataResults[v].Ende_Comp,
                    this.state.tableDataResults[v].nume_guia,
                    this.state.tableDataResults[v].Codi_Cart
                ]);

                let tipologias = this.state.tableDataResults[v].Classificacao ? this.state.tableDataResults[v].Classificacao.trim() : this.state.tableDataResults[v].Classificacao

                switch (tipologias) {
                    case 'APTO.':
                        tipologiasCount.APARTAMENTO.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'CASA':
                        tipologiasCount.CASA.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'BARRACO':
                        tipologiasCount.BARRACO.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'TELHEIRO':
                        tipologiasCount.TELHEIRO.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'SALA':
                        tipologiasCount.SALA.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'INDÚSTRIA':
                        tipologiasCount.INDUSTRIA.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'LOJA':
                        tipologiasCount.LOJA.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'DEPÓSITO':
                        tipologiasCount.DEPOSITO.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'COM/RESIDÊNCIA':
                        tipologiasCount.COM_RESIDENCIA.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'TERRENO':
                        tipologiasCount.TERRENO.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    default:
                        tipologiasCount.OUTROS.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                }

                let tipoImovel = this.state.tableDataResults[v].Tipo_Imovel ? this.state.tableDataResults[v].Tipo_Imovel.trim() : this.state.tableDataResults[v].Tipo_Imovel
                switch (tipoImovel) {
                    case 'TERRITORIAL':
                        tipologiasCount.TERRITORIAL.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    case 'PREDIAL':
                        tipologiasCount.PREDIAL.push(this.state.tableDataResults[v].Codi_Imov)
                        break;
                    default:
                        break;
                }

                excelData.push(
                    {
                        Codi_Imov: this.state.tableDataResults[v].Codi_Imov,
                        Data_Guia: this.state.tableDataResults[v].Data_Guia,
                        Classificacao: this.state.tableDataResults[v].Classificacao,
                        Tipo_Imovel: this.state.tableDataResults[v].Tipo_Imovel,
                        valo_laud: this.state.tableDataResults[v].valo_laud,
                        Idade: this.state.tableDataResults[v].Idade,
                        Area_Terreno: this.state.tableDataResults[v].Area_Terreno,
                        Area_Predial: this.state.tableDataResults[v].Area_Predial,
                        Estado_Conservacao: this.state.tableDataResults[v].Estado_Conservacao,
                        Padrao: this.state.tableDataResults[v].Padrao,
                        Qtde_Pavimentos: this.state.tableDataResults[v].Qtde_Pavimentos,
                        Proprietario: this.state.tableDataResults[v].Proprietario,
                        Ende_Comp: this.state.tableDataResults[v].Ende_Comp,
                        nume_guia: this.state.tableDataResults[v].nume_guia,
                        Codi_Cart: this.state.tableDataResults[v].Codi_Cart,

                        valor_metro_quadrado: isNaN(Number(this.state.tableDataResults[v].valo_laud / this.state.tableDataResults[v].Area_Predial)) ||
                            !isFinite(Number(this.state.tableDataResults[v].valo_laud / this.state.tableDataResults[v].Area_Predial)) ? 0 :
                            Number(this.state.tableDataResults[v].valo_laud / this.state.tableDataResults[v].Area_Predial).toFixed(2),
                        ponderacao: isNaN(Number((this.state.tableDataResults[v].valo_laud / this.state.tableDataResults[v].Area_Predial) * this.state.tableDataResults[v].Area_Predial)) ||
                            !isFinite(Number((this.state.tableDataResults[v].valo_laud / this.state.tableDataResults[v].Area_Predial) * this.state.tableDataResults[v].Area_Predial)) ? 0 :
                            Number((this.state.tableDataResults[v].valo_laud / this.state.tableDataResults[v].Area_Predial) * this.state.tableDataResults[v].Area_Predial).toFixed(2)
                    }
                )

            }
        }

        let excelDataResumo = [
            {
                soma_areas: this.state.sum_areas ? this.state.sum_areas.toFixed(2) + " m²" : 0 + " m²",
                soma_ponderacao: this.state.sum_ponderada ? this.state.sum_ponderada.toFixed(2) : 0,
                valor_medio_ponderado: this.state.ponderada ? this.state.ponderada.toFixed(2) : 0
            }
        ];


        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        let ExcelSheetGen =
            <ExcelFile element={<Dropdown.Item eventKey="1" onClick={() => this.handleExport()}>Para Excel</Dropdown.Item>}>
                <ExcelSheet data={excelData} name="Transmissoes">
                    <ExcelColumn label="Código do Imóvel" value="Codi_Imov" />
                    <ExcelColumn label="Data" value="Data_Guia" />
                    <ExcelColumn label="Classificação" value="Classificacao" />
                    <ExcelColumn label="Tipo do Imóvel" value="Tipo_Imovel" />
                    <ExcelColumn label="Atividade" value="Atividade" />
                    <ExcelColumn label="Valor Avaliação" value="valo_laud" />
                    <ExcelColumn label="Idade" value="Idade" />
                    <ExcelColumn label="Área do Terreno" value="Area_Terreno" />
                    <ExcelColumn label="Area da Unidade" value="Area_Predial" />
                    <ExcelColumn label="Conservação" value="Estado_Conservacao" />
                    <ExcelColumn label="Padrao" value="Padrao" />
                    <ExcelColumn label="Qtde de Pavimentos" value="Qtde_Pavimentos" />
                    <ExcelColumn label="Proprietário" value="Proprietario" />
                    <ExcelColumn label="Nº Guia" value="nume_guia" />
                    <ExcelColumn label="Cartografia" value="Codi_Cart" />

                    <ExcelColumn label="Valor da Operação" value="nu_valor_avaliacao" />
                    <ExcelColumn label="Valor m²" value="valor_metro_quadrado" />
                    <ExcelColumn label="Ponderação" value="ponderacao" />
                </ExcelSheet>
                <ExcelSheet data={excelDataResumo} name="Quadro Resumo">
                    <ExcelColumn label="Soma total das Áreas - AI (m²)" value="soma_areas" />
                    <ExcelColumn label="Soma da Ponderação (AI x VM)" value="soma_ponderacao" />
                    <ExcelColumn label="Valor Médio Ponderado (VMP)" value="valor_medio_ponderado" />
                </ExcelSheet>
            </ExcelFile>


        //console.log(this.state.tableDataResults)

        switch (this.state.coluna) {
            /*case 'geocodigo':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
            break;*/
            case 'id_transmissao':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'tipo_imovel':
                valorInput = <Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                    <option disabled hidden value=''>-</option>
                    <option value="PREDIAL">Predial</option>
                    <option value="TERRITORIAL">Territorial</option>
                </Form.Control>
                break;
            case 'tipologia':
                valorInput = <Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                    <option disabled hidden value=''>-</option>
                    <option value="APTO.">Apartamento</option>
                    <option value="CASA">Casa</option>
                    <option value="BARRACO">Barraco</option>
                    <option value="TELHEIRO">Telheiro</option>
                    <option value="SALA">Sala</option>
                    <option value="EDICULA">Edicula</option>
                    <option value="LOJA">Loja</option>
                    <option value="COM/RESIDÊNCIA">Comercial com Residência</option>
                    <option value="POSTO DE SERVIÇO">Posto de Serviço</option>
                    <option value="EDF. COMERCIAL">Edificio Comercial</option>
                    <option value="INDÚSTRIA">Indústria</option>
                    <option value="COBERTURA METÁLICA">Cobertura Metálica</option>
                    <option value="GARAGEM">Garagem</option>
                    <option value="DEPÓSITO">Depósito</option>
                    <option value="GALPAO ABERTO">Galpão Aberto</option>
                    <option value="GALPAO FECHADO">Galpão Fechado</option>
                    <option value="GALPAO INDUSTRIAL">Galpão Industrial</option>
                    <option value="IGREJA/TEMPLO">Igreja/Templo</option>
                    <option value="SOBRADO">Sobrado</option>
                    <option value="PALAFITA">Palafita</option>
                    <option value="MOCAMBO/SIMILAR">Mocambo</option>
                    <option value="FINANCEIRA">Financeira</option>
                    <option value="ESCOLA/CRECHE">Escola/Creche</option>
                    <option value="HOTEL">Hotel</option>
                    <option value="CLUBE/ENTRETEN.">Clube</option>
                    <option value="POSTO DE COMBUSTÍVEL">Posto de Combustível</option>
                    <option value="HOSPITAL/CLINICA">Hospital/Clínica</option>
                    <option value="EDIFICIO GARAGEM">Edi. Garagem</option>
                    <option value="ESPECIAL">Especial</option>
                    <option value="NULL">Não Definido (NULL)</option>
                </Form.Control>
                break;
            case 'padrao':
                valorInput = <Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                    <option disabled hidden value=''>-</option>
                    <option value="ALTO">ALTO</option>
                    <option value="NORMAL">NORMAL</option>
                    <option value="BAIXO">BAIXO</option>
                </Form.Control>
                break;
            case 'idade':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'area_construida':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'conservacao':
                valorInput = <Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                    <option disabled hidden value=''>-</option>
                    <option value="OTIMO">OTIMO</option>
                    <option value="BOM">BOM</option>
                    <option value="REGULAR">REGULAR</option>
                    <option value="RUIM">RUIM</option>
                </Form.Control>
                break;
            case 'pavimentos':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'sequencial':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'area_terreno':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'estrutura':
                valorInput = <Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                    <option disabled hidden value=''>-</option>
                    <option value="TIPO CAIXAO">TIPO CAIXAO</option>
                    <option value="SEMI-PILOTIS">SEMI-PILOTIS</option>
                    <option value="SOBRE PILOTIS">SOBRE PILOTIS</option>
                    <option value="SEMI-ENTERRADO">SEMI-ENTERRADO</option>
                    <option value="COM SUBSOLO">COM SUBSOLO</option>
                </Form.Control>
                break;
            default:
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;

        }

        const dataChart1 = {
            labels: [
                'APARTAMENTO', 'CASA', 'TERRENO', 'BARRACO', 'TELHEIRO', 'SALA/CONJUNTO', 'INDÚSTRIA',
                'LOJA', 'DEPÓSITO', 'COM/RESIDÊNCIA', 'OUTROS'
            ],
            datasets: [
                {
                    label: 'Tipologia',
                    data: [
                        tipologiasCount.APARTAMENTO.length,
                        tipologiasCount.CASA.length,
                        tipologiasCount.TERRENO.length,
                        tipologiasCount.BARRACO.length,
                        tipologiasCount.TELHEIRO.length,
                        tipologiasCount.SALA.length,
                        tipologiasCount.INDUSTRIA.length,
                        tipologiasCount.LOJA.length,
                        tipologiasCount.DEPOSITO.length,
                        tipologiasCount.COM_RESIDENCIA.length,
                        tipologiasCount.OUTROS.length,
                    ],
                    backgroundColor: [
                        'rgba(13, 179, 158, 0.2)',
                        'rgba(249, 65, 68, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 25, 19, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(45, 45, 99, 0.2)',
                        'rgba(248, 150, 30, 0.2)',
                        'rgba(132, 165, 157, 0.2)',
                        'rgba(116, 0, 184, 0.2)',
                        'rgba(50, 48, 49, 0.2)',
                        'rgba(115, 93, 120, 0.2)',
                    ],
                    borderColor: [
                        'rgba(13, 179, 158, 1)',
                        'rgba(249, 65, 68, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 25, 19, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(45, 45, 99, 1)',
                        'rgba(248, 150, 30, 1)',
                        'rgba(132, 165, 157, 1)',
                        'rgba(116, 0, 184, 1)',
                        'rgba(50, 48, 49, 1)',
                        'rgba(115, 93, 120, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }

        const dataChart2 = {
            labels: ['Tipo de Imóvel'],
            datasets: [
                {
                    label: 'PREDIAL',
                    data: [tipologiasCount.PREDIAL.length],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'TERRITORIAL',
                    data: [tipologiasCount.TERRITORIAL.length],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                }

            ],
        }

        const optionsCharts = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        }

        let modalgraficos;
        if (this.state.modalGraficos) {
            modalgraficos = <Modal
                show={this.state.modalGraficos}
                onHide={e => this.openModalGraficos(!this.state.modalGraficos)}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Gráficos:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="info" className="rowSpacing">
                        Transmissões por Tipologia
                    </Alert>
                    <section>
                        <Pie data={dataChart1} />
                    </section>
                    <hr />
                    <Alert variant="info" className="rowSpacing">
                        Transmissões por Tipo de Imóvel
                    </Alert>
                    <section>
                        <Bar data={dataChart2} options={optionsCharts} />
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => this.openModalGraficos(!this.state.modalGraficos)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        } else {
            modalgraficos = null
        }


        return (

            <section id="pesquisaTransmissoes">

                <ButtonGroup size="sm">
                    <Button variant="info" onClick={() => this.showFilter()}> Filtro Avançado</Button>
                    <Button disabled={this.state.btnDisabled} variant="primary" onClick={() => this.showTransmissoes(this.state.tableDataResults)}>Resultados</Button>
                </ButtonGroup>

                <section className="transmissoesRowSpacing" ref={this.panelFilter}>

                    <Form onSubmit={e => this.includeExpressions(e)} noValidate className={this.state.setValidated ? 'is-invalid was-validated form-control:invalid' : ''}>
                        <Alert variant={this.state.alertType} show={this.state.alertMessageStatus} onClose={() => this.closeAlertMessage()} dismissible>
                            {this.state.alertMessage}
                        </Alert>
                        <Row>
                            <Col>
                                {/*<FormControl size="sm" as={CurrencyFormat} format="##/##/####" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']} name="datainicial" value={this.state.datainicial || this.state.todayis} onChange={this.handleChange} placeholder={this.state.todayis} required />*/}
                                <Form.Label><strong>Data Inicial</strong></Form.Label>
                                <FormControl size="sm" as={DatePicker}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >

                                            <Button size="sm" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                <MdNavigateBefore size={16} color="#fafafa" />
                                            </Button>
                                            <Form.Control as="select" size="sm"
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Form.Control as="select" size="sm"
                                                value={months[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Button size="sm" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                <MdNavigateNext size={16} color="#fafafa" />
                                            </Button>
                                        </div>
                                    )}
                                    locale="pt"
                                    withPortal
                                    selected={this.state.datainicial}
                                    dateFormat="dd/MM/yyyy"
                                    name="datainicial"
                                    value={this.state.datainicial}
                                    onChange={date => this.handleDateChange(date, 'datainicial')}
                                    required
                                />
                            </Col>
                            <Col>
                                {/*<FormControl size="sm" as={CurrencyFormat} format="##/##/####" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}  name="datafinal" value={this.state.datafinal || this.state.todayis} onChange={this.handleChange} placeholder={this.state.todayis} required />*/}
                                <Form.Label><strong>Data Final</strong></Form.Label>
                                <FormControl size="sm" as={DatePicker}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button size="sm" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                <MdNavigateBefore size={16} color="#fafafa" />
                                            </Button>
                                            <Form.Control as="select" size="sm"
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Form.Control as="select" size="sm"
                                                value={months[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Button size="sm" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                <MdNavigateNext size={16} color="#fafafa" />
                                            </Button>
                                        </div>
                                    )}
                                    locale="pt"
                                    withPortal
                                    selected={this.state.datafinal}
                                    dateFormat="dd/MM/yyyy"
                                    name="datafinal"
                                    value={this.state.datafinal}
                                    onChange={date => this.handleDateChange(date, 'datafinal')}
                                    required
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Coluna</Form.Label>
                                    <Form.Control as="select" size="sm" value={this.state.coluna || ''} name="coluna" onChange={this.handleChange} required>
                                        <option disabled hidden value=''>-</option>
                                        {/*<option value="geocodigo">Geocódigo da Unidade</option>*/}
                                        <option value="id_transmissao">Id da Transmissão</option>
                                        <option value="sequencial">Sequencial</option>
                                        <option value="tipo_imovel">Tipo do Imóvel (Natureza)</option>
                                        <option value="tipologia">Tipologia</option>
                                        <option value="padrao">Padrão</option>
                                        <option value="idade">Idade</option>
                                        <option value="area_construida">Área Construída</option>
                                        <option value="conservacao">Conservação</option>
                                        <option value="pavimentos">Pavimentos</option>
                                        <option value="area_terreno">Área do Terreno</option>
                                        <option value="estrutura">Estrutura</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Operador</Form.Label>
                                    <Form.Control as="select" size="sm" value={this.state.operador || ''} name="operador" onChange={this.handleChange} required>
                                        <option disabled hidden value=''>-</option>
                                        <option value="=">Igual</option>
                                        <option value="<>">Diferente</option>
                                        {
                                            this.state.coluna === "area_construida" ||
                                                this.state.coluna === "area_terreno" ||
                                                this.state.coluna === "idade" ? (
                                                <option value=">=">Maior ou igual</option>
                                            ) : null
                                        }
                                        {
                                            this.state.coluna === "area_construida" ||
                                                this.state.coluna === "area_terreno" ||
                                                this.state.coluna === "idade" ? (
                                                <option value="<=">Menor ou igual</option>
                                            ) : null
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Valor</Form.Label>
                                    {this.state.coluna !== 'tipologia' && valorInput}
                                    {this.state.coluna === 'tipologia' && (<Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                                        <option disabled hidden value=''>-</option>
                                        {this.state.topologyValueSelectBox && this.state.topologyValueSelectBox.map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </Form.Control>)}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button disabled={this.state.btnDisabled} variant="success" size="sm" type="submit" block>
                            <FaPlusCircle size={16} color="#fafafa" />
                            {'  '}
                            Incluir Expressão
                        </Button>

                    </Form>
                    <hr />

                    <Button ref={this.startButtonsFP} disabled={this.state.btnDisabled} onClick={() => this.drawPolygonFiltroProximidade()} variant="info" size="sm" block>
                        <FaDrawPolygon size={16} color="#fafafa" />
                        {'  '}
                        Cálculo por Proximidade
                    </Button>

                    <Alert variant="info" ref={this.actionButtonsFP}>
                        <strong>Realize o desenho do polígono.</strong>
                        <hr />
                        <ButtonGroup size="sm">
                            <Button onClick={() => this.cancelFiltroProximidade()} variant="danger">Cancelar</Button>
                            <Button disabled={this.state.doneButtonFP} onClick={() => this.doneFiltroProximidade()} variant="success">Incluir Polígono</Button>
                        </ButtonGroup>
                    </Alert>
                    <hr />

                    <section className="transmissoesList transmissoesRowSpacing">
                        <DataTable
                            striped={true}
                            noHeader={true}
                            columns={this.columnsFilter}
                            data={this.state.tableDataFilter}
                            customStyles={this.customStylesResults}
                            progressPending={this.state.isLoaded}
                            dense
                            pagination
                            paginationComponentOptions={{ rowsPerPageText: '', rangeSeparatorText: 'de', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
                            highlightOnHover
                        />
                    </section>

                    <ButtonGroup size="sm">
                        <Button disabled={this.state.btnDisabled} onClick={() => this.clearFilters()} variant="danger">
                            <FaTrashAlt size={16} color="#fafafa" />
                            {'  '}
                            Limpar Filtros
                        </Button>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.handleFilter()} variant="success">
                            <FaSearchLocation size={16} color="#fafafa" />
                            {'  '}
                            Consultar
                        </Button>
                    </ButtonGroup>

                </section>

                <section className="transmissoesRowSpacing" ref={this.panelTransmissoes}>
                    <Alert variant='danger' show={this.state.alertMessageResult} onClose={() => this.closeAlertMessageResult()} dismissible>
                        Não foi possível localizar a inscrição no mapa
                    </Alert>
                    <DataTable
                        className="transmissoesList"
                        striped={true}
                        fixedHeader={true}
                        fixedHeaderScrollHeight="260px"
                        noHeader={true}
                        columns={this.columnsResults}
                        data={this.state.tableDataResults}
                        customStyles={this.customStylesResults}
                        progressPending={this.state.isLoaded}
                        progressComponent={<WidgetLoading />}
                        pagination
                        paginationTotalRows={this.state.totalRows}
                        paginationComponentOptions={{ rowsPerPageText: '', rangeSeparatorText: 'de', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
                        highlightOnHover={true}
                        selectableRowsHighlight={true}
                        selectableRows
                        onSelectedRowsChange={row => this.tableRowClicked(row.selectedRows)}
                        pointerOnHover={true}
                    />
                    <hr />

                    <ButtonGroup size="sm" style={{ padding: '2px' }}>
                        <OverlayTrigger trigger="click" placement="left" overlay={<Tooltip>Soma total das Áreas dos Imóveis<br /> em m²</Tooltip>}>
                            <Button variant="info"> AI (m²):<br />
                                {this.state.sum_areas ? this.state.sum_areas.toFixed(2) + " m²" : 0 + " m²"}
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger trigger="click" placement="left" overlay={<Tooltip>Ponderação<br /> Área do Imóvel x Valor Metro Quadrado do Imóvel</Tooltip>}>
                            <Button variant="primary"> (AI x VM):<br />
                                <CurrencyFormat value={this.state.sum_ponderada ? this.state.sum_ponderada.toFixed(2) : 0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger trigger="click" placement="left" overlay={<Tooltip>Valor Médio Ponderado (VMP) do m² por Proximidade Espacial<br /> (Ponderação / Total AI) </Tooltip>}>
                            <Button variant="success"> (VMP):<br />
                                <CurrencyFormat value={this.state.ponderada ? this.state.ponderada.toFixed(2) : 0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                            </Button>
                        </OverlayTrigger>
                    </ButtonGroup>

                    <hr />

                    <ButtonGroup size="sm" style={{ padding: '5px' }}>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.clearFilters()} variant="danger">
                            <FaTrashAlt size={16} color="#fafafa" />
                            {'  '}
                            Limpar
                        </Button>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.showTransmissoes(this.state.tableDataResults)} variant="success">
                            <FaSearchLocation size={16} color="#fafafa" />
                            {'  '}
                            Zoom
                        </Button>
                        <DropdownButton
                            disabled={this.state.btnDisabled}
                            variant="primary"
                            size="sm"
                            as={ButtonGroup}
                            title={<strong><FaFileCsv size={16} color="#fafafa" /> Exportar</strong>}
                            key={"left"}
                            id={`dropdown-button-drop-left`}
                            drop={"left"}
                        >

                            {ExcelSheetGen}
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="2" as={CSVLink} data={csvData} onClick={() => this.handleExport()} >Para CSV</Dropdown.Item>
                        </DropdownButton>

                        <Button disabled={this.state.btnDisabled} onClick={() => this.openModalGraficos(true)} variant="info">
                            <FaChartBar size={16} color="#fafafa" />
                            {'  '}
                            Gráficos
                        </Button>
                    </ButtonGroup>
                </section>

                {modalgraficos}




            </section>



        );
    }
}


export default Transmissoes;


