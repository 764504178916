import React from "react";
import ReactDOM from "react-dom";
import { loadModules } from "esri-loader";
import {
  Alert,
  ButtonGroup,
  Button,
  Form,
  /*DropdownButton, Dropdown,*/ InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap";
import EsriSearchServices from "../EsriMap/search_services";
import randomstring from "randomstring";
import Popup from "../Popup";
import ScaleLoader from "react-spinners/ScaleLoader";
//import {ViewContext} from '../EsriMap/context';

import { FaSearch } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { ImMenu3 } from "react-icons/im";

import Config from "../../config";
import "./style.css";

class PesquisaRapida extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: "loading",
      error: null,
      isLoaded: true,
      formAction: "",
      setValidated: false,
      menuOpened: true,
      campo: "",
      campoLabel: "Selecione uma opção",
      classResOpen: "resOpenFalse",
      searchLayers: [],
      searchGeocode: [],
      resultList: [],
      popup: false,
      popupMapPoint: null,

      isErrorMenu: false,
      messageError: "",

      selectLayer: "",
    };

    this.qualCampo = this.qualCampo.bind(this);
    this.limparCampo = this.limparCampo.bind(this);
    this.popupOpened = this.popupOpened.bind(this);

    this.rootView = null;
    this.centerX = null;
    this.centerY = null;

    this.resultsPesquisa = React.createRef();
    this.menuPesquisa = React.createRef();
    this.inputSearch = React.createRef();

    //Configuracoes
    this.config = Config.configs();
  }

  componentDidMount() {
    this.resultsPesquisa.current.style.display = "none";
    this.menuPesquisa.current.style.display = "none";

    this.rootView = document.getElementById("root");
    this.centerX = this.rootView.offsetLeft + this.rootView.offsetWidth / 2;
    this.centerY = this.rootView.offsetTop + this.rootView.offsetHeight / 2;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isErrorMenu: false,
    });
  };

  handleKeyUp = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    if (event.target.value.length === 0) {
      this.resultsPesquisa.current.style.display = "none";
    } else {
      this.handlePreLoadData(event);
      this.resultsPesquisa.current.style.display = "block";
    }

    this.menuPesquisa.current.style.display = "none";
  };

  qualCampo = (campo) => {
    //console.log(campo)
    this.menuPesquisa.current.style.display = "none";
    this.resultsPesquisa.current.style.display = "none";
    this.setState({
      campo: campo,
      campoLabel: "Buscar " + campo,
      menuOpened: true,
    });

    this.inputSearch.current.focus();
    this.selectSearchDefault(document.location.href);
  };

  limparCampo = () => {
    if (this.props.view) {
      this.props.view.graphics = null;
    }
    document.location.href = `${document.location.href.split("#")[0]}#`;
    this.setState({
      campo: "",
      campoLabel: "Selecione uma opção",
      buscar: "",
      searchLayers: [],
      searchGeocode: [],
      menuOpened: true,
      popup: false,
      popupMapPoint: null,
    });
    const clearOptionSearchActive = Array.from(
      this.menuPesquisa.current.querySelectorAll(".active")
    );
    clearOptionSearchActive.forEach((el) => el.classList.remove("active"));
    // console.log(this.menuPesquisa.current);

    this.resultsPesquisa.current.style.display = "none";
    this.menuPesquisa.current.style.display = "none";
  };

  abrirMenu = (status) => {
    this.setState({
      menuOpened: status,
    });

    if (this.state.menuOpened) {
      this.menuPesquisa.current.style.display = "block";
    } else {
      this.menuPesquisa.current.style.display = "none";
    }

    this.resultsPesquisa.current.style.display = "none";
  };

  /**
   * Para personalizar a pesquisa incluir cases nos Switchs
   * @param {*} layer
   * @param {*} where
   */
  showPolygon = async (layer, where) => {
    //console.log(this.props.view)
    try {
      this.props.view.graphics = null;

      const [FeatureLayer, Query, Graphic] = await loadModules([
        "esri/layers/FeatureLayer",
        "esri/tasks/support/Query",
        "esri/Graphic",
      ]);

      //Query por Geocoding - Dentro da View.When
      var fillSymbol = {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [102, 153, 255, 0.4],
        outline: {
          color: [51, 119, 255],
          width: 3,
        },
      };

      let qlayer = new FeatureLayer(layer);

      //" geocoding = '"+where+"' "
      const query = new Query();
      query.where = where;
      query.outSpatialReference = this.props.view.spatialReference;
      query.returnGeometry = true;
      query.returnQueryGeometry = true;
      query.outFields = ["*"];

      this.props.view.when((vi) => {
        qlayer.queryFeatures(query).then((results) => {
          results.features.map((feature) => {
            const polygon = {
              type: "polygon",
              hasZ: true,
              hasM: true,
              rings: feature.geometry.rings[0],
              spatialReference: {
                wkid: feature.geometry.spatialReference.wkid,
              },
            };

            const polygonGraphic = new Graphic({
              geometry: polygon,
              symbol: fillSymbol,
            });

            this.setState({
              popup: true,
              popupMapPoint: {
                x: this.centerX,
                y: this.centerY,
                mapPoint: feature.geometry.centroid,
              },
              selectLayer: qlayer.title,
            });

            vi.graphics.remove(polygonGraphic);
            vi.graphics.add(polygonGraphic);
          });

          if (results.features.length === 0) {
            this.setState({
              searchLayers: [],
              menuOpened: true,
              isErrorMenu: true,
              messageError: "Erro nenhum resultado encontrado",
            });
            return;
          }
          //Zoom nos resultados
          qlayer.queryExtent(query).then((results) => {
            vi.goTo({ target: results.extent, zoom: 18 }, { duration: 500 });
          });
          this.resultsPesquisa.current.style.display = "none";
        });
      });
    } catch (e) {
      console.log("Poligono não encontrado");
    }
  };

  showLine = async (layer, where) => {
    //console.log(this.props.view)
    try {
      this.props.view.graphics = null;

      const [FeatureLayer, Query, Graphic] = await loadModules([
        "esri/layers/FeatureLayer",
        "esri/tasks/support/Query",
        "esri/Graphic",
      ]);

      let qlayer = new FeatureLayer(layer);

      // query " geocoding = '"+where+"' "
      const query = new Query();
      query.where = where;
      query.outSpatialReference = this.props.view.spatialReference;
      query.returnGeometry = true;
      query.returnQueryGeometry = true;
      query.outFields = ["*"];

      this.props.view.when((vi) => {
        qlayer.queryFeatures(query).then((results) => {
          results.features.map((feature) => {
            //Create a polygon geometry
            var line = {
              type: "polyline",
              hasZ: true,
              hasM: true,
              paths: feature.geometry.paths[0],
              spatialReference: {
                wkid: feature.geometry.spatialReference.wkid,
              },
            };

            var lineGraphic = new Graphic({
              geometry: line,
              symbol: {
                type: "simple-line", // autocasts as SimpleLineSymbol()
                color: [226, 119, 40],
                width: 3,
              },
            });

            this.setState({
              popup: true,
              popupMapPoint: {
                x: this.centerX,
                y: this.centerY,
                mapPoint: lineGraphic.geometry.extent.center,
              },
              selectLayer: qlayer.title,
            });

            vi.graphics.remove(lineGraphic);
            vi.graphics.add(lineGraphic);
          });
        });

        //Zoom nos resultados
        qlayer.queryExtent(query).then((results) => {
          vi.goTo({ target: results.extent, zoom: 18 }, { duration: 500 });
        });
      });
    } catch (e) {
      console.log("Linha não encontrada");
    }
  };

  showPoint = async (layer, where) => {
    //console.log(this.props.view)
    try {
      this.props.view.graphics = null;

      const [FeatureLayer, Query, Graphic] = await loadModules([
        "esri/layers/FeatureLayer",
        "esri/tasks/support/Query",
        "esri/Graphic",
      ]);

      let qlayer = new FeatureLayer(layer);

      // query " geocoding = '"+where+"' "
      const query = new Query();
      query.where = where;
      query.outSpatialReference = this.props.view.spatialReference;
      query.returnGeometry = true;
      query.returnQueryGeometry = true;
      query.outFields = ["*"];

      this.props.view.when((vi) => {
        qlayer.queryFeatures(query).then((results) => {
          results.features.map((feature) => {
            var point = {
              type: "point",
              hasZ: true,
              hasM: true,
              x: feature.geometry.x,
              y: feature.geometry.y,
              spatialReference: {
                wkid: feature.geometry.spatialReference.wkid,
              },
            };

            var pointGraphic = new Graphic({
              geometry: point,
              symbol: {
                type: "simple-marker",
                size: 6,
                color: "#b7e4c7",
                outline: {
                  color: "rgba(45, 106, 79, 0.5)",
                  width: 5,
                },
              },
            });

            this.setState({
              popup: true,
              popupMapPoint: {
                x: this.centerX,
                y: this.centerY,
                mapPoint: pointGraphic.geometry,
              },
              selectLayer: qlayer.title,
            });

            vi.graphics.remove(pointGraphic);
            vi.graphics.add(pointGraphic);
          });
        });

        //Zoom nos resultados
        qlayer.queryExtent(query).then((results) => {
          vi.goTo({ target: results.extent, zoom: 18 }, { duration: 500 });
        });
      });
    } catch (e) {
      console.log("Ponto não encontrado");
    }
  };

  showGeocoder = async (geocode) => {
    //console.log(this.props.view)
    try {
      this.props.view.graphics = null;

      const [Graphic] = await loadModules(["esri/Graphic"]);

      this.props.view.when((vi) => {
        var point = {
          type: "point",
          hasZ: true,
          hasM: true,
          x: geocode.location.x,
          y: geocode.location.y,
          spatialReference: { wkid: geocode.location.spatialReference.wkid },
        };

        var pointGraphic = new Graphic({
          geometry: point,
          symbol: {
            type: "simple-marker",
            size: 6,
            color: "#b7e4c7",
            outline: {
              color: "rgba(45, 106, 79, 0.5)",
              width: 5,
            },
          },
        });

        this.setState({
          popup: true,
          popupMapPoint: {
            x: this.centerX,
            y: this.centerY,
            mapPoint: pointGraphic.geometry,
            geocode: geocode.address,
          },
        });

        vi.graphics.remove(pointGraphic);
        vi.graphics.add(pointGraphic);

        //Zoom nos resultados
        vi.goTo({ target: geocode.extent }, { duration: 500 });
      });
    } catch (e) {
      console.log("Geocode não encontrado");
    }
  };

  activeLayer = (title) => {
    const getallLayer = this.props.view.map.layers.flatten(function (item) {
      return item.layers || item.sublayers;
    });
    const _layer = getallLayer.find(function (layer) {
      //console.log(layer.title)
      return layer.title === title;
    });

    _layer.visible = true;
  };

  selectSearchDefault = (campo) => {
    console.log(campo);
    // const campo = '#sequencial';
    this.inputSearch.current.focus();
    const children = Array.from(this.menuPesquisa.current.children);
    children.forEach((el) =>
      el.getAttribute("href") === campo
        ? el.classList.add("active")
        : el.classList.remove("active")
    );
  };

  zoomFeature = async (campo, valor) => {
    //faz o Zoom e Geometry no resultado clicado
    const services = await EsriSearchServices.services();

    switch (campo) {
      case "Código do Imóvel":
        this.activeLayer("Lotes");
        await this.showPolygon(services.layer1, "codi_cart='" + valor + "'");
        break;
      case "Quadra":
        this.activeLayer("Quadras");
        await this.showPolygon(services.layer3, "objectid='" + valor + "'");
        break;
      case "Localização Cartográfica":
        this.activeLayer("Lotes");
        await this.showPolygon(services.layer1, "codi_cart='" + valor + "'");
        break;
      case "Logradouro":
        this.activeLayer("Logradouros");
        await this.showLine(services.layer4, "nome_logr='" + valor + "'");
        break;
      case "Geocoder":
        await this.showGeocoder(valor);
        break;
    }

    if (this.state.popup) {
      this.setState({
        popup: false,
        popupMapPoint: null,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      // form is invalid! so we show error messages
      this.setState({ setValidated: true });
      return;
    }
    // form is valid! We can parse and submit data
    this.setState({
      setValidated: false,
      searchLayers: [],
      searchGeocode: [],
      menuOpened: true,
    });

    const dataForm = {
      buscar: this.state.buscar,
      campo: this.state.campo,
    };

    //Geocoder
    if (this.state.campo === "Geocoder" && this.state.campo !== "Todos") {
      await this.getGeocoder();
      this.resultsPesquisa.current.style.display = "block";
      return;
    }
    //Others
    // if (this.state.campo !== "Geocoder" && this.state.campo !== "Todos") {

    // 10175504 / 14704889
    let geocoding = await fetch(this.config.appurl + "admin/pesquisa/rapida", {
      method: "POST",
      body: JSON.stringify(dataForm),
      headers: {
        "Content-Type": "application/json",
      },
    });

    let sgeo = await geocoding.json();

    //Se for todos - o resultado será um array
    if (sgeo.length > 0) {
      //montar array de resultados com o componente - armazenar itens da lista no estado
      this.setState({
        searchLayers: sgeo,
        menuOpened: true,
      });
    } else {
      console.log("Erro nenhum resultado encontrado");
      this.setState({
        searchLayers: [],
        menuOpened: true,
      });
    }
    // }

    //Todos
    // if (this.state.campo === "Todos") {

    //   // 10175504 / 14704889
    //   let geocoding = await fetch(this.config.appurl + 'admin/pesquisa/rapida', {
    //     method: 'POST',
    //     body: JSON.stringify(dataForm),
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   })

    //   let sgeo = await geocoding.json();
    //   await this.getGeocoder()

    //   //Se for todos - o resultado será um array
    //   if (sgeo.length > 0) {

    //     //montar array de resultados com o componente - armazenar itens da lista no estado
    //     this.setState({
    //       searchLayers: sgeo,
    //       menuOpened: true,
    //     });

    //   } else {
    //     console.log("Erro nenhum resultado encontrado")
    //     this.setState({
    //       searchLayers: [],
    //       menuOpened: true
    //     });
    //   }
    // }

    //mostra os resultados
    this.resultsPesquisa.current.style.display = "block";
  };

  handlePreLoadData = async (event) => {
    // console.log({
    //   event,
    //   ctrl: event.ctrlKey,
    //   shift: event.shiftKey,
    //   alt: event.altKey,
    // });
    if (typeof event.keyCode === "undefined") {
      return;
    }
    // form is valid! We can parse and submit data
    this.setState({
      searchLayers: [],
      searchGeocode: [],
      menuOpened: true,
    });

    const dataForm = {
      buscar: this.state.buscar,
      campo: this.state.campo,
    };
    
    //Geocoder
    if (this.state.campo === "Geocoder" && this.state.campo !== "Todos") {
      await this.getGeocoder();
      this.resultsPesquisa.current.style.display = "block";
      return;
    }

    // if (this.state.campo !== "Geocoder" && this.state.campo !== "Todos") {

    // 10175504 / 14704889
    let geocoding = await fetch(this.config.appurl + "admin/pesquisa/rapida", {
      method: "POST",
      body: JSON.stringify(dataForm),
      headers: {
        "Content-Type": "application/json",
      },
    });

    let sgeo = await geocoding.json();

    //Se for todos - o resultado será um array
    if (sgeo.length > 0) {
      //montar array de resultados com o componente - armazenar itens da lista no estado
      this.setState({
        searchLayers: sgeo,
        menuOpened: true,
      });
    } else {
      console.log("Erro nenhum resultado encontrado");
      this.setState({
        searchLayers: [],
        menuOpened: true,
      });
    }
    // }

    //Todos
    // if (this.state.campo === "Todos") {

    //   // 10175504 / 14704889
    //   let geocoding = await fetch(this.config.appurl + 'admin/pesquisa/rapida', {
    //     method: 'POST',
    //     body: JSON.stringify(dataForm),
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   })

    //   let sgeo = await geocoding.json();
    //   await this.getGeocoder()
    //   console.log(sgeo);

    //   //Se for todos - o resultado será um array
    //   if (sgeo.length > 0) {

    //     //montar array de resultados com o componente - armazenar itens da lista no estado
    //     this.setState({
    //       searchLayers: sgeo,
    //       menuOpened: true,
    //     });

    //   } else {
    //     console.log("Erro nenhum resultado encontrado")
    //     this.setState({
    //       searchLayers: [],
    //       menuOpened: true
    //     });
    //   }
    // }
  };

  getGeocoder = async () => {
    //Geocoder
    let geocoders = [];
    const [Locator] = await loadModules(["esri/tasks/Locator"]);

    let locatorTask = new Locator({
      url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
    });
    const params = {
      address: {
        SingleLine: this.state.buscar
          .split(",")
          .map((i) => i.trim())
          .reverse()
          .join(", "),
        maxLocations: 1,
      },
    };

    // Execute a reverse geocode using the clicked location
    locatorTask
      .addressToLocations(params)
      .then((response) => {
        // If an address is successfully found, show it in the popup's content
        response.map((feature) => {
          geocoders.push({
            id: randomstring.generate().toString(),
            address: feature.address,
            extent: feature.extent,
            location: feature.location,
          });
          return true;
        });

        this.setState({
          //searchGeocode: [...this.state.searchGeocode, geocoders]
          searchGeocode: geocoders,
          menuOpened: true,
        });
      })
      .catch(() => {
        this.setState({
          //searchGeocode: [...this.state.searchGeocode, geocoders]
          searchGeocode: [],
          menuOpened: true,
        });
        // If the promise fails and no result is found, show a generic message
        console.log("No address was found for this location");
      });
  };

  popupOpened = (action) => {
    //console.log(action)
    this.props.view.graphics = null;
    this.setState({ popup: action });
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const layers =
      this.state.searchLayers.map(({ texto, id, valor, campo }) => {
        const randonNumber = Math.random() * (10000 - 100) + 100;
        return (
          <ListGroup.Item
            key={`${id}-${randonNumber}`}
            action
            href={`#pesquisa-${id}-${randonNumber}`}
            onClick={() => this.zoomFeature(campo, valor)}
          >
            {texto}
          </ListGroup.Item>
        );
      }) || [];

    const geocode =
      this.state.searchGeocode.map(({ id, address, extent, location }) => {
        const datageocoder = { address, extent, location };
        const addressShort =
          address//.length > 10 ? `${address.substring(0, 22)}...` : address;
        return (
          <ListGroup.Item
            key={id}
            action
            href={`#pesquisa-${id}`}
            onClick={() => this.zoomFeature("Geocoder", datageocoder)}
          >
            {`Geocoder: ${addressShort}`}
          </ListGroup.Item>
        );
      }) || [];

    return (
      <>
        <Form
          onSubmit={(e) => this.handleSubmit(e)}
          noValidate
          className={
            this.state.setValidated
              ? "is-invalid was-validated form-control:invalid"
              : ""
          }
        >
          <InputGroup>
            <ButtonGroup size="sm" as={InputGroup.Append}>
              <Button
                variant="danger"
                onClick={() => this.abrirMenu(!this.state.menuOpened)}
              >
                <ImMenu3 size={16} color="#fafafa" />{" "}
              </Button>
            </ButtonGroup>

            <FormControl
              size="sm"
              variant="danger"
              id="inputSearch"
              ref={this.inputSearch}
              placeholder={this.state.campoLabel}
              name="buscar"
              autoComplete="off"
              value={this.state.buscar || ""}
              onChange={this.handleChange}
              onKeyUp={this.handleKeyUp}
              onFocus={this.handleKeyUp}
              required
            />

            <ButtonGroup size="sm" as={InputGroup.Prepend}>
              <Button variant="secondary" onClick={() => this.limparCampo()}>
                <MdClear size={16} color="#fafafa" />{" "}
              </Button>
            </ButtonGroup>
            <ButtonGroup size="sm" as={InputGroup.Prepend}>
              <Button variant="danger" type="submit">
                <FaSearch size={16} color="#fafafa" />{" "}
              </Button>
            </ButtonGroup>
          </InputGroup>

          <ListGroup
            variantdefaultactivekey="#"
            id="menuPesquisa"
            ref={this.menuPesquisa}
          >
            <ListGroup.Item
              action
              href="#Quadra"
              onClick={() => this.qualCampo("Quadra")}
            >
              Quadra
            </ListGroup.Item>
            <ListGroup.Item
              action
              href="#CodigoImovel"
              onClick={() => this.qualCampo("Código do Imóvel")}
            >
              Código do Imóvel
            </ListGroup.Item>
            <ListGroup.Item
              action
              href="#Logradouro"
              onClick={() => this.qualCampo("Logradouro")}
            >
              Logradouro
            </ListGroup.Item>
            <ListGroup.Item
              action
              href="#LocalCarto"
              onClick={() => this.qualCampo("Localização Cartográfica")}
            >
              Localização Cartográfica
            </ListGroup.Item>
            <ListGroup.Item
              action
              href="#Geocoder"
              onClick={() => this.qualCampo("Geocoder")}
            >
              Localizador Global
            </ListGroup.Item>
          </ListGroup>

          <ListGroup
            defaultActiveKey="#"
            id="resultsPesquisa"
            ref={this.resultsPesquisa}
          >
            {layers.length > 0 && !this.state.isErrorMenu ? layers : ""}
            {geocode.length > 0 && !this.state.isErrorMenu ? geocode : ""}
            {layers.length === 0 &&
            geocode.length === 0 &&
            !this.state.isErrorMenu ? (
              <ListGroup.Item className="loadingSearch" disabled>
                <ScaleLoader size={20} color={"#c00812"} />
              </ListGroup.Item>
            ) : (
              ""
            )}
            {this.state.isErrorMenu ? (
              <ListGroup.Item className="loadingSearch" disabled>
                <Alert variant="danger">
                  Não foi possível localizar a inscrição no mapa
                </Alert>
              </ListGroup.Item>
            ) : (
              ""
            )}
          </ListGroup>
        </Form>

        {ReactDOM.createPortal(
          this.state.popup === true ? (
            <Popup
              view={this.props.view}
              selectLayer={this.state.selectLayer}
              popupOpened={() => this.popupOpened(!this.state.popup)}
              mappoint={this.state.popupMapPoint}
            />
          ) : null,
          document.getElementById("root")
        )}
      </>
    );
  }
}

//PesquisaRapida.contextType = ViewContext;

export default PesquisaRapida;
