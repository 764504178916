//import { useEffect } from 'react';
import { loadModules } from 'esri-loader';

const EsriBaseMap = async (view, cont) => {

    const [BasemapGallery] = await loadModules([
        'esri/widgets/BasemapGallery'
    ]);
    
    const basemap = new BasemapGallery({
        view: view,
        container: cont
    });
    
    return basemap;

 
}

export default EsriBaseMap;