import React from 'react';
import { loadModules } from 'esri-loader';
import { Button, ButtonGroup, InputGroup, Alert } from "react-bootstrap";
import { MdClose, MdZoomIn, MdMenu } from 'react-icons/md';
//import { FaQuestion } from 'react-icons/fa'
import { BsArrowsMove } from 'react-icons/bs';
import ScaleLoader from "react-spinners/ScaleLoader";
//import Moment from 'react-moment';
//import CurrencyFormat from 'react-currency-format';
import getListLayers from './popupservices';
import Config from '../../config';
// import debug from 'debug';

import './style.css';

class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lfeatures: [],
      ufeatures: [],
      errorUnidades: false,
      page: null,
      openmenu: false
    };

    this.modal = null;
    this.modalBody = null;
    this.modalMenu = null;
    this.modalUnidades = null;
    this.moveWindow = null;
    this.centerLon = null;
    this.centerLat = null;
    this.popupFeatures = [];
    this.popupTitle = "Nenhuma feição foi encontrada";

    //Configuracoes
    this.config = Config.configs()

  }

  async componentDidMount() {
    //console.log(this.props)
    try {
      this.modal = document.querySelector(".modalwindow");
      this.modalBody = document.getElementById("modalBody");
      this.modalMenu = document.getElementById("modalMenu");
      this.modalUnidades = document.getElementById("modalUnidades");
      this.moveWindow = document.getElementById("moveWindow");

      //Actions to opne the popup
      this.props.view.graphics = null;
      this.modal.style.display = 'none';
      this.modalMenu.style.display = "none";
      this.modalUnidades.style.display = "none";

      this.centerLon = this.props.mappoint.mapPoint.longitude;
      this.centerLat = this.props.mappoint.mapPoint.latitude;
      this.popupElement(this.modal, this.props.mappoint.x, this.props.mappoint.y);
      this.showPoint(this.props.mappoint.mapPoint);

      if (this.props.mappoint.geocode) {
        this.openCustomPage(this.props.mappoint.geocode);
      } else {
        this.loadFeatures(this.props.mappoint.mapPoint);
      }

    } catch (e) {
      console.log("Não foi possivel carregar o popup " + e)
    }
  }

  popupElement = (el, cx, cy) => {
    //console.log(el)

    if (!el.classList.contains("anim")) {
      el.classList.add("anim");
    }

    this.modalBody.style.display = "block";

    el.style.display = 'block';
    //el.style.width = '320px';
    //el.style.height = '350px';

    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

    el.style.top = cy + "px";
    el.style.left = cx + "px";

    //This action avoid that Window Screen overflow
    let view = document.getElementById("root")
    let viewWidth = view.clientWidth;
    let viewHeight = view.clientHeight;

    //console.log(el.offsetTop)
    if (el.offsetTop > (viewHeight - el.clientHeight)) {
      //bottom
      el.style.top = (viewHeight - el.clientHeight - 3) + "px";
    }

    if (el.offsetTop < 0) {
      //top
      el.style.top = "2px";
    }

    //console.log(el.offsetLeft)
    if (el.offsetLeft > (viewWidth - el.clientWidth)) {
      //right
      el.style.left = (viewWidth - el.clientWidth - 3) + "px";
    }

    if (el.offsetLeft < 0) {
      //left
      el.style.left = "2px";
    }

    /*document.onclick = function(event){
      if(event.target.classList.value === "modaltitle"){
        console.log(event.target)
        event.target.onmousedown = dragMouseDown;
      }
    }*/

    if (this.moveWindow) {
      this.moveWindow.onmousedown = dragMouseDown;
    } else {
      el.onmousedown = dragMouseDown
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closepopupElement;
      document.onmousemove = elementDrag;
    }
    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      el.style.top = (el.offsetTop - pos2) + "px";
      el.style.left = (el.offsetLeft - pos1) + "px";
      el.style.opacity = 0.85

      //Avoid Modal screen overflow
      if (el.offsetTop > (viewHeight - el.clientHeight)) {
        //bottom
        el.style.top = (viewHeight - el.clientHeight - 3) + "px";
      }

      if (el.offsetTop < 0) {
        //top
        el.style.top = "2px";
      }

      if (el.offsetLeft > (viewWidth - el.clientWidth)) {
        //right
        el.style.left = (viewWidth - el.clientWidth - 3) + "px";
      }

      if (el.offsetLeft < 0) {
        //left
        el.style.left = "2px";
      }

    }
    function closepopupElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      el.style.opacity = 1
    }
  }

  showPoint = async (mapPoint) => {
    this.props.view.graphics = null;

    const [Graphic,] = await loadModules([
      "esri/Graphic",
    ]);

    var point = {
      type: "point",
      hasZ: true,
      hasM: true,
      x: mapPoint.x,
      y: mapPoint.y,
      spatialReference: { wkid: mapPoint.spatialReference.wkid }
    };

    var pointGraphic = new Graphic({
      geometry: point,
      symbol: {
        type: "simple-marker",
        size: 8,
        color: "#c00812",
        outline: {
          color: "rgb(180, 8, 10)",
          width: 5
        }
      }
    });

    this.props.view.graphics.remove(pointGraphic);
    this.props.view.graphics.add(pointGraphic);

  }

  loadFeatures = (mapPoint) => {
    const getAllLayesAndSublayersActived = this.props.view.map.layers
      .flatten((item) => item.layers || item.sublayers)
      .filter((item) => item.visible && item.parent.visible)
      .map(({ title, visible }) => ({ title, visible }))

    if (!getAllLayesAndSublayersActived.some(item => item.visible)) {
      this.setState({
        loading: false
      });

      let randonNumber2 = Math.random() * (10000 - 100) + 100;
      this.setState({
        page: <section className="anim" key={randonNumber2}>
          <Alert variant='danger'>Não possui nenhuma camada ativa.</Alert>
        </section>
      });
      return;
    }

    getListLayers()
      .then((layers) => {
        const layerActived = layers.filter((layer) => getAllLayesAndSublayersActived.some((layerApp) => layer.title === layerApp.title))
        return layerActived
      })
      .then((layers) => {
        const xmin = Number(mapPoint.x) - 20
        const xmin1 = Number(mapPoint.x) - 1
        const ymin = Number(mapPoint.y) - 20
        const ymin1 = Number(mapPoint.y) - 1
        const xmax = Number(mapPoint.x)
        const ymax = Number(mapPoint.y)
        const geometryPoint = mapPoint.x + "," + mapPoint.y;
        const geometryEnvelope = xmin + "," + ymin + "," + xmax + "," + ymax;
        const geometryEnvelope1 = xmin1 + "," + ymin1 + "," + xmax + "," + ymax;

        const layersFormatted = layers
          .map((layer) => {
            const coords = layer.geometry === "esriGeometryPoint" ? geometryPoint : geometryEnvelope;

            const body = {
              inSR: mapPoint.spatialReference.wkid,
              outSR: mapPoint.spatialReference.wkid,
              geometryType: layer.title === "Mapeamento Móvel 360" ? geometryEnvelope1 : layer.geometry,
              geometry: coords,
              url: layer.url + "/query",
              title: layer.title,
              email: localStorage.getItem('email')
            };

            const settings = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
            };
            const url = `${this.config.appurl}lotes/mostrar/camada`;

            return {
              url,
              settings,
              order: layer.order,
              title: layer.title,
            };
          })
          .sort((layer1, layer2) => {
            if (layer1.order > layer2.order) {
              return 1;
            } else if (layer1.order === layer2.order) {
              return -1
            } else {
              return 0
            }
          })

        this.setState({ lfeatures: layersFormatted });
        const firstLayer = layersFormatted[0];
        this.openPages(firstLayer)
      })
  }

  loadUnidades = async (Codi_Imov) => {
    try {
      this.modalMenu.style.display = "none";
      this.modalBody.style.display = "none";
      this.modalUnidades.style.display = "block";
      //console.log(Codi_Imov)

      // 10175504 / 14704889
      const dataForm = {
        uemail: localStorage.getItem('email'),
        usalt: localStorage.getItem('salt'),
        Codi_Imov: Codi_Imov //"450310079"
      }

      const unidades = await fetch(this.config.appurl + 'lotes/listar/unidades', {
        method: 'POST',
        body: JSON.stringify(dataForm),
        headers: {
          "Content-Type": "application/json"
        }
      })

      const sgeo = await unidades.json();
      console.log("unidades ", sgeo)

      if (sgeo.error) {
        this.setState({
          ufeatures: [],
          loading: false,
          errorUnidades: true
        });
        console.log("Erro nenhum resultado encontrado ");
        return;
      }

      //Se for todos - o resultado será um array

      if (sgeo.rows.length > 0) {
        //montar array de resultados com o componente - armazenar itens da lista no estado
        this.setState({
          ufeatures: sgeo.rows || []
        });

      } else {
        this.setState({
          ufeatures: []
        });
        console.log("Erro nenhum resultado encontrado")
      }
    } catch (e) {
      this.setState({
        ufeatures: []
      });
      console.log("Erro nenhum resultado encontrado " + e)
    }

  }

  openUnidade = async (Codi_Imov) => {
    this.modalMenu.style.display = "none";
    this.modalBody.style.display = "block";
    this.modalUnidades.style.display = "none";

    this.setState({ page: null });

    const dataForm = {
      uemail: localStorage.getItem('email'),
      usalt: localStorage.getItem('salt'),
      Codi_Imov //"450310079"
    }

    const unidades = await fetch(this.config.appurl + 'lotes/mostrar/imovel', {
      method: 'POST',
      body: JSON.stringify(dataForm),
      headers: {
        "Content-Type": "application/json"
      }
    })

    const sgeo = await unidades.json();
    console.log('unidade ', sgeo)

    if (sgeo.error) {
      console.log("Ocorreu um erro.")
      return;
    }

    if (sgeo.rows.length === 0) {
      console.log("Não foi localizado nenhuma unidade");
      return;
    }

    this.setState({
      page: <section className="anim">
        <div id="modalUnidadesTitle" className="modaltitle">
          <strong>Unidade: {Number(Codi_Imov).toFixed(0)}</strong>
          &nbsp; &nbsp; &nbsp;
          <a className="card-link" href='#unidades' onClick={() => this.voltarUnidades()}>Voltar</a>
        </div>
        {sgeo.rows.length > 0 && sgeo.rows[0].map(({ alias, field, value }) => (
          <div className='tableFlexRow' key={field}>
            <div className='tableFlexColumn tableFlexColumn_bg1'>
              <strong>{alias}</strong>
            </div>
            <div className='tableFlexColumn tableFlexColumn_bg2'>
              <span>{value}</span>
            </div>
          </div>
        ))}
      </section>
    });
  }

  voltarUnidades = () => {
    this.modalMenu.style.display = "none";
    this.modalBody.style.display = "none";
    this.modalUnidades.style.display = "block";
  };
  openImagem = (url) =>{
    window.open(url, '_blank');
  }
  openPages = (feature) => {
    try {
      this.modalMenu.style.display = "none";
      this.modalBody.style.display = "block";

      this.setState({
        openmenu: false
      });
      this.setState({
        loading: true
      });

      fetch(feature.url, feature.settings)
        .then((response) => response.json())
        .then(([result]) => {

          if (!result) {
            this.setState({
              loading: false
            });
            this.setState({
              page: <section className="anim" key={feature.title}>
                <div className="modaltitle">
                  <strong>{feature.title}</strong>
                </div>

              </section>
            });
            return;
          }
          let CODI_IMOV = '';
          const trs = result.map((field) => {
            if (field.field === "codi_imov") {
              CODI_IMOV = field.value
            }
            if (feature.title === "Mapeamento Móvel 360") {
              return (
                <div className='tableFlexRow' key={'tableFlexRow_' + field.field}>
                  <div className='tableFlexColumn tableFlexColumn_bg1'>
                    <strong>{field.alias}</strong>
                  </div>
                  <div className='tableFlexColumn tableFlexColumn_bg2'>
                    <span>{field.alias === "fid"
                      ? (field.value)
                      : (<img
                        style={{ width: "100%", heigth: "auto", cursor: 'pointer' }}
                        src={`http://162.214.66.188:3355/public/${field.value}`}
                        alt="imagem 360"
                        onClick={() => this.openImagem(`http://162.214.66.188:3355/public/${field.value}`)}
                      />)}</span>
                  </div>
                </div>
              )
            }
            return (
              <div className='tableFlexRow' key={'tableFlexRow_' + field.field}>
                <div className='tableFlexColumn tableFlexColumn_bg1'>
                  <strong>{field.alias}</strong>
                </div>
                <div className='tableFlexColumn tableFlexColumn_bg2'>
                  <span>{field.value}</span>
                </div>
              </div>
            )
          });

          if (feature.title === "Lotes") {
            trs.push(
              <div className='tableFlexRow' key={'tableFlexRow_unidades'}>
                <div className='tableFlexColumn tableFlexColumn_bg1'>
                  <span><a className="btn btn-secondary btn-sm btn-block" href="#unidades" onClick={() => this.loadUnidades(CODI_IMOV)} >Abrir Unidades</a></span>
                </div>
              </div>
            )
          }

          this.setState({
            loading: false
          });
          this.setState({
            page: <section className="anim" key={feature.title}>
              <div className="modaltitle">
                <strong>{feature.title}</strong>
              </div>
              {trs.map((tr) => {
                return tr;
              })}
            </section>
          });
        });
    } catch (e) {
      console.log(e)
    }
  };

  openMenu = (action) => {
    this.popupTitle = "Menu"
    if (action) {
      this.modalMenu.style.display = "block";
      this.modalBody.style.display = "none";
    } else {
      this.modalMenu.style.display = "none";
      this.modalBody.style.display = "block";
    }
    this.setState({
      openmenu: action
    });
  };

  zoomFeature = () => {
    this.props.view.goTo({ center: [this.centerLon, this.centerLat], zoom: 18 }, { duration: 500 });
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {

    const backLink = this.state.lfeatures?.filter((lfeature) => lfeature.title === "Lotes")[0]

    return (
      <>
        <section className="modalwindow anim">

          <div id="modalHeader">
            <ButtonGroup size="sm" as={InputGroup.Prepend}>
              <Button id="moveWindow" variant="danger" title="Mover Janela" ><BsArrowsMove size={16} color="#fafafa" /> </Button>
              <Button variant="danger" title="Aplicar Zoom" onClick={() => this.zoomFeature()} ><MdZoomIn size={16} color="#fafafa" /> </Button>
              <Button variant="danger" title="Menu" onClick={() => this.openMenu(!this.state.openmenu)} ><MdMenu size={16} color="#fafafa" /> </Button>
              <Button variant="danger" title="Fechar" onClick={this.props.popupOpened} ><MdClose size={16} color="#fafafa" /> </Button>
            </ButtonGroup>
          </div>

          <div id="modalBody">
            {this.state.page && !this.state.loading
              ? this.state.page : (<section className='nullSearch anim' key="loadSearch0">
                <ScaleLoader size={20} color={"#c00812"} />
              </section>)}
          </div>

          <ul className="listtabs" id="modalMenu">
            <li className="modaltitle">
              <strong>Menu</strong>
            </li>
            {this.state.lfeatures.length > 0 ? (this.state.lfeatures
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((lfeature, i) => {
                return (
                  <li key={i}>
                    <a href='#pmenu' onClick={() => this.openPages(lfeature)}>
                      {lfeature.title}
                    </a>
                  </li>
                )
              })) : (<li className='nullSearch anim' key="nullSearch3">
                <ScaleLoader size={20} color={"#c00812"} />
              </li>
            )}
          </ul>

          <ul className="listtabs" id="modalUnidades">
            <li className="modaltitle">
              <strong>Unidades</strong>
              &nbsp; &nbsp; &nbsp; <a href='#pmenu' className="backLink" onClick={() => this.openPages(backLink)}>Voltar</a>
            </li>
            {this.state.ufeatures.length > 0 ? (this.state.ufeatures.map((unidade, index) => {
              const Codi_Imov = unidade.find(({ field }) => field === 'Codi_Imov').value;
              const Apsa_Imov = unidade.find(({ field }) => field === 'Apsa_Imov').value;

              return (<li key={index}>
                <a href='#modalUnidadesTitle' onClick={() => this.openUnidade(Codi_Imov)}>
                  {index + 1} &nbsp; &nbsp;
                  {"Cód Imóvel : " + Number(Codi_Imov).toFixed(0)} &nbsp; &nbsp;
                  {Apsa_Imov ? "Nº: " + Apsa_Imov : "Nº: (***)"}
                </a>
              </li>);
            })) :  this.state.errorUnidades ?  ( <li className='nullSearch anim' key="nullSearch4">
            Não foram encontrados dados ou você não tem permissão.
          </li>
            ) : (
              <li className='nullSearch anim' key="nullSearch3">
              <ScaleLoader size={20} color={"#c00812"} />
            </li>
             
            )}
          </ul>

        </section>

      </>
    )
  }
}

export default Popup;