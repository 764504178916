import React from 'react';
import { Redirect  } from "react-router-dom";
import EsriMap from '../../components/EsriMap';
import PageLoading from '../../components/PageLoading';
import './style.css';
import Config from '../../config';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      logged: "loading",
      acls:[]
    };

    //Configuracoes
    this.config = Config.configs()

  }

  componentDidMount() {

    fetch(this.config.appurl+"geoportal/logged", {
      method: 'POST',
      body: JSON.stringify({email:localStorage.getItem('email'), salt:localStorage.getItem('salt')}),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(
      //loading
      this.setState(
        {
          logged: "loading"
        }
      )
    )
    .then(
      (response) => {
        if(response.error){
          this.setState(
            {
              logged: "no"
            }
          )
          //limpar a sessão
          localStorage.clear();
        }else{
          this.setState(
            {
              logged: "yes",
              acls: response.usuario.acls
            }
          )
        }
      }
    )

  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  render() {

    let content;

    if(this.state.logged === "yes"){
      content = <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
        <EsriMap acls={this.state.acls} />
      </div>;
    }else if(this.state.logged === "loading"){
      content = <PageLoading />;
    }else{
      content = <Redirect push to="/geoitbi/"/>;
    }

    return (
      <>
        {content}
      </>
    );
    

  }
}

export default Dashboard;
