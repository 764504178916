class EsriSearchServices{

  //Método para gerar o content dos popups
  getFeatures = async (url) => {
    const template =  {
      title: "",
      content: ""
    }
    
    let data = await fetch(url, {
        method: 'GET'
    })

    let response = await data.json()
    //console.log(response)

    template.title = response.name

    //se tiver campos adicionar no Array
    if(response.fields){
      let value = "";
      response.fields.map(function(campo){

        // if(campo.name === "info_adc"){
        //   value = "<div class='tableFlexRow'>"+
        //     "<div class='tableFlexColumn tableFlexColumn_bg1'>"+
        //       "<strong>Galeria (Mais Info)</strong>"+ 
        //     "</div>"+
        //     "<div class='tableFlexColumn tableFlexColumn_bg2'>"+
        //       "<a class='btn btn-sm btn-primary text-white' href='http://gpq.tecgeobr.com.br/plantaquadra/{dsqf}' target='_blank'>Abrir Galeria</a>"+
        //     "</div>"+
        //   "</div>";
        // }else 
        if(campo.name === "objectid"|| campo.name === "id" || campo.name === "st_area(shape)" || campo.name === "st_length(shape)" || campo.name === "shape"){
          value = ''
        }
        // else{
        //   value = "<div class='tableFlexRow'>"+
        //     "<div class='tableFlexColumn tableFlexColumn_bg1'>"+
        //       "<strong>" + campo.alias+ "</strong>"+ 
        //     "</div>"+
        //     "<div class='tableFlexColumn tableFlexColumn_bg2'>"+
        //       "<span>{"+campo.name+"}</span>"+
        //     "</div>"+
        //   "</div>";
        // }

        template.content += value;
        
        
        return true;
      })
    }

    return template;
  }

  services = async () => {

    const urls = {
      "layer1": {
          "url": "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/4",
          "title": "Lotes"
      },
      "layer2": {
        "url": "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/8",
        "title": "Setores"
      },
      "layer3": {
        "url": "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/7",
        "title": "Quadras"
      },
      "layer4": {
        "url": "https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/3",
        "title": "Logradouros"
      },
    }

    return urls;
  
  }
}

const esri_searchs = new EsriSearchServices();
export default esri_searchs;