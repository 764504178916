import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Button, ButtonGroup, ListGroup } from 'react-bootstrap';
import ScaleLoader from "react-spinners/ScaleLoader";
import { loadModules } from "esri-loader";
import './style.css';
import Config from '../../config';

const ZONAS = {
  sul: [
      "Água Fria",
      "Anatólia",
      "Bairro das Indústrias",
      "Bancários",
      "Barra de Gramame",
      "Cidade dos Colibris",
      "Costa do Sol",
      "Costa e Silva",
      "Cristo Redentor",
      "Cuiá",
      "Distrito Industrial",
      "Ernesto Geisel",
      "Ernâni Sátiro",
      "Funcionários II",
      "Funcionários III",
      "Gramame",
      "Grotão",
      "Jardim Cidade Universitária",
      "Jardim São Paulo",
      "José Américo",
      "João Paulo II",
      "Mangabeira",
      "Mumbaba",
      "Mussuré",
      "Muçumagro",
      "Paratibe",
      "Planalto da Boa Esperança",
      "Valentina Figueiredo"
  ],
  leste: [
      "Aeroclube",
      "Altiplano",
      "Bessa",
      "Jardim Brisamar",
      "Cabo Branco",
      "Castelo Branco",
      "Jardim Luna",
      "Jardim Oceania",
      "João Agripino",
      "Manaíra",
      "Miramar",
      "Penha",
      "Ponta dos Seixas",
      "Portal do Sol",
      "Quadramares[4]",
      "São José",
      "Tambaú",
      "Tambauzinho"
  ],
  norte: [
      "Alto do Céu",
      "Bairro dos Estados",
      "Bairro dos Ipês",
      "Centro",
      "Expedicionários",
      "Jardim 13 de Maio",
      "Jardim Esther",
      "Jardim Mangueira",
      "Mandacaru",
      "Padre Zé",
      "Pedro Gondim",
      "Róger",
      "Tambiá",
      "Torre",
      "Varadouro"
  ],
  oeste: [
      "Alto do Mateus",
      "Bairro dos Novais",
      "Cruz das Armas",
      "Ilha do Bispo",
      "Jaguaribe",
      "Jardim Planalto",
      "Jardim Veneza",
      "Mata do Buraquinho",
      "Oitizeiro",
      "Rangel",
      "Trincheiras",
      "Varjão"
  ]
}
export default class AvaliacaodeImovelPorIa extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaging: false,
      logradouro: '',
      infor: false,
      latitude: 0,
      longitude: 0,
      zona: '',
      area: 0,
      tipo: '',
      quarto: 0,
      banheiro: 0,
      vaga: 0,
      piscina: false,
      elevador: false,
      salao_de_festa: false,
      academia: false,
      playground: false,
      quadra_de_esporte: false,
      portaria_24_horas: false,
      varanda_gourmet: false,
      sauna: false,
      spa: false,
      area_servico: false,
      espaco_gourmet: false,

      searchGeocode: [],
      valueMetro: 0,
      price: 0,
      option: 'avaliar', // avalia, result
      selectPoint: false,
      resultActive: false,
    };

    this.click = null;
    this.pointGraphic = null;
    
    this.config = Config.configs()
  }

  componentDidMount() {
    const map = this.props.view.map;

    const getLayerAndSublayers = map.layers.flatten(function (item) {
        return item.layers || item.sublayers;
    })
    const layerAmostraDePesquisa = getLayerAndSublayers.find(function (layer) {
        return layer.title === "RASPAGEM IMOVEIS - jp previsoes pontos";
    });
    layerAmostraDePesquisa.visible = true;

    this.click = this.props.view.on('click', event => {
      if (event.mapPoint) {
        (async () => {
          const geocode = {
            location: event.mapPoint
          }
          await this.showGeocoder(geocode);
          console.log(`${geocode.location.longitude},${geocode.location.latitude}`,
          geocode.location.spatialReference.wkid,
          geocode.location.spatialReference.wkid)
          await this.getZona(
            `${geocode.location.longitude},${geocode.location.latitude}`,
            4326,
            4326
          );
        })();
      }
    });
  }

  getZona = async (geometry, inSR, outSR) => {
    const formSearchLayer = new FormData();
    formSearchLayer.append('f', 'json');
    formSearchLayer.append('where', "1=1");
    formSearchLayer.append('inSR', inSR);
    formSearchLayer.append('outSR', outSR);
    formSearchLayer.append('geometryType', 'esriGeometryPoint'); //esriGeometryEnvelope (Se for linhas e pontos), esriGeometryPoint (Se for poligonos)
    formSearchLayer.append('spatialRel', 'esriSpatialRelIntersects');
    formSearchLayer.append('geometry', geometry);
    formSearchLayer.append('outFields', "*");
    formSearchLayer.append('returnGeometry', 'true');

    const responseArcgis = await fetch('https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/1/query', {
      method: 'POST',
      body: formSearchLayer
    }).then((response) => response.json());

    if (responseArcgis.features.length === 0) {
      return;
    }

    const bairroLayer = responseArcgis.features[0].attributes.name.toLowerCase();
    console.log({step: 0, data: bairroLayer})
    const zona = Object.entries(ZONAS)
      .map(([zona, bairros]) => ([zona, bairros.some((bairro) => bairroLayer === bairro.toLowerCase())]))
      .filter(([zona, isActived]) => isActived);
    console.log({step: 1, data: zona})

    if (zona.length == 0) {
      return;
    }
    console.log({ zona: zona[0][0] });
    this.setState({ zona: zona[0][0] });
    

  };

  showGeocoder = async (geocode) => {
    //console.log(this.props.view)
    try {
      this.props.view.graphics = null;

      const [Graphic] = await loadModules(["esri/Graphic"]);

      this.props.view.when((vi) => {
        if (this.pointGraphic) {
          vi.graphics.remove(this.pointGraphic);
        }
        var point = {
          type: "point",
          hasZ: true,
          hasM: true,
          x: geocode.location.x,
          y: geocode.location.y,
          spatialReference: { wkid: geocode.location.spatialReference.wkid },
        };

        this.setState({ latitude: geocode.location.latitude, longitude: geocode.location.longitude, selectPoint: true })

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: {
            type: "simple-marker",
            size: 12,
            color: "#c00812",
            outline: {
              color: "rgba(45, 106, 79, 0.5)",
              width: 5,
            },
          },
        });

        // vi.graphics.remove(pointGraphic);
        vi.graphics.add(pointGraphic);
        this.pointGraphic = vi.graphics[vi.graphics.length -1];

        //Zoom nos resultados
        vi.goTo({ target: geocode.extent }, { duration: 500 });
      });
    } catch (e) {
      console.log("Geocode não encontrado");
    }
  };

  getGeocoder = async () => {
    //Geocoder
    const geocoders = [];
    const [Locator] = await loadModules(["esri/tasks/Locator"]);

    const locatorTask = new Locator({
      url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
    });
    const params = {
      address: {
        SingleLine: this.state.logradouro,
        //   .split(",")
        //   .map((i) => i.trim())
        //   .reverse()
        //   .join(", "),
        countryCode: "BRL",
        maxLocations: 6,
      },
    };

    // Execute a reverse geocode using the clicked location
    locatorTask
      .addressToLocations(params)
      .then((response) => {
        response.forEach((feature) => {
          geocoders.push({
            id: (Math.random()*2).toString(12),//crypto.randomUUID().toString(),
            address: feature.address,
            extent: feature.extent,
            location: feature.location,
          });
        });

        this.setState({
          //searchGeocode: [...this.state.searchGeocode, geocoders]
          searchGeocode: geocoders,
        });
      })
  };

  handleKeyUp = (event) => {
    event.persist();  
    if (typeof event.keyCode === "undefined") {
      return;
    }

    if (event.target.value.length === 0) {
      return;
    }
    this.setState({ loading: true, infor: true, selectPoint: false });
    ; (async () => {
      await this.getGeocoder();
      this.setState({ loading: false });
    })();
  };

  getLatitudeAndLongitude = (datageocoder) => {
    this.showGeocoder(datageocoder);
    this.setState({ infor: false  });
    console.log(datageocoder)
    this.getZona(
      `${datageocoder.location.longitude},${datageocoder.location.latitude}`,
      datageocoder.location.spatialReference.wkid,
      datageocoder.location.spatialReference.wkid
    );
  };
  

  handleCalcule = (event) => {
    event.preventDefault();
    const latitude = this.state.latitude;
    const longitude = this.state.longitude;
    var area = this.state.area;
    const tipo = this.state.tipo;
    const banheiro = this.state.banheiro;
    const quarto = this.state.quarto;
    const vaga = this.state.vaga;
    const piscina = this.state.piscina ? 1 : 0;
    const elevador = this.state.elevador ? 1 : 0;
    const salao_de_festa = this.state.salao_de_festa ? 1 : 0;
    const academia = this.state.academia ? 1 : 0;
    const playground = this.state.playground ? 1 : 0;
    const quadra_de_esporte = this.state.quadra_de_esporte ? 1 : 0;
    const portaria_24_horas = this.state.portaria_24_horas ? 1 : 0;
    const espaco_gourmet = this.state.espaco_gourmet ? 1 : 0;
    const varanda_gourmet = this.state.varanda_gourmet ? 1 : 0;
    const sauna = this.state.sauna ? 1 : 0;
    const spa = this.state.spa ? 1 : 0;
    const area_servico = this.state.area_servico ? 1 : 0;
    area = parseInt(area)

    const formBody = {
      latitude,
      longitude,
      tipo,
      area,
      quarto,
      vaga,
      banheiro,
      piscina,
      elevador,
      salao_de_festa,
      academia,
      playground ,
      quadra_de_esporte,
      portaria_24_horas,
      varanda_gourmet,
      sauna,
      spa,
      area_servico,
      espaco_gourmet
    }
    console.log(formBody)
    //fetch('http://162.214.66.188:8000/v0.0.1/joao-pessoa-pb/predict/v2.1.0', {
    fetch(`${this.config.appurl}predict`, {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formBody)
    })
      .then((response) => response.json())
      .then((response) => {
       
        this.setState({ valueMetro: response, price: Number(response) * Number(this.state.area), option: 'result' })
      })
      .catch((error) => { console.error(error); });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

  }
  handleChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });

  }

  componentWillUnmount() {
    if (this.click !== null) {
      this.click.remove();
    }

    if (!this.props.view) {
      return;
    }
    const { map } = this.props.view;

    const getLayerAndSublayers = map.layers.flatten(function (item) {
        return item.layers || item.sublayers;
    })
    const layerAmostraDePesquisa = getLayerAndSublayers.find(function (layer) {
        return layer.title === "RASPAGEM IMOVEIS - jp previsoes pontos";
    });
    layerAmostraDePesquisa.visible = false;
  }
   handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, infor: true, selectPoint: false });
    ; (async () => {
      await this.getGeocoder();
      this.setState({ loading: false });
    })();
  }
  limparDados = () => {
    this.setState({
      latitude: "",
      longitude: "",

      area: 0,
      tipo: "",
      banheiro: 0,
      quarto: 0,
      vaga: 0,
      piscina: false,
      elevador: false,
      salao_de_festa: false,

      academia: false,
      playground: false,
      quadra_de_esporte: false,
      logradouro: "",
      infor: false,
      selectPoint: false,
      portaria_24_horas: false,
      varanda_gourmet: false,
      sauna: false,
      spa: false,
      valueMetro: 0,
      price: 0,
      option: 'avaliar',
      searchGeocode: [],
    })
    
  };
  



  render() {
    const geocode =
      this.state.searchGeocode.map(({ id, address, extent, location }) => {
        const datageocoder = { address, extent, location };
        const addressShort =
          address//.length > 10 ? `${address.substring(0, 22)}...` : address;
        return (
          <ListGroup.Item
            key={id}
            action
            href={`#pesquisa-${id}`}
            onClick={() => this.getLatitudeAndLongitude(datageocoder)}
          >
            {addressShort}
          </ListGroup.Item>
        );
      }) || [];

    return (<div name="avaliacaodeImovelPorIA">

      <ButtonGroup size="sm" className="buttons-header">
          <Button
            variant="secondary"
            onClick={() => this.setState({option: 'avaliar'})}
            >Avaliar</Button>
          <Button
            variant="danger"
            onClick={() => this.setState({option: 'result'})}
            disabled={(this.state.valueMetro===0)}
          >Resultado</Button>
      </ButtonGroup>

      <div style={{display: this.state.option === 'avaliar'? 'block': 'none', paddingBottom: '2.4rem'}}>
        <form onSubmit={this.handleSubmit} className="search-logradouro">
          <input
            type="text"
            className="search-logradouro__input"
            placeholder="Localizar o logradouro"
            value={this.state.logradouro}
            onChange={(event) => this.setState({ logradouro: event.target.value })}
            onKeyUp={this.handleKeyUp}
          />
          <button
            variant="danger"
            type="submit"
            className="search-logradouro__button"
          >
            <FaSearch size={16} color="#fafafa" />
            {" "}
          </button>
        </form>
        {(geocode.length > 0 || this.state.loading) && this.state.selectPoint === false && (
          <ListGroup
            defaultActiveKey="#"
            name="resultsPesquisa"
          // horizontal="md"
          >
            {geocode.length > 0 && geocode}
            {this.state.loading && geocode.length === 0 && (<ListGroup.Item className="loadingSearch" disabled>
              <ScaleLoader size={20} color={"#c00812"} />
            </ListGroup.Item>)}
          </ListGroup>
        )}
        {this.state.infor && (<div className="display">
          <p>Não localizou o endereço? Procure no mapa ao lado e click para pegar as coordenadas.</p>
        </div>)}
        {this.state.selectPoint && (<div className="display">
          <div className="">
            <label><strong>Longitude:</strong>{' '}{this.state.longitude}</label>
          </div>
          <div className="">
            <label><strong>latitude</strong>{' '}{this.state.latitude}</label>
          </div>
        </div>)}
        
        <form className="form-avaliacao-mercado" onSubmit={this.handleCalcule}>
          
          <div className="form-avaliacao-mercado__group-field">
            <label htmlFor="tipo">Tipo do Imóvel</label>
            <select
              name="tipo"
              id="tipo"
              value={this.state.tipo}
              onChange={this.handleChange}
            >
              <option disabled hidden value=''></option>
              <option value="casas">Casas</option>
              <option value="apartamentos">Apartamentos</option>

            </select>
          </div>
          <div className="form-avaliacao-mercado__group-field">
            <label htmlFor="area">Área (m²)</label>
            <input 
              type="number"
              name="area"
              id="area"
              value={this.state.area}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-avaliacao-mercado__group-field">
            <label htmlFor="quarto">Quantidade de Quartos</label>
            <input 
              type="number"
              name="quarto"
              id="quarto"
              value={this.state.quarto}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-avaliacao-mercado__group-field">
            <label htmlFor="vaga">Quantidade de Vagas</label>
            <input
              type="number"
              name="vaga"
              id="vaga"
              value={this.state.vaga}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-avaliacao-mercado__group-field">
            <label htmlFor="banheiro">Quantidade de Banheiro</label>
            <input
              type="number"
              name="banheiro"
              id="banheiro"
              value={this.state.banheiro}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-avaliacao-mercado__group-field">
            <label style={{"--font-size": "1.2rem"}}>Selecione o que possuí:</label>
            <div>
              <input 
                type="checkbox"
                name="piscina"
                id="piscina"
                checked={this.state.piscina}
                value={this.state.piscina}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="piscina">Piscina</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="elevador"
                id="elevador"
                checked={this.state.elevador}
                value={this.state.elevador}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="elevador">Elevador</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="salao_de_festa"
                id="salao_de_festa"
                checked={this.state.salao_de_festa}
                value={this.state.salao_de_festa}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="salao_de_festa">Salao de Festa</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="academia" 
                id="academia" 
                checked={this.state.academia}
                value={this.state.academia}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="academia">Academia</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="playground"
                id="playground"
                checked={this.state.playground}
                value={this.state.playground}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="playground">Playground</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="quadra_de_esporte"
                id="quadra_de_esporte"
                checked={this.state.quadra_de_esporte}
                value={this.state.quadra_de_esporte}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="quadra_de_esporte">Quadra de Esporte</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="portaria_24_horas"
                id="portaria_24_horas"
                checked={this.state.portaria_24_horas}
                value={this.state.portaria_24_horas}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="portaria_24_horas">Portaria 24 Horas</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="varanda_gourmet"
                id="varanda_gourmet"
                checked={this.state.varanda_gourmet}
                value={this.state.varanda_gourmet}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="varanda_gourmet">Varanda Gourmet</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="sauna"
                id="sauna"
                checked={this.state.sauna}
                value={this.state.sauna}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="sauna">Sauna</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="spa"
                id="spa"
                checked={this.state.spa}
                value={this.state.spa}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="spa">Spa</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="spa"
                id="spa"
                checked={this.state.spa}
                value={this.state.spa}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="salao_de_festa">Spa</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="area_servico"
                id="area_servico"
                checked={this.state.area_servico}
                value={this.state.area_servico}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="area_servico">Área de serviço</label>
            </div>
            <div>
              <input 
                type="checkbox"
                name="espaco_gourmet"
                id="espaco_gourmet"
                checked={this.state.espaco_gourmet}
                value={this.state.espaco_gourmet}
                onChange={this.handleChangeCheckbox}
              /> {' '}
              <label htmlFor="espaco_gourmet">Espaço Gourmet</label>
            </div>
          </div>
          <button type="submit" className="form-avaliacao-mercado__button">Consultar</button>
          <button onClick={(event) => {event.preventDefault(); this.limparDados()}}  className="form-limpar__button">Limpar</button>
        </form>
        
      </div>
      <div style={{display: this.state.option === 'result'? 'block': 'none'}}>
        <div className="display">
          <div className="price">
            <label><strong>Valor do metro quadrado:</strong>{' '} {this.state.valueMetro.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
          </div>
          <div className="price">
            <label><strong>Valor do imóvel:</strong>{' '} {this.state.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
          </div>
        </div>
      </div>
    </div>)
  }
}