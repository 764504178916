import React from 'react';
import {InputGroup, FormControl, Form, Table, Button, ButtonGroup, Tabs, Tab, Modal, Alert, Container, Row, Col,/* Badge*/}  from "react-bootstrap";
import './style.css';

import {MdSearch, /*MdInfoOutline,*/ MdPrint, MdSend} from 'react-icons/md';
import {FaMapMarkerAlt, FaTrashAlt} from "react-icons/fa";
import {BiBuildingHouse} from "react-icons/bi"
import ScaleLoader from "react-spinners/ScaleLoader";
import CurrencyFormat from 'react-currency-format';
import ReactHtmlParser from 'react-html-parser';
import Config from '../../config';
import { loadModules } from 'esri-loader';


class AvaliacaoImovel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading:"no",
            setValidated:false,
            setValidated2:false,
            inscricao: '',
            openmodal:false,
            alertCalc:false,
            alertCalcContent:null,
            alertOutrasTipologias:false,
            outraTipologia:'CASA',
            relatorio_resumido:'',
            relatorio_detalhado:'',
            btnRelatorios:true,

            alertType:'primary',
            alertMessageStatus:false,
            alertMessage:'',
            valorMedio:null,
            valorMedioEvoTerreno:null,
            valorMedioEvoEdificacao:null,
            btnZoomGeocode:'',
        };


        //Configuracoes
        this.config = Config.configs()
    }


    buscaInscricao = async (event,action) => {

        event.preventDefault();
        //console.log(event.target)

        if (!event.target.checkValidity()) {
            // form is invalid! so we show error messages
            this.setState({ setValidated: true });
            return;
        }

        // form is valid! We can parse and submit data
        this.setState({ setValidated: false });

        let urlForm;
        let dataForm;
        if(action === "sequencial"){
            dataForm = {
                sequencial:this.state.sequencial,
                uemail:localStorage.getItem('email'),
                usalt:localStorage.getItem('salt')
            };
    
            urlForm = this.config.appurl+"admin/avaliacoes/filtrar";
        }else{
            dataForm = {
                sequencial:this.state.sequencial,
                tipologia:this.state.outraTipologia,
                uemail:localStorage.getItem('email'),
                usalt:localStorage.getItem('salt')
            };
    
            urlForm = this.config.appurl+"admin/avaliacoes/outratipologia";
        }

        console.log(urlForm)
        console.log(dataForm)

        
        fetch(urlForm, {
          method: 'POST',
          body: JSON.stringify(dataForm),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json())
        .then(
          //loading
          this.setState({loading: "yes"})
        )
        .then(
          async (response) => {
            if(response.error){
            
                //Exibe as mensagens
                this.setState(
                    {
                        loading: "no",
                        alertCalcContent:ReactHtmlParser("<h5 style='color:red'>"+response.message+"</h5>"),
                        btnDisabled:false,
                    }
                );
                this.openAlertCalc(true)
            }else{

                console.log(response)
                this.clearFilters()

                if(response.outrastipologias){
                    //Exibe as mensagens
                    this.setState(
                       {
                         loading: "no",
                         btnDisabled:false,
                       }
                   );
                   this.openOutrasTipologias(true)

               }else{
                    if(response.imovel.length > 0 ){

                        if(!response.valormedio.valor && !response.valormedioevo.valorEdificacao && !response.valormedioevo.valorTerreno){
                            //Exibe as mensagens
                            this.setState(
                                {
                                    loading: "no",
                                    alertCalcContent:ReactHtmlParser("<h6 style='color:red'>O imóvel/terreno pesquisado está fora dos retângulos envolventes<br/> ou não possui variáveis suficientes para realizar o cálculo nos modelos Comparativo e Evolutivo.</h6><hr/>"),
                                    btnDisabled:false,
                                
                                }
                            );
                            this.openAlertCalc(true)
                        }else{
                        
                            let msgs = "";

                            if(response.valormedio.valor){
                            
                                msgs += "<h6 style='color:green'>O Modelo Comparativo foi calculado com sucesso!</h6><hr/>";
                                this.setState(
                                    {
                                        valorMedio:response.valormedio.valor ? response.valormedio.valor : null,
                                    }
                                );
                            
                            }else{
                                msgs += "<h6 style='color:red'>Não foram encontradas variáveis suficientes para calcular com o modelo Comparativo.</h6><hr/>";
                                this.setState(
                                    {
                                        valorMedio:null,
                                    }
                                );
                            }
        

                            if(!response.valormedioevo.valorEdificacao){
                                msgs += "<h6 style='color:purple'>Não foi possível retornar o valor da Edificação por falta de variáveis.</h6><hr/>";
                            }else{
                                msgs += "<h6 style='color:green'>O Modelo Evolutivo para edificação foi calculado com sucesso!</h6><hr/>";
                            }
                            if(!response.valormedioevo.valorTerreno){
                                msgs += "<h6 style='color:purple'>Não foi possível retornar o valor do Terreno por falta de variáveis.</h6><hr/>";
                            }else{
                                msgs += "<h6 style='color:green'>O Modelo Evolutivo para o Terreno foi calculado com sucesso!</h6><hr/>";
                            }
                            this.setState(
                                {
                                    valorMedioEvoTerreno:response.valormedioevo.valorTerreno ? response.valormedioevo.valorTerreno : null,
                                    valorMedioEvoEdificacao:response.valormedioevo.valorEdificacao ? response.valormedioevo.valorEdificacao : null,
                                }
                            );

                            //Exibe as mensagens
                            this.setState(
                                {
                                    loading: "no",
                                    alertCalcContent:ReactHtmlParser(msgs),
                                    btnDisabled:false,
                                }
                            );
                            this.openAlertCalc(true)
                            
                            
                                
                        }

                        this.showImovel(response.imovel);
                        this.populateTables(response)


                    }else{
                    
                        //Exibe as mensagens
                        this.setState(
                            {
                                loading: "no",
                                alertCalcContent:ReactHtmlParser("<h5 style='color:red'>Não foram encontrados resultados</h5>"),
                                btnDisabled:false,
                            }
                        );
                        this.openAlertCalc(true)
                    }
               }
                
            }
          },
          (error) => {
            //Exibe as mensagens
            this.setState(
                {
                    loading: "no",
                    alertCalcContent:ReactHtmlParser("<h5 style='color:red'>Não foram encontrados resultados</h5><br/><small style='color:purple'>("+error+")</small>"),
                    btnDisabled:false,
                }
            );
            this.openAlertCalc(true)
           
          }
        )
    }


    terceiraAvaliacao = async (event) =>{
        
        event.preventDefault();


        if (!event.target.checkValidity()) {
            // form is invalid! so we show error messages
            this.setState({ setValidated2: true });
            return;
        }

        // form is valid! We can parse and submit data
        this.setState({ setValidated2: false });

        const dataForm = {
            sequencial:this.state.SEQUENCIAL,
            tipologia:this.state.TIPOLOGIA ? this.state.TIPOLOGIA : this.state.TIPOLOGIA_EVO,
            vmi_comparativo:this.state.valorMedio ? this.state.valorMedio.toFixed(2) : 0,
            vmi_evolutivo:this.state.valorMedioEvoEdificacao ? this.state.valorMedioEvoEdificacao.toFixed(2) : 0,
            vmt_evolutivo:this.state.valorMedioEvoTerreno ? this.state.valorMedioEvoTerreno.toFixed(2) : 0,
            terceira_avaliacao:this.state.terceira_avaliacao,
            uemail:localStorage.getItem('email'),
            usalt:localStorage.getItem('salt')
        };

        //console.log(dataForm)
    
        fetch(this.config.appurl+"admin/avaliacoes/salvar", {
          method: 'POST',
          body: JSON.stringify(dataForm),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json())
        .then(
          //loading
          this.setState({loading: "yes"})
        )
        .then(
            async (response) => {
                if(response.error){
                
                    //Exibe as mensagens
                    this.setState(
                        {
                            loading: "no",
                            alertCalcContent:ReactHtmlParser("<h5 style='color:red'>"+response.message+"</h5>"),
                            btnDisabled:false,
                        }
                    );
                    this.openAlertCalc(true)
                }else{
                    //Exibe as mensagens
                    this.setState(
                        {
                            loading: "no",
                            alertCalcContent:ReactHtmlParser("<h5 style='color:green'>"+response.message+"</h5>"),
                            btnDisabled:false,
                            terceira_avaliacao: '0,00'
                        }
                    );
                    this.openAlertCalc(true)
                }
            },
            (error) => {
                //Exibe as mensagens
                this.setState(
                    {
                        loading: "no",
                        alertCalcContent:ReactHtmlParser("<h5 style='color:red'>Não foi possível salvar a avaliação</h5><br/><small style='color:purple'>("+error+")</small>"),
                        btnDisabled:false,
                    }
                );
                this.openAlertCalc(true)
            
            }
          )
        
    }

    showImovel = async(dados) => {

        //console.log(dados)
        
        if(dados.length > 0){

            this.setState({btnRelatorios:false})
            let where = " geocoding = '"+dados[0].de_geocodigo+"' ";

            /**
             * Pegar todos os resultados da pesquisa e pintar os lotes
             * Zoom no resultado da pesquisa
             * Pintar o lote onde o Imóvel está
             */
            
            /** ESRI */
            const [FeatureLayer, Query, Graphic] = await loadModules([
                'esri/layers/FeatureLayer',
                "esri/tasks/support/Query",
                "esri/Graphic"
            ], { css: true });

            //Query and Highligth
            this.props.view.graphics = null;
            this.props.view.when(function (vi) {

                const lotes = new FeatureLayer({
                    url: "http://10.85.2.91/jaboatao/rest/services/GEO_CIDADE/Referencia_Cadastral_GeoCidade_/MapServer/20",
                })

                lotes.load();

                //Query por Geocoding - Dentro da View.When
                var fillSymbol = {
                    type: "simple-fill", // autocasts as new SimpleFillSymbol()
                    color: [102, 153, 255, 0.7],
                    outline: {
                        color: [51, 119, 255],
                        width: 2
                    }
                };

                const query = new Query();
                query.where = where;
                query.outSpatialReference = vi.spatialReference;
                query.returnGeometry = true;
                query.returnQueryGeometry  = true;
                query.outFields = ["*"];

                lotes.queryFeatures(query).then(function(results){

                    results.features.map( async function(feature) {

                        //Create a polygon geometry
                        var polygon = {
                            type: "polygon", // autocasts as new Polygon()
                            hasZ: true,
                            hasM: true,
                            rings: feature.geometry.rings[0],
                            spatialReference: { wkid: feature.geometry.spatialReference.wkid }
                        };

                        var polygonGraphic = new Graphic({
                            geometry: polygon,
                            symbol: fillSymbol
                        });

                        vi.graphics.remove(polygonGraphic);
                        vi.graphics.add(polygonGraphic);
                        
                        /*//verificar o tipo de imóvel e se está em seu respectivo retangulo envolvente
                        const retangulo_envolvente = new FeatureLayer({
                            url: "http://10.85.2.91/jaboatao/rest/services/GEOITBI_PMJG_HOMOLOGACAO/calculo_modelo_tipologico/MapServer",
                        })

                        retangulo_envolvente.load();
                        

                        //Buscar Setor Censitário por Geometria
                        
                        const setor_censitario = new FeatureLayer({
                            url: "http://10.85.2.91/jaboatao/rest/services/GEOITBI_PMJG_HOMOLOGACAO/setor_censitario/MapServer",
                        })

                        setor_censitario.load();

                        //Segunda etapa query no Retangulo envolvente
                        const queryRetangulo = new Query();
                        queryRetangulo.where = "";
                        queryRetangulo.geometry = polygon;
                        queryRetangulo.outFields = ["*"];

                        //console.log(polygonGraphic)
                        
                        let insideRet = [];
                        let finalResult = await setor_censitario.queryFeatures(queryRetangulo);
                        for(let i = 0; i < finalResult.features.length; i++){
                            insideRet.push(finalResult.features[i].attributes)
                        }

                        console.log(finalResult)*/


                    });
                   
                });

                //Zoom nos resultados
                lotes.queryExtent(query).then(function(results){
                    vi.goTo({target:results.extent},{ duration: 1000 });
                });

                return true;

            });

        }
        
    }

    populateTables = (attr) =>{
        //console.log(attr)

        if(attr){
            if(attr.valormedio.variaveis){
                let variaveis = attr.valormedio.variaveis;

                let Renda = variaveis.RENDA ? <CurrencyFormat value={variaveis.RENDA} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                : "*Variável Indefinida";

                //RESET CORES
                this.setState({
                    COORD_N_MODEL : "",
                    COORD_E_MODEL : "",
                    AREA_UNID_MODEL : "",
                    QTD_PAV_MODEL : "",
                    ANDAR_MODEL : "",
                    PADRAO_MODEL : "",
                    IDADE_MODEL : "",
                    TIPO_VIA_MODEL : "",
                    RENDA_MODEL : "",
                    GS_VIA_MODEL : "",
                    ESTRUTURA_MODEL : "",
                    AREA_TER_MODEL : "",
                    FRENTE_TER_MODEL : "",
                    AREA_CONST_MODEL : "",
                    PAVIMENTA_VIA_MODEL : "",
                    USO_IMOVEL_MODEL: "",
                })

                this.setState({
                    MODELO: variaveis.MODELO,
                    SEQUENCIAL: variaveis.SEQUENCIAL ? Number(variaveis.SEQUENCIAL).toFixed(0) : "*Variável Indefinida",
                    TIPOLOGIA: variaveis.TIPOLOGIA ? variaveis.TIPOLOGIA : "*Variável Indefinida",
                    LOGRADOURO: variaveis.LOGRADOURO ? variaveis.LOGRADOURO : "*Variável Indefinida",
                    COMPLEMENTO: variaveis.COMPLEMENTO ? variaveis.COMPLEMENTO : "*Variável Indefinida",
                    ANDAR: variaveis.ANDAR ? variaveis.ANDAR : "*Variável Indefinida",
                    QTD_PAV: variaveis.QTD_PAV ? variaveis.QTD_PAV : "*Variável Indefinida",
                    TIPO_VIA: variaveis.TIPO_VIA ? variaveis.TIPO_VIA : "*Variável Indefinida",
                    TIPO_VIA_TEXTO: variaveis.TIPO_VIA_TEXTO ? variaveis.TIPO_VIA_TEXTO : "*Variável Indefinida",
                    COORD_E: variaveis.COORD_E ? Number(variaveis.COORD_E).toFixed(2) : "*Variável Indefinida",
                    COORD_N: variaveis.COORD_N ? Number(variaveis.COORD_N).toFixed(2) : "*Variável Indefinida",
                    IDADE: variaveis.IDADE ? variaveis.IDADE : "*Variável Indefinida",
                    AREA_CONST: variaveis.AREA_CONST ? variaveis.AREA_CONST : "*Variável Indefinida",
                    AREA_TER: variaveis.AREA_TER ? variaveis.AREA_TER : "*Variável Indefinida",
                    AREA_TOTAL_CONST: variaveis.AREA_TOTAL_CONST ? variaveis.AREA_TOTAL_CONST : "*Variável Indefinida",
                    AREA_UNID: variaveis.AREA_UNID ? variaveis.AREA_UNID : "*Variável Indefinida",
                    FRENTE_TER: variaveis.FRENTE_TER ? variaveis.FRENTE_TER : "*Variável Indefinida",
                    PAVIMENTA_VIA: variaveis.PAVIMENTA_VIA ? variaveis.PAVIMENTA_VIA : "*Variável Indefinida",
                    PAVIMENTA_VIA_TEXTO: variaveis.PAVIMENTA_VIA_TEXTO ? variaveis.PAVIMENTA_VIA_TEXTO : "*Variável Indefinida",
                    USO_IMOVEL: variaveis.USO_IMOVEL ? variaveis.USO_IMOVEL : "*Variável Indefinida",
                    USO_IMOVEL_TEXTO: variaveis.USO_IMOVEL_TEXTO ? variaveis.USO_IMOVEL_TEXTO : "*Variável Indefinida",
                    ESTRUTURA: variaveis.ESTRUTURA ? variaveis.ESTRUTURA : "*Variável Indefinida",
                    ESTRUTURA_TEXTO: variaveis.ESTRUTURA_TEXTO ? variaveis.ESTRUTURA_TEXTO : "*Variável Indefinida",
                    GS_VIA: variaveis.GS_VIA ? variaveis.GS_VIA : "*Variável Indefinida",
                    GS_VIA_TEXTO: variaveis.GS_VIA_TEXTO ? variaveis.GS_VIA_TEXTO : "*Variável Indefinida",
                    NATUREZA: variaveis.NATUREZA ? variaveis.NATUREZA : "*Variável Indefinida",
                    PADRAO: variaveis.PADRAO ? variaveis.PADRAO : "*Variável Indefinida",
                    PADRAO_TEXTO: variaveis.PADRAO_TEXTO ? variaveis.PADRAO_TEXTO : "*Variável Indefinida",
                    RENDA: Renda,
                    btnZoomGeocode:variaveis.GEOCODE
                })

               

                /**Definir as cores das variáveis que são essenciais no modelo */
                if(variaveis.MODELO === "CalcAptAcima4"){
                    this.setState({
                        COORD_N_MODEL : "headTableBlue",
                        COORD_E_MODEL : "headTableBlue",
                        AREA_UNID_MODEL : "headTableBlue",
                        QTD_PAV_MODEL : "headTableBlue",
                        ANDAR_MODEL : "headTableBlue",
                        PADRAO_MODEL : "headTableBlue",
                        IDADE_MODEL : "headTableBlue",
                        TIPO_VIA_MODEL : "headTableBlue",
                        RENDA_MODEL : "headTableBlue"
                    })
                }

                if(variaveis.MODELO === "CalcAptAte4"){
                    this.setState({
                        COORD_E_MODEL : "headTableBlue",
                        AREA_UNID_MODEL : "headTableBlue",
                        QTD_PAV_MODEL : "headTableBlue",
                        ANDAR_MODEL : "headTableBlue",
                        PADRAO_MODEL : "headTableBlue",
                        IDADE_MODEL : "headTableBlue",
                        GS_VIA_MODEL : "headTableBlue",
                        RENDA_MODEL : "headTableBlue",
                        ESTRUTURA_MODEL : "headTableBlue"
                    })
                }

                if(variaveis.MODELO === "CalcCasa"){
                    this.setState({
                        COORD_N_MODEL : "headTableBlue",
                        COORD_E_MODEL : "headTableBlue",
                        AREA_TER_MODEL : "headTableBlue",
                        FRENTE_TER_MODEL : "headTableBlue",
                        AREA_CONST_MODEL : "headTableBlue",
                        PADRAO_MODEL : "headTableBlue",
                        IDADE_MODEL : "headTableBlue",
                        PAVIMENTA_VIA_MODEL : "headTableBlue",
                        USO_IMOVEL_MODEL: "headTableBlue",
                        RENDA_MODEL : "headTableBlue"
                    })
                }

               
            }
            if(attr.valormedioevo.variaveis){
                let variaveis_evo = attr.valormedioevo.variaveis;

                let RendaEvo = variaveis_evo.RENDA ? <CurrencyFormat value={variaveis_evo.RENDA} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                : "*Variável Indefinida";

                let CubEvo = variaveis_evo.CUB ? <CurrencyFormat value={variaveis_evo.CUB} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                : "*Variável Indefinida";

                this.setState({
                    COORD_N_MODEL_EVO : "",
                    COORD_E_MODEL_EVO : "",
                    AREA_TER_MODEL_EVO : "",
                    FRENTE_TER_MODEL_EVO : "",
                    TIPO_VIA_MODEL_EVO : "",
                    GUIA_SARJETA_MODEL_EVO : "",
                    COEF_APROV_MODEL_EVO : "",
                    ZONA_MODEL_EVO : "",
                    ALT_MAX_CONST_MODEL_EVO: "",
                    RENDA_MODEL_EVO : "",

                    PAVIMENTOS_MODEL_EVO : "",
                    ESTADO_CONSERVACAO_MODEL_EVO : "",
                    TIPOLOGIA_MODEL_EVO : "",
                    PADRAO_MODEL_EVO : "",
                    AREA_CONST_MODEL_EVO : "",
                    IDADE_MODEL_EVO : "",
                    CUB_MODEL_EVO:"",
                    DEPRECIACAO_MODEL_EVO:""
                })

                this.setState({
                    SEQUENCIAL_EVO: variaveis_evo.SEQUENCIAL ? Number(variaveis_evo.SEQUENCIAL).toFixed(0) : "*Variável Indefinida",
                    TIPOLOGIA_EVO: variaveis_evo.TIPOLOGIA ? variaveis_evo.TIPOLOGIA : "*Variável Indefinida",
                    COORD_E_EVO: variaveis_evo.COORD_E ? Number(variaveis_evo.COORD_E).toFixed(2) : "*Variável Indefinida",
                    COORD_N_EVO: variaveis_evo.COORD_N ? Number(variaveis_evo.COORD_N).toFixed(2) : "*Variável Indefinida",
                    LOGRADOURO_EVO: variaveis_evo.LOGRADOURO ? variaveis_evo.LOGRADOURO : "*Variável Indefinida",
                    COMPLEMENTO_EVO: variaveis_evo.COMPLEMENTO ? variaveis_evo.COMPLEMENTO : "*Variável Indefinida",
                    GUIA_SARJETA_TEXTO_EVO: variaveis_evo.GUIA_SARJETA_TEXTO ? variaveis_evo.GUIA_SARJETA_TEXTO : "*Variável Indefinida",
                    GUIA_SARJETA_EVO: variaveis_evo.GUIA_SARJETA ? variaveis_evo.GUIA_SARJETA : "*Variável Indefinida",
                    PAVIMENTOS_EVO: variaveis_evo.PAVIMENTOS ? variaveis_evo.PAVIMENTOS : "*Variável Indefinida",
                    TIPO_VIA_TEXTO_EVO: variaveis_evo.TIPO_VIA_TEXTO ? variaveis_evo.TIPO_VIA_TEXTO : "*Variável Indefinida",
                    TIPO_VIA_EVO: variaveis_evo.TIPO_VIA ? variaveis_evo.TIPO_VIA : "*Variável Indefinida",
                    NATUREZA_EVO: variaveis_evo.NATUREZA ? variaveis_evo.NATUREZA : "*Variável Indefinida",
                    IDADE_EVO: variaveis_evo.IDADE ? variaveis_evo.IDADE : "*Variável Indefinida",
                    USO_IMOVEL_TEXTO_EVO: variaveis_evo.USO_IMOVEL_TEXTO ? variaveis_evo.USO_IMOVEL_TEXTO : "*Variável Indefinida",
                    ESTADO_CONSERVACAO_EVO: variaveis_evo.ESTADO_CONSERVACAO ? variaveis_evo.ESTADO_CONSERVACAO : "*Variável Indefinida",
                    PADRAO_EVO: variaveis_evo.PADRAO ? variaveis_evo.PADRAO: "*Variável Indefinida",
                    AREA_CONST_EVO: variaveis_evo.AREA_CONST ? variaveis_evo.AREA_CONST : "*Variável Indefinida",
                    AREA_TER_EVO: variaveis_evo.AREA_TER ? variaveis_evo.AREA_TER : "*Variável Indefinida",
                    AREA_TOTAL_CONST_EVO: variaveis_evo.AREA_TOTAL_CONST ? variaveis_evo.AREA_TOTAL_CONST : "*Variável Indefinida",
                    FRENTE_TER_EVO: variaveis_evo.FRENTE_TER ? variaveis_evo.FRENTE_TER : "*Variável Indefinida",
                    CUB_EVO: CubEvo,
                    DEPRECIACAO_EVO: variaveis_evo.DEPRECIACAO ? variaveis_evo.DEPRECIACAO : "*Variável Indefinida",
                    ZONA_EVO: variaveis_evo.ZONA ? variaveis_evo.ZONA : "*Variável Indefinida",
                    COEF_APROV_EVO: variaveis_evo.COEF_APROV ? variaveis_evo.COEF_APROV : "*Variável Indefinida",
                    ALT_MAX_CONST_EVO: variaveis_evo.ALT_MAX_CONST || variaveis_evo.ALT_MAX_CONST === 0 ? variaveis_evo.ALT_MAX_CONST : "*Variável Indefinida",
                    RENDA_EVO: RendaEvo,
                    btnZoomGeocode:variaveis_evo.GEOCODE
                })

                /**Definir as cores das variáveis que são essenciais no modelo */
                if(variaveis_evo.MODELO_EVO === "CalcModeloEvolutivo"){
                    this.setState({
                        COORD_N_MODEL_EVO : "headTableBlue",
                        COORD_E_MODEL_EVO : "headTableBlue",
                        AREA_TER_MODEL_EVO : "headTableBlue",
                        FRENTE_TER_MODEL_EVO : "headTableBlue",
                        TIPO_VIA_MODEL_EVO : "headTableBlue",
                        GUIA_SARJETA_MODEL_EVO : "headTableBlue",
                        COEF_APROV_MODEL_EVO : "headTableBlue",
                        ZONA_MODEL_EVO : "headTableBlue",
                        ALT_MAX_CONST_MODEL_EVO: "headTableBlue",
                        RENDA_MODEL_EVO : "headTableBlue",

                        PAVIMENTOS_MODEL_EVO : "headTablePurple",
                        ESTADO_CONSERVACAO_MODEL_EVO : "headTablePurple",
                        TIPOLOGIA_MODEL_EVO : "headTablePurple",
                        PADRAO_MODEL_EVO : "headTablePurple",
                        AREA_CONST_MODEL_EVO : "headTablePurple",
                        IDADE_MODEL_EVO : "headTablePurple",
                        CUB_MODEL_EVO:"headTablePurple",
                        DEPRECIACAO_MODEL_EVO:"headTablePurple"
                    })
                }

                
            }
           

        }
        

    }

    handleZoom = async(geocodigo) =>{
        /**
         * Zoom nos resultados da pesquisa
         * Pintar todos os lotes pesquisados
         * Posicionar extent na pesquisa
         */

        let where = " geocoding = '"+geocodigo+"' ";
        
        /** ESRI */
        const [FeatureLayer, Query, Graphic] = await loadModules([
            'esri/layers/FeatureLayer',
            "esri/tasks/support/Query",
            "esri/Graphic"
        ], { css: true });

        //Query and Highligth
        this.props.view.graphics = null;
        this.props.view.when(function (vi) {
            const lotes = new FeatureLayer({
                url: "http://10.85.2.91/jaboatao/rest/services/GEO_CIDADE/Referencia_Cadastral_GeoCidade_/MapServer/20",
            })

            lotes.load();

            //Query por Geocoding - Dentro da View.When
            var fillSymbol = {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [102, 153, 255, 0.7],
                outline: {
                    color: [51, 119, 255],
                    width: 2
                }
            };

            const query = new Query();
            query.where = where;
            query.outSpatialReference = vi.spatialReference;
            query.returnGeometry = true;
            query.returnQueryGeometry  = true;
            query.outFields = ["*"];

            lotes.queryFeatures(query).then(function(results){

            results.features.map(function(feature) {

                //console.log(JSON.stringify(feature, undefined, 2))
                
                //Create a polygon geometry
                var polygon = {
                    type: "polygon", // autocasts as new Polygon()
                    hasZ: true,
                    hasM: true,
                    rings: feature.geometry.rings[0],
                    spatialReference: { wkid: feature.geometry.spatialReference.wkid }
                };

                var polygonGraphic = new Graphic({
                    geometry: polygon,
                    symbol: fillSymbol
                });

                vi.graphics.remove(polygonGraphic);
                vi.graphics.add(polygonGraphic);
                return true;

             });
            });
    
            //Zoom nos resultados
            lotes.queryExtent(query).then(function(results){
                vi.goTo({target:results.extent},{ duration: 1000 });
            });

        });

    }

    clearFilters = async() =>{
        this.props.view.graphics = null;

        //RESET CORES
        this.setState({
            COORD_N_MODEL : "",
            COORD_E_MODEL : "",
            AREA_UNID_MODEL : "",
            QTD_PAV_MODEL : "",
            ANDAR_MODEL : "",
            PADRAO_MODEL : "",
            IDADE_MODEL : "",
            TIPO_VIA_MODEL : "",
            RENDA_MODEL : "",
            GS_VIA_MODEL : "",
            ESTRUTURA_MODEL : "",
            AREA_TER_MODEL : "",
            FRENTE_TER_MODEL : "",
            AREA_CONST_MODEL : "",
            PAVIMENTA_VIA_MODEL : "",
            USO_IMOVEL_MODEL: "",
        })

        this.setState({
            SEQUENCIAL: "-",
            TIPOLOGIA: "-",
            LOGRADOURO: "-",
            COMPLEMENTO: "-",
            ANDAR: "-",
            QTD_PAV: "-",
            TIPO_VIA: "-",
            TIPO_VIA_TEXTO: "-",
            COORD_E: "-",
            COORD_N: "-",
            IDADE: "-",
            AREA_CONST: "-",
            AREA_TER: "-",
            AREA_TOTAL_CONST: "-",
            AREA_UNID: "-",
            FRENTE_TER: "-",
            PAVIMENTA_VIA: "-",
            PAVIMENTA_VIA_TEXTO: "-",
            USO_IMOVEL: "-",
            USO_IMOVEL_TEXTO: "-",
            ESTRUTURA: "-",
            ESTRUTURA_TEXTO: "-",
            GS_VIA: "-",
            GS_VIA_TEXTO: "-",
            NATUREZA: "-",
            PADRAO: "-",
            PADRAO_TEXTO: "-",
            RENDA: "-",
        })


        this.setState({
            COORD_N_MODEL_EVO : "",
            COORD_E_MODEL_EVO : "",
            AREA_TER_MODEL_EVO : "",
            FRENTE_TER_MODEL_EVO : "",
            TIPO_VIA_MODEL_EVO : "",
            GUIA_SARJETA_MODEL_EVO : "",
            COEF_APROV_MODEL_EVO : "",
            ZONA_MODEL_EVO : "",
            ALT_MAX_CONST_MODEL_EVO: "",
            RENDA_MODEL_EVO : "",

            PAVIMENTOS_MODEL_EVO : "",
            ESTADO_CONSERVACAO_MODEL_EVO : "",
            TIPOLOGIA_MODEL_EVO : "",
            PADRAO_MODEL_EVO : "",
            AREA_CONST_MODEL_EVO : "",
            IDADE_MODEL_EVO : "",
            CUB_MODEL_EVO:"",
            DEPRECIACAO_MODEL_EVO:""
        })

        this.setState({
            SEQUENCIAL_EVO: "-",
            TIPOLOGIA_EVO: "-",
            COORD_E_EVO: "-",
            COORD_N_EVO: "-",
            LOGRADOURO_EVO: "-",
            COMPLEMENTO_EVO: "-",
            GUIA_SARJETA_TEXTO_EVO: "-",
            GUIA_SARJETA_EVO: "-",
            PAVIMENTOS_EVO: "-",
            TIPO_VIA_TEXTO_EVO: "-",
            TIPO_VIA_EVO: "-",
            NATUREZA_EVO: "-",
            IDADE_EVO: "-",
            USO_IMOVEL_TEXTO_EVO: "-",
            ESTADO_CONSERVACAO_EVO: "-",
            PADRAO_EVO: "-",
            AREA_CONST_EVO: "-",
            AREA_TER_EVO: "-",
            AREA_TOTAL_CONST_EVO: "-",
            FRENTE_TER_EVO: "-",
            CUB_EVO: "-",
            DEPRECIACAO_EVO: "-",
            ZONA_EVO: "-",
            COEF_APROV_EVO: "-",
            ALT_MAX_CONST_EVO: "-",
            RENDA_EVO: "-"
            
        })


        this.setState(
            {
                btnRelatorios:true,
                valorMedio:null,
                valorMedioEvoTerreno:null,
                valorMedioEvoEdificacao:null,
            }
        );
    }


    closeAlertMessage = () => {
        this.setState(
          {
            alertMessageStatus: false
          }
        );
    }

    
    handleChange = (event) => {
        //console.log(event.target)
        this.setState({[event.target.name]: event.target.value});
    }

    handleChangeTipologia = (event) => {
        //console.log(event.target)
        this.setState({[event.target.name]: event.target.value});
    }

    openModal = (action) =>{
        this.setState({ openmodal: action })
    }

    openAlertCalc = (action) =>{
        this.setState({ alertCalc: action })
    }

    openOutrasTipologias = (action) =>{
        this.setState({ alertOutrasTipologias: action })
    }

    printAction = (id) =>{
        this.printElement(document.getElementById(id));
        window.print();
    }

    printElement = (elem, append, delimiter) => {
        var domClone = elem.cloneNode(true);
    
        var $printSection = document.getElementById("printSection");
    
        if (!$printSection) {
            $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }
    
        if (append !== true) {
            $printSection.innerHTML = "";
        }
    
        else if (append === true) {
            if (typeof(delimiter) === "string") {
                $printSection.innerHTML += delimiter;
            }
            else if (typeof(delimiter) === "object") {
                $printSection.appendChlid(delimiter);
            }
        }
    
        $printSection.appendChild(domClone);
    }

   

    render() {

        let content;
        let modelo_comparativo;
        let modal;
        let alertcalc;
        let alert_outras_tipologias;
        let dataTableComparativo;
        let dataTableValComparativo;
        let dataTableEvolutivo;
        let dataTableValEvolutivo;

        let valMedioMin;
        let valMedio;
        let valMedioMax;

        let valorEvoTerrenoMin;
        let valorEvoTerreno;
        let valorEvoTerrenoMax;

        let valorEvoEdificacaoMin;
        let valorEvoEdificacao;
        let valorEvoEdificacaoMax;

        let valorEvoTotalMin;
        let valorEvoTotal;
        let valorEvoTotalMax;

        if(this.state.MODELO === "CalcAptAte4"){
            modelo_comparativo = <Alert variant="primary">
                <h6>A seguinte fórmula define o Cálculo de Avaliação para Apartamentos com até 4 Pavimentos pelo modelo Comparativo</h6>
                <h6>
                    <strong>
                        VIAp_até_4 pav. = VU Ap_até_4 pav. x Área Ap_até_4 pav.<br/>
                        VU Ap_até_4 pav.= e LN _(VAL_UNITÁRIO)
                    </strong>
                </h6>
                <hr/>
                <strong>LN _(VAL_UNITÁRIO)</strong>
                 = -10,89553622 + (2,3080459*10-10 * COORD_E²) - (0,083558977 * AREA_UNID½) - 
                 (0,39862664 * 1/QTD_PAV²) + (0,02915418 * 1/ANDAR²) + (0,080647415 * PADRAO) – 
                 (0,10905699 * IDADE½) + (0,11996861 * GS_VIA) + (1,7906921*10-9 * RENDA²) + (0,059503174 * ESTRUTURA)<br/>
                <strong>Retângulo envolvente (E: Min 286224, Máx 289274; N: Máx 9098888, Mín 9090101) Retirar da cartografia (Lotes_quadras_regulares)</strong>
                
            </Alert>
        }

        if(this.state.MODELO === "CalcAptAcima4"){
            modelo_comparativo = <Alert variant="primary">
                <h6>A seguinte fórmula define o Cálculo de Avaliação para Apartamentos com mais de 4 Pavimentos pelo modelo Comparativo</h6>
                <h6>
                    <strong>
                        VIAp_acima_4 pav. = VU Ap_acima_4 pav. x Área Ap_acima_4 pav.<br/>
                        VU Ap_acima_4 pav.= e LN _(VAL_UNITÁRIO)
                    </strong>
                </h6>
                <hr/>
                <strong>LN _(VAL_UNITÁRIO)</strong>
                 = -39,34107041 + (5,0026548*10-13 * COORD_N²) + (6,1584775*10-11 * COORD_E²) + (525,91728 * 1/AREA_UNID²) – 
                 (1,244239 * 1/QTD_PAV²) + (0,00021187047 * ANDAR²) + (0,43042253 * PADRAO½) - (0,13419013 * Ln(IDADE)) + 
                 (0,043626042 * TIPO_VIA) - (186,54071 * 1/RENDA)<br/>
                <strong>Retângulo envolvente (E: Mín 285807, Máx 289068; N: Mín 9090223, Máx 9097657) Retirar da cartografia (Lotes_quadras_regulares)</strong>
                
            </Alert>
        }

        if(this.state.MODELO === "CalcCasa"){
            modelo_comparativo = <Alert variant="primary">
                <h6>A seguinte fórmula define o Cálculo de Avaliação para Casas pelo modelo Comparativo</h6>
                <h6>
                    <strong>
                        VICasa = VALOR_IMOVEL²
                    </strong>
                </h6>
                <hr/>
                <strong>VALOR_IMOVEL²</strong>
                 = -54894,9108 + (3,9598794*1018 * 1/COORD_N²) + (8,7243581*10-8 * COORD_E²) + (0,0002189316 * AREA_TER²) + 
                (0,26395119 * FRENTE_TER²) + (0,68149029 * AREA_CONST) + (7,6478363 * PADRAO²) - (0,12189108 * IDADE²) + 
                (89,840544 * PAVIMENTA_VIA) + (33,510524 * USO_IMOVEL) + (7,228805*10-6 * RENDA²)<br/>
                <strong>Retângulo envolvente (E: Mín 284698, Máx 289495; N: Mí 9088961, Máx 9098532)</strong>
                
            </Alert>
        }
       

       
        if(this.state.SEQUENCIAL){
            dataTableComparativo = <>
            <tr><td>Sequencial</td><td>{this.state.SEQUENCIAL}</td></tr>
            <tr><td>Tipologia</td><td>{this.state.TIPOLOGIA}</td></tr>
            <tr><td>Natureza</td><td>{this.state.NATUREZA}</td></tr>
            <tr><td>Logradouro</td><td>{this.state.LOGRADOURO}</td></tr>
            <tr><td>Complemento</td><td>{this.state.COMPLEMENTO}</td></tr>
            <tr className={this.state.ANDAR_MODEL}><td>Andar</td><td>{this.state.ANDAR}</td></tr>
            <tr className={this.state.QTD_PAV_MODEL}><td>Pavimentos</td><td>{this.state.QTD_PAV}</td></tr>
            <tr className={this.state.TIPO_VIA_MODEL}><td>Tipo de Via</td><td>{this.state.TIPO_VIA_TEXTO}</td></tr>
            <tr className={this.state.TIPO_VIA_MODEL}><td>Tipo de Via (Val)</td><td>{this.state.TIPO_VIA}</td></tr>
            <tr className={this.state.COORD_E_MODEL}><td>Coordenada Este</td><td>{this.state.COORD_E}</td></tr>
            <tr className={this.state.COORD_N_MODEL}><td>Coordenada Norte</td><td>{this.state.COORD_N}</td></tr>
            <tr className={this.state.IDADE_MODEL}><td>Idade (Anos)</td><td>{this.state.IDADE}</td></tr>
            <tr className={this.state.AREA_UNID_MODEL}><td>Área da Unidade (m²)</td><td>{this.state.AREA_UNID}</td></tr>
            <tr className={this.state.AREA_CONST_MODEL}><td>Área Construída (m²)</td><td>{this.state.AREA_CONST}</td></tr>
            <tr className={this.state.AREA_TER_MODEL}><td>Área do Terreno (m²)</td><td>{this.state.AREA_TER}</td></tr>
            <tr className={this.state.FRENTE_TER_MODEL}><td>Testada (m²)</td><td>{this.state.FRENTE_TER}</td></tr>
            <tr><td>Área Total (m²)</td><td>{this.state.AREA_TOTAL_CONST}</td></tr>
            <tr className={this.state.PAVIMENTA_VIA_MODEL}><td>Pavimentação</td><td>{this.state.PAVIMENTA_VIA_TEXTO}</td></tr>
            <tr className={this.state.PAVIMENTA_VIA_MODEL}><td>Pavimentação (Val)</td><td>{this.state.PAVIMENTA_VIA}</td></tr>
            <tr className={this.state.USO_IMOVEL_MODEL}><td>Uso do Imóvel</td><td>{this.state.USO_IMOVEL_TEXTO}</td></tr>
            <tr className={this.state.USO_IMOVEL_MODEL}><td>Uso do Imóvel (Val)</td><td>{this.state.USO_IMOVEL}</td></tr>
            <tr className={this.state.ESTRUTURA_MODEL}><td>Estrutura</td><td>{this.state.ESTRUTURA_TEXTO}</td></tr>
            <tr className={this.state.ESTRUTURA_MODEL}><td>Estrutura (Val)</td><td>{this.state.ESTRUTURA}</td></tr>
            <tr className={this.state.GS_VIA_MODEL}><td>Guias e Sarjetas</td><td>{this.state.GS_VIA_TEXTO}</td></tr>
            <tr className={this.state.GS_VIA_MODEL}><td>Guias e Sarjetas (val)</td><td>{this.state.GS_VIA}</td></tr>
            <tr className={this.state.PADRAO_MODEL}><td>Padrão</td><td>{this.state.PADRAO_TEXTO}</td></tr>
            <tr className={this.state.PADRAO_MODEL}><td>Padrão (Val)</td><td>{this.state.PADRAO}</td></tr>
            <tr className={this.state.RENDA_MODEL}><td>Renda IBGE</td><td>{this.state.RENDA}</td></tr>
            </>;

        }else{
            dataTableComparativo = <>
            <tr><td>Sequencial</td><td>-</td></tr>
            <tr><td>Tipologia</td><td>-</td></tr>
            <tr><td>Natureza</td><td>-</td></tr>
            <tr><td>Logradouro</td><td>-</td></tr>
            <tr><td>Complemento</td><td>-</td></tr>
            <tr><td>Andar</td><td>-</td></tr>
            <tr><td>Tipo de Via</td><td>-</td></tr>
            <tr><td>Tipo de Via (Val)</td><td>-</td></tr>
            <tr><td>Coordenada Este</td><td>-</td></tr>
            <tr><td>Coordenada Norte</td><td>-</td></tr>
            <tr><td>Idade (Anos)</td><td>-</td></tr>
            <tr><td>Área da Unidade (m²)</td><td>-</td></tr>
            <tr><td>Área Construída (m²)</td><td>-</td></tr>
            <tr><td>Área do Terreno (m²)</td><td>-</td></tr>
            <tr><td>Testada (m²)</td><td>-</td></tr>
            <tr><td>Área Total (m²)</td><td>-</td></tr>
            <tr><td>Pavimentação</td><td>-</td></tr>
            <tr><td>Pavimentação (Val)</td><td>-</td></tr>
            <tr><td>Uso do Imóvel</td><td>-</td></tr>
            <tr><td>Uso do Imóvel (Val)</td><td>-</td></tr>
            <tr><td>Estrutura</td><td>-</td></tr>
            <tr><td>Estrutura (Val)</td><td>-</td></tr>
            <tr><td>Guias e Sarjetas</td><td>-</td></tr>
            <tr><td>Guias e Sarjetas (val)</td><td>-</td></tr>
            <tr><td>Padrão</td><td>-</td></tr>
            <tr><td>Padrão (Val)</td><td>-</td></tr>
            <tr><td>Renda IBGE</td><td>-</td></tr>
            </>;

        }

        if(this.state.SEQUENCIAL_EVO){
            dataTableEvolutivo = <>
            <tr><td>Sequencial</td><td>{this.state.SEQUENCIAL_EVO}</td></tr>
            <tr className={this.state.TIPOLOGIA_MODEL_EVO}><td>Tipologia</td><td>{this.state.TIPOLOGIA_EVO}</td></tr>
            <tr><td>Natureza</td><td>{this.state.NATUREZA_EVO}</td></tr>
            <tr><td>Logradouro</td><td>{this.state.LOGRADOURO_EVO}</td></tr>
            <tr><td>Complemento</td><td>{this.state.COMPLEMENTO_EVO}</td></tr>
            <tr><td>Uso do Imóvel</td><td>{this.state.USO_IMOVEL_TEXTO_EVO}</td></tr>
            <tr className={this.state.PAVIMENTOS_MODEL_EVO}><td>Pavimentos</td><td>{this.state.PAVIMENTOS_EVO}</td></tr>
            <tr className={this.state.TIPO_VIA_MODEL_EVO}><td>Tipo de Via</td><td>{this.state.TIPO_VIA_TEXTO_EVO}</td></tr>
            <tr className={this.state.TIPO_VIA_MODEL_EVO}><td>Tipo de Via (Val)</td><td>{this.state.TIPO_VIA_EVO}</td></tr>
            <tr className={this.state.COORD_E_MODEL_EVO}><td>Coordenada Este</td><td>{this.state.COORD_E_EVO}</td></tr>
            <tr className={this.state.COORD_N_MODEL_EVO}><td>Coordenada Norte</td><td>{this.state.COORD_N_EVO}</td></tr>
            <tr className={this.state.IDADE_MODEL_EVO}><td>Idade (anos)</td><td>{this.state.IDADE_EVO}</td></tr>
            <tr className={this.state.AREA_CONST_MODEL_EVO}><td>Área Construída (m²)</td><td>{this.state.AREA_CONST_EVO}</td></tr>
            <tr className={this.state.AREA_TER_MODEL_EVO}><td>Área do Terreno (m²)</td><td>{this.state.AREA_TER_EVO}</td></tr>
            <tr className={this.state.FRENTE_TER_MODEL_EVO}><td>Testada (m²)</td><td>{this.state.FRENTE_TER_EVO}</td></tr>
           
            <tr className={this.state.GUIA_SARJETA_MODEL_EVO}><td>Guias e Sarjetas</td><td>{this.state.GUIA_SARJETA_TEXTO_EVO}</td></tr>
            <tr className={this.state.GUIA_SARJETA_MODEL_EVO}><td>Guias e Sarjetas (val)</td><td>{this.state.GUIA_SARJETA_EVO}</td></tr>
            <tr className={this.state.ESTADO_CONSERVACAO_MODEL_EVO}><td>Conservação</td><td>{this.state.ESTADO_CONSERVACAO_EVO}</td></tr>
            <tr className={this.state.PADRAO_MODEL_EVO}><td>Padrão</td><td>{this.state.PADRAO_EVO}</td></tr>
            <tr className={this.state.CUB_MODEL_EVO}><td>CUB</td><td>{this.state.CUB_EVO}</td></tr>
            <tr className={this.state.DEPRECIACAO_MODEL_EVO}><td>DEPRECIAÇÃO</td><td>{this.state.DEPRECIACAO_EVO}</td></tr>
            <tr className={this.state.ZONA_MODEL_EVO}><td>Zona</td><td>{this.state.ZONA_EVO}</td></tr>
            <tr className={this.state.COEF_APROV_MODEL_EVO}><td>Coef. de Aproveitamento (Zona)</td><td>{this.state.COEF_APROV_EVO}</td></tr>
            <tr className={this.state.ALT_MAX_CONST_MODEL_EVO}><td>Altura Máxima Permitida</td><td>{this.state.ALT_MAX_CONST_EVO}</td></tr>
            <tr className={this.state.RENDA_MODEL_EVO}><td>Renda IBGE</td><td>{this.state.RENDA_EVO}</td></tr>
            </>;

        }else{
            dataTableEvolutivo = <>
            <tr><td>Sequencial</td><td>-</td></tr>
            <tr><td>Tipologia</td><td>-</td></tr>
            <tr><td>Natureza</td><td>-</td></tr>
            <tr><td>Logradouro</td><td>-</td></tr>
            <tr><td>Complemento</td><td>-</td></tr>
            <tr><td>Pavimentos</td><td>-</td></tr>
            <tr><td>Tipo de Via</td><td>-</td></tr>
            <tr><td>Tipo de Via (Val)</td><td>-</td></tr>
            <tr><td>Coordenada Este</td><td>-</td></tr>
            <tr><td>Coordenada Norte</td><td>-</td></tr>
            <tr><td>Idade (Anos)</td><td>-</td></tr>
            <tr><td>Área Construída (m²)</td><td>-</td></tr>
            <tr><td>Área do Terreno (m²)</td><td>-</td></tr>
            <tr><td>Testada (m²)</td><td>-</td></tr>
            <tr><td>Área Total (m²)</td><td>-</td></tr>
            <tr><td>Uso do Imóvel</td><td>-</td></tr>
            <tr><td>Guias e Sarjetas</td><td>-</td></tr>
            <tr><td>Guias e Sarjetas (val)</td><td>-</td></tr>
            <tr><td>Conservação</td><td>-</td></tr>
            <tr><td>Padrão</td><td>-</td></tr>
            <tr><td>CUB</td><td>-</td></tr>
            <tr><td>DEPRECIAÇÃO</td><td>-</td></tr>
            <tr><td>Zona</td><td>-</td></tr>
            <tr><td>Coef. de Aproveitamento (Zona)</td><td>-</td></tr>
            <tr><td>Altura Máxima Permitida</td><td>-</td></tr>
            <tr><td>Renda IBGE</td><td>-</td></tr>
            </>;

        }

        if(this.state.valorMedio){
            
            valMedioMin = this.state.valorMedio ? (this.state.valorMedio-(this.state.valorMedio*0.15)).toFixed(2) : 0;
            valMedio = this.state.valorMedio ? this.state.valorMedio.toFixed(2) : 0;
            valMedioMax = this.state.valorMedio ? (this.state.valorMedio+(this.state.valorMedio*0.15)).toFixed(2) : 0;

            dataTableValComparativo = <tr><td>Imóvel</td><td className="headTableYellow">
                <CurrencyFormat value={valMedioMin} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableGreen">
                <CurrencyFormat value={valMedio} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableRed">
                <CurrencyFormat value={valMedioMax} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td></tr>;
         
        }else{
            dataTableValComparativo = <tr><td>Imóvel</td><td className="headTableYellow">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableGreen">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableRed">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td></tr>; 
        }

        
        if(this.state.valorMedioEvoTerreno || this.state.valorMedioEvoEdificacao){

            valorEvoTerrenoMin = this.state.valorMedioEvoTerreno ? (this.state.valorMedioEvoTerreno-(this.state.valorMedioEvoTerreno*0.15)).toFixed(2) : 0;
            valorEvoTerreno = this.state.valorMedioEvoTerreno ? this.state.valorMedioEvoTerreno.toFixed(2) : 0;
            valorEvoTerrenoMax = this.state.valorMedioEvoTerreno ? (this.state.valorMedioEvoTerreno+(this.state.valorMedioEvoTerreno*0.15)).toFixed(2) : 0;
        
            valorEvoEdificacaoMin = this.state.valorMedioEvoEdificacao ? (this.state.valorMedioEvoEdificacao-(this.state.valorMedioEvoEdificacao*0.15)).toFixed(2) : 0;
            valorEvoEdificacao = this.state.valorMedioEvoEdificacao ? this.state.valorMedioEvoEdificacao.toFixed(2) : 0;
            valorEvoEdificacaoMax = this.state.valorMedioEvoEdificacao ? (this.state.valorMedioEvoEdificacao+(this.state.valorMedioEvoEdificacao*0.15)).toFixed(2) : 0;

            //console.log(this.state.TIPOLOGIA)
            if(this.state.TIPOLOGIA === "APARTAMENTO" || this.state.TIPOLOGIA_EVO === "APARTAMENTO"){
                valorEvoTotalMin = Number(valorEvoEdificacaoMin);
                valorEvoTotalMax = Number(valorEvoEdificacaoMax);
                valorEvoTotal = Number(valorEvoEdificacao);
            }else{
                valorEvoTotalMin = Number(valorEvoTerrenoMin) + Number(valorEvoEdificacaoMin);
                valorEvoTotalMax = Number(valorEvoTerrenoMax) + Number(valorEvoEdificacaoMax);
                valorEvoTotal = Number(valorEvoTerreno) + Number(valorEvoEdificacao);
            }
           
            dataTableValEvolutivo = <><tr>
                <td>Terreno</td>
                <td className="headTableYellow">
                    <CurrencyFormat value={valorEvoTerrenoMin} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
                <td className="headTableGreen">
                    <CurrencyFormat value={valorEvoTerreno} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
                <td className="headTableRed">
                    <CurrencyFormat value={valorEvoTerrenoMax} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
            </tr>
            <tr>
                <td>Edificação</td>
                <td className="headTableYellow">
                    <CurrencyFormat value={valorEvoEdificacaoMin} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
                <td className="headTableGreen">
                    <CurrencyFormat value={valorEvoEdificacao} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
                <td className="headTableRed">
                    <CurrencyFormat value={valorEvoEdificacaoMax} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
            </tr>
            <tr>
                <td>Total</td>
                <td className="headTableYellow">
                    <CurrencyFormat value={valorEvoTotalMin.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
                <td className="headTableGreen">
                    <CurrencyFormat value={valorEvoTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
                <td className="headTableRed">
                    <CurrencyFormat value={valorEvoTotalMax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                </td>
            </tr></>;
           
        }else{
            dataTableValEvolutivo = <><tr>
            <td>Terreno</td>
            <td className="headTableYellow">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableGreen">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableRed">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            </tr>
            <tr>
            <td>Edificação</td>
            <td className="headTableYellow">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableGreen">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableRed">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            </tr>
            <tr>
            <td>Total</td>
            <td className="headTableYellow">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableGreen">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            <td className="headTableRed">
                <CurrencyFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
            </td>
            </tr></>;
        }
       

        if(this.state.openmodal){
            modal = <Modal
            size="xl"
            show={this.state.openmodal}
            onHide={e => this.openModal(!this.state.openmodal)}
            backdrop="static"
            keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Relatórios</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Tabs defaultActiveKey="comparativo">
                    <Tab eventKey="comparativo" title="Modelo Comparativo">
      
                        <section className="rowSpacing" id="printThis">
                            <Container>
                                <Row style={{backgroundColor:'#f7f7f7', padding:"10px", border:"solid 1px #dedede"}}>
                                    <Col md={7}>
                                        <img 
                                            src="/nova_logo.png" 
                                            alt="Logo PMJG"
                                            width="100"
                                            height="100"
                                            className="d-inline-block align-center"
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <h5>Geoportal de ITBI - Prefeitura Municipal de Jaboatão dos Guararapes</h5>
                                        <h6>Relatório do Cálculo de Avaliação do Imóvel</h6>
                                        <strong>Sequencial: </strong> {this.state.SEQUENCIAL !== "-" ? this.state.SEQUENCIAL : this.state.SEQUENCIAL_EVO}
                                    </Col>
                                </Row>
                            </Container>
                            <hr/>

                            {this.state.alertCalcContent}

                            <h5><strong>Modelo Comparativo</strong></h5>
                            <hr/>
                            {modelo_comparativo}
                            
                            <section className="rowSpacing">
                                <Table striped bordered hover size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Dados para geração do modelo</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tableFont">
                                        {dataTableComparativo}
                                    </tbody>
                                </Table>
                                
                                <strong style={{fontSize:"11px", color:"#1580c7"}}>*Variáveis Essenciais do modelo Comparativo</strong>
                                <hr/>
                            </section>
                            <section className="rowSpacing">
                                <Table hover striped bordered size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan="4">Estimativa de valores</th>
                                        </tr>
                                        <tr>
                                            <td>Valor</td>
                                            <td className="headTableYellow ">Mínimo</td>
                                            <td className="headTableGreen">Médio</td>
                                            <td className="headTableRed">Máximo</td>
                                        </tr>
                                    </thead>
                                    <tbody className="tableFont">
                                        {dataTableValComparativo}
                                    </tbody>
                                </Table>
                                
                            </section>
                        </section>
                     
                    </Tab>
                    <Tab eventKey="evolutivo" title="Modelo Evolutivo">
                   
                        <section className="rowSpacing" id="printThis2">
                            <Container>
                                <Row style={{backgroundColor:'#f7f7f7', padding:"10px", border:"solid 1px #dedede"}}>
                                    <Col md={7}>
                                        <img 
                                            src="/nova_logo.png" 
                                            alt="Logo PMJG"
                                            width="100"
                                            height="100"
                                            className="d-inline-block align-center"
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <h5>Geoportal de ITBI - Prefeitura Municipal de Jaboatão dos Guararapes</h5>
                                        <h6>Relatório do Cálculo de Avaliação do Imóvel</h6>
                                        <strong>Sequencial: </strong> {this.state.SEQUENCIAL !== "-" ? this.state.SEQUENCIAL : this.state.SEQUENCIAL_EVO}
                                    </Col>
                                </Row>
                            </Container>
                            <hr/>

                            {this.state.alertCalcContent}

                            <h5><strong>Modelo Evolutivo</strong></h5>
                            <hr/>

                            <Alert variant="info">
                                <h6>A seguinte fórmula define o Cálculo de Avaliação do Terreno pelo modelo Evolutivo</h6>
                                <h6><strong>Valor do Terreno = VAL_UNIT² * AREA_TER</strong></h6>
                                <hr/>
                                <strong>VAL_UNIT²</strong> = 3322,590732 – (4,0998324*10-11 * COORD_N²) + 
                                (7,7728245*10-10 * COORD_E²) + (600696,01 * 1/AREA_TER²) – (854,1856 * 1/FRENTE_TER²) +
                                (2,2090658 * TIPO_VIA) + (0,92552421 * GUIA_SARJETA) + (0,41045045 * COEF_APROV²) +
                                (261,38924 * 1/ALT_MAX_CONST²) + (0,47211837 * RENDA_IBGE ½) <br/>
                                <strong>Retângulo envolvente (E: Mín 280774, Máx 289250; N: Mín 9089971, Máx 9099577)</strong>
                                <hr/>
                                <h6><strong>A seguinte fórmula define o Cálculo de Avaliação de Edificações (Reedição de Imóveis) pelo modelo Evolutivo</strong></h6>
                                <strong>VR</strong> = 0.20 * CUB * AREA_UNIDADE + 0.80 * CUB * AREA_UNIDADE * DEPRECIACAO

                            </Alert>

                            <section className="rowSpacing">
                                <Table striped bordered hover size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Dados para geração do modelo</th>
                                        </tr>
                                    </thead>
                                    <tbody className="tableFont">
                                        {dataTableEvolutivo}
                                    </tbody>
                                </Table>
                                <strong style={{fontSize:"11px", color:"#1580c7"}}>*Variáveis Essenciais do modelo Evolutivo (Terreno)</strong><br/>
                                <strong style={{fontSize:"11px", color:"#820ea5"}}>*Variáveis Essenciais do modelo Evolutivo (Edificações)</strong>
                                <hr/>
                            </section>
                            <section className="rowSpacing">
                                <Table hover striped bordered size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan="4">Estimativa de valores</th>
                                        </tr>
                                        <tr>
                                            <td>Valor</td>
                                            <td className="headTableYellow ">Mínimo</td>
                                            <td className="headTableGreen">Médio</td>
                                            <td className="headTableRed">Máximo</td>
                                        </tr>
                                    </thead>
                                    <tbody className="tableFont">
                                        {dataTableValEvolutivo}
                                    </tbody>
                                </Table>
                            </section>

                        </section>
                
                    </Tab>
                    
                </Tabs>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.printAction("printThis")}>
                        <MdPrint size={16} color="#fafafa" /> Imprimir/Gerar PDF Modelo Comparativo
                    </Button>
                    <Button variant="info" onClick={() => this.printAction("printThis2")}>
                        <MdPrint size={16} color="#fafafa" /> Imprimir/Gerar PDF Modelo Evolutivo
                    </Button>
                    <Button variant="secondary" onClick={e => this.openModal(!this.state.openmodal)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        }else{
            modal = null
        }

        if(this.state.alertCalc){
            alertcalc = <Modal
            size="lg"
            show={this.state.alertCalc}
            onHide={e => this.openAlertCalc(!this.state.alertCalc)}
            backdrop="static"
            keyboard={true}
            >
                <Modal.Header closeButton>
                <Modal.Title>Atenção:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.alertCalcContent}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={e => this.openAlertCalc(!this.state.alertCalc)}>
                    Fechar
                </Button>
                </Modal.Footer>
            </Modal>
        }else{
            alertcalc = null
        }

        if(this.state.alertOutrasTipologias){
            alert_outras_tipologias = <Modal
            size="lg"
            show={this.state.alertOutrasTipologias}
            onHide={e => this.openOutrasTipologias(!this.state.alertOutrasTipologias)}
            backdrop="static"
            keyboard={true}
            >
                <Modal.Header closeButton>
                <Modal.Title>Calcular outra Tipologia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>O Imóvel buscado possui uma tipologia não precificada nos modelos Comparativo e Evolutivo, o técnico poderá escolher logo a baixo uma tipologia para realizar o cálculo</h5>
                    <hr/>
                    <section className="rowSpacing">
                        <form onSubmit={e => this.buscaInscricao(e,"tipologias")} noValidate className={this.state.setValidated ? 'is-invalid was-validated form-control:invalid' : ''}>

                            <Form.Group>
                                <Form.Label>Tipologia</Form.Label>
                                <Form.Control as="select" size="sm" value={this.state.outraTipologia || 'CASA'} name="outraTipologia" onChange={this.handleChangeTipologia} required>
                                    <option value="APARTAMENTO">APARTAMENTO</option>
                                    <option value="CASA">CASA</option>
                                </Form.Control>
                            </Form.Group>
                            
                            <Button size="sm" type="submit" variant="primary" block><MdSearch size={16} color="#fafafa" />{' '} Buscar</Button>

                        </form>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={e => this.openOutrasTipologias(!this.state.alertOutrasTipologias)}>
                    Fechar
                </Button>
                </Modal.Footer>
            </Modal>
        }else{
            alert_outras_tipologias = null
        }

        
        if(this.state.loading === "no"){
            
            content =  <section id="avaliacaoImovelContainer">

                <section className="rowSpacing">
                    <form onSubmit={e => this.buscaInscricao(e,"sequencial")} noValidate className={this.state.setValidated ? 'is-invalid was-validated form-control:invalid' : ''}>
                        <Alert variant={this.state.alertType} show={this.state.alertMessageStatus} onClose={() => this.closeAlertMessage()} dismissible>
                            {this.state.alertMessage}
                        </Alert>
                        <InputGroup>
                            <FormControl type="number" size="sm" name="sequencial" value={this.state.value} onChange={this.handleChange} placeholder="Sequencial" required/>
                            <InputGroup.Append>
                                <Button size="sm" type="submit" variant="primary"><MdSearch size={16} color="#fafafa" />{' '} Buscar</Button>
                                {/*<Button size="sm" variant="info"><MdInfoOutline size={16} color="#fafafa" /></Button>*/}
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">
                                Por favor insira um sequencial válido (Apenas números)
                            </Form.Control.Feedback>
                        </InputGroup>
                        
                    </form>
                   
                </section>

                <Form.Label><strong>Modelos de Cálculo</strong></Form.Label>
                <Tabs defaultActiveKey="comparativo">
                    <Tab eventKey="comparativo" title="Comparativo">
                        
                        <section className="colAtributos rowSpacing">
                            <Table striped bordered hover size="sm" responsive="sm">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Dados para geração do modelo</th>
                                    </tr>
                                </thead>
                                <tbody className="tableFont">
                                    {dataTableComparativo}                                   
                                </tbody>
                            </Table>
                            <strong style={{fontSize:"11px", color:"#1580c7"}}>*Variáveis Essenciais do modelo Comparativo</strong><br/>

                        </section>
                       
                        <section className="colCalculo rowSpacing">
                            <Table hover striped bordered size="sm" responsive="sm">
                                <thead>
                                    <tr>
                                        <th colSpan="4">Estimativa de valores</th>
                                    </tr>
                                    <tr>
                                        <td>Valor</td>
                                        <td className="headTableYellow ">Mínimo</td>
                                        <td className="headTableGreen">Médio</td>
                                        <td className="headTableRed">Máximo</td>
                                    </tr>
                                </thead>
                                <tbody className="tableFont">
                                    {dataTableValComparativo}
                                </tbody>
                            </Table>
                        </section>

                    </Tab>
                    <Tab eventKey="evolutivo" title="Evolutivo">
                                            
                        <section className="colAtributos rowSpacing">
                            <Table striped bordered hover size="sm" responsive="sm">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Dados para geração do modelo</th>
                                    </tr>
                                </thead>
                                <tbody className="tableFont">
                                    {dataTableEvolutivo}
                                </tbody>
                            </Table>
                            <strong style={{fontSize:"11px", color:"#1580c7"}}>*Variáveis Essenciais do modelo Evolutivo (Terreno)</strong><br/>
                            <strong style={{fontSize:"11px", color:"#820ea5"}}>*Variáveis Essenciais do modelo Evolutivo (Edificações)</strong>
                        </section>

                        <section className="colCalculo rowSpacing">
                                <Table hover striped bordered size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th colSpan="4">Estimativa de valores</th>
                                        </tr>
                                        <tr>
                                            <td>Valor</td>
                                            <td className="headTableYellow ">Mínimo</td>
                                            <td className="headTableGreen">Médio</td>
                                            <td className="headTableRed">Máximo</td>
                                        </tr>
                                    </thead>
                                    <tbody className="tableFont">
                                        {dataTableValEvolutivo}
                                    </tbody>
                                </Table>
                            </section>

                    </Tab>
                </Tabs>
 
                <hr/>
                <ButtonGroup size="sm">
                    <Button disabled={this.state.btnRelatorios} variant="primary" onClick={() => this.handleZoom(this.state.btnZoomGeocode)}><FaMapMarkerAlt size={16} color="#fafafa" /> Ver no mapa</Button>
                    <Button disabled={this.state.btnRelatorios} variant="danger" onClick={() => this.clearFilters()}><FaTrashAlt size={16} color="#fafafa" /> Limpar Consulta</Button>
                    <Button disabled={this.state.btnRelatorios} variant="success" onClick={() => this.openModal(!this.state.openmodal)}><BiBuildingHouse size={16} color="#fafafa" /> Abrir Relatórios</Button>
                </ButtonGroup>

                <hr/>
                <section className="rowSpacing">
                    <form id="form2" onSubmit={this.terceiraAvaliacao} noValidate className={this.state.setValidated2 ? 'is-invalid was-validated form-control:invalid' : ''}>
                        
                        <Form.Label><strong>3ª Avaliação</strong></Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button size="sm" variant="secondary">R$</Button>
                            </InputGroup.Prepend>
                                <FormControl disabled={this.state.btnRelatorios} as={CurrencyFormat} thousandSeparator={true} size="sm" name="terceira_avaliacao" value={this.state.value} onChange={this.handleChange} placeholder="Valor da Avaliação" required />
                            <InputGroup.Append>
                                <Button disabled={this.state.btnRelatorios} size="sm" type="submit" variant="success"><MdSend size={16} color="#fafafa" />{' '} Salvar</Button>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">
                                Por favor insira um valor para a 3ª Avaliação (Apenas números)
                            </Form.Control.Feedback>
                        </InputGroup>
                       
                    </form>
                </section>
               

            </section>

        }else{
            content = <div className="loading_widget_centered" >
                <div className="scaleLoader_widget_panel">
                    <ScaleLoader size={22} color={"#fafafa"} />
                </div>
            </div>;
        }
    
        return (
          <>
            {content}
            {modal}
            {alertcalc}
            {alert_outras_tipologias}
          </>
        );
    
       
    }  
}


export default AvaliacaoImovel;


