//import { useEffect } from 'react';
import { loadModules } from 'esri-loader';


const EsriMeasurement = async (view, cont) => {

    const [Measurement] = await loadModules([
        'esri/widgets/Measurement'
    ]);

    const measurement = new Measurement({
        view: view,
        container: cont,
        
    })
    
    return measurement;

}

export default EsriMeasurement;