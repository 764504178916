import pt from 'date-fns/locale/pt';
import { loadModules } from 'esri-loader';
import React from 'react';
import { Alert, Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, FormControl, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
//import moment from 'moment-timezone';
import { Bar, Pie } from 'react-chartjs-2';
import { CSVLink } from "react-csv";
import CurrencyFormat from 'react-currency-format';
import DataTable from 'react-data-table-component';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-export-excel";
import ReactHtmlParser from 'react-html-parser';
import { FaChartBar, FaChevronLeft, FaDrawPolygon, FaFileCsv, FaMousePointer, FaPlus, FaPlusCircle, FaRegWindowClose, FaSearchLocation, FaTrashAlt } from "react-icons/fa";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import Moment from 'react-moment';
import ClipLoader from "react-spinners/BarLoader";
import WidgetLoading from '../../components/WidgetLoading';
import Config from '../../config';
import './style.css';


class PesquisaMercado extends React.Component {

    constructor(props) {
        super(props);

        let today = new Date();
        let day = today.getDate() >= 9 ? today.getDate() : "0" + today.getDate();
        let month = today.getMonth() >= 9 ? today.getMonth() + 1 : "0" + Number(today.getMonth() + 1);
        let gettoday = day + "/" + month + "/" + today.getFullYear();
        //let gettoday = "10/03/2018";

        registerLocale('pt', pt)

        this.state = {
            logged: "loading",
            tableDataResults: [],
            tableDataFilter: [],
            totalRows: 0,
            isLoaded: true,
            btnDisabled: false,

            formAction: '',
            alertType: 'primary',
            alertMessageStatus: false,
            alertMessage: '',
            setValidated: false,
            todayis: gettoday,
            valor: '',
            datainicial: today,
            datafinal: today,
            doneButtonFP: true,
            resultsFP: [],

            btnCadastroDisabled: false,
            btnMenuCadastroDisabled: true,

            alertCalc: false,
            alertCalcContent: null,

            modalGraficos: false,
            graficoTipologia: [],
            graficoTipoImovel: [],

            sum_areas: 0,
            sum_ponderada: 0,
            ponderada: 0,

            topologyValueSelectBox: [],

            poligonos: [],

            listarAmostras: null,
            selectAmostraText: 'Selecione uma amostra para editar.',
            formAmostraText: 'Clique no mapa para inserir um ponto',
            showFormAmostras: 'none',
            amostra_new_geometry: null,
            amostra_action: 'add',
            amostra_modal_deletar: false,
            amostra_modal_feedback: false,
            amostra_modal_feedback_msg: "",
        };

        this.panelConsultas = React.createRef();
        this.panelFilter = React.createRef();
        this.panelCadastro = React.createRef();

        this.panelCadastroTools = React.createRef();
        this.panelSelectAmostra = React.createRef();
        this.panelFormAmostra = React.createRef();

        this.showConsultas = this.showConsultas.bind(this);
        this.showFilter = this.showFilter.bind(this);
        this.filtroProximidade = this.filtroProximidade.bind(this);

        this.editor = null;
        this.polycoord = [];
        this.sketch = null;
        this.startButtonsPM = React.createRef();
        this.actionButtonsPM = React.createRef();

        this.pesquisaForm = React.createRef();
        this.Form1 = React.createRef();
        this.Form2 = React.createRef();
        this.Form3 = React.createRef();
        this.Form4 = React.createRef();
        this.Form5 = React.createRef();
        this.FormButtons = React.createRef();
        this.btnAddPoint = React.createRef();
        this.btnRemovePoint = React.createRef();
        this.btnApplyPoint = React.createRef();

        this.editFeature = null;
        this.highlight = null;

        this.columnsResults = [
            { name: 'Tipo da Pesquisa', selector: 'f6_tipopesq', sortable: true, wrap: true },
            { name: 'Tipo do Imóvel', selector: 'f2_natureza', sortable: true },
            { name: 'Tipologia', selector: 'f3_tipologi', sortable: true },
            {
                name: 'Data Pesquisa', selector: 'f5_datapesq', sortable: true,
                cell: row => <Moment date={row.f5_datapesq} format="DD/MM/YYYY" />
            },
            {
                name: 'Valor Avaliação', selector: 'f39_preço_', sortable: true,
                cell: row => <CurrencyFormat value={Number(row.f39_preço_).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />,
                style: { backgroundColor: '#cce0ff' }
            },
            {
                name: 'Área Terreno (m²)', selector: 'f16_areater', sortable: true, cell: row => Number(row.f16_areater).toFixed(2),
                style: { backgroundColor: '#e6ffc8' }
            },
            {
                name: 'Área Unidade (m²)', selector: 'f17_areacon', sortable: true, cell: row => Number(row.f17_areacon).toFixed(2),
                style: { backgroundColor: '#e6ffb3' }
            },
            {
                name: 'Valor m²', selector: 'valor_m2', sortable: true, wrap: true,
                cell: row => isNaN(Number(row.f39_preço_ / row.f17_areacon)) ||
                    !isFinite(Number(row.f39_preço_ / row.f17_areacon)) ? 0 :
                    <CurrencyFormat value={Number(row.f39_preço_ / row.f17_areacon).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />,
                style: { backgroundColor: '#e0ccff' }
            },
            {
                name: 'Ponderação', selector: 'ponderacao', sortable: true, wrap: true,
                cell: row => isNaN(Number((row.f39_preço_ / row.f17_areacon) * row.f17_areacon)) ||
                    !isFinite(Number((row.f39_preço_ / row.f17_areacon) * row.f17_areacon)) ? 0 :
                    <CurrencyFormat value={Number((row.f39_preço_ / row.f17_areacon) * row.f17_areacon).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />,
                style: { backgroundColor: '#ffd6cc' }
            }


        ];

        this.columnsFilter = [
            { name: 'Coluna', selector: 'coluna', sortable: false, wrap: true },
            { name: 'Operador', selector: 'operador', sortable: false, wrap: true },
            { name: 'Valor', selector: 'valor', sortable: false, wrap: true },
            {
                name: 'Excluir',
                selector: 'acoes',
                sortable: false,
                right: true,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                cell: row => <Button onClick={() => this.excludeExpression(row.id)} variant='danger' size='sm' style={{ padding: '2px' }}><FaTrashAlt size={12} color="white" /></Button>

            },
        ];

        this.customStylesResults = {
            header: {
                style: {
                    minHeight: '20px',
                    fontSize: '11px'
                },
            },
            rows: {
                style: {
                    fontSize: '12px', // override the row height
                }
            },
        };

        //Configuracoes
        this.config = Config.configs()

    }

    async componentDidMount() {

        this.setState({
            isLoaded: false
        });

        //definindo o painel padrão (Inicial)
        this.panelConsultas.current.style.display = "none";
        this.panelCadastro.current.style.display = "none";

        this.filtroProximidade();
        this.selectPesquisasMercado();

        const responseTopology = await this.loadingAllTopoly();
        if (responseTopology.error) {
            console.log(responseTopology.error);
        }
        this.setState({
            topologyValueSelectBox: responseTopology.rows.sort(),
        });
    }

    loadingAllTopoly = async () => {
        const uri = `${this.config.appurl}admin/pesquisas/topologias`;
        const settings = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            }
        };
        const getAllTopology = await fetch(uri, settings);
        return getAllTopology.json()
    }

    handleChange = (event) => {
        if (event.target.name === 'coluna') {
            this.setState({
                valor: ''
            });
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDateChange = (data, campo) => {
        this.setState({ [campo]: data });
    }

    includeExpressions = async (event) => {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            // form is invalid! so we show error messages
            this.setState({ setValidated: true });
            return;
        }
        // form is valid! We can parse and submit data
        this.setState({ setValidated: false });

        //{expressao:"Tipologia Igual a CASA",acoes:1},

        const setid = this.state.tableDataFilter.length > 0 ?
            this.state.tableDataFilter.length + 1 : 1;

        const new_exression = {
            id: setid,
            coluna: this.state.coluna,
            operador: this.state.operador,
            valor: this.state.valor
        }

        this.setState({
            tableDataFilter: [...this.state.tableDataFilter, new_exression]
        });
    }

    removeGraphic = (id) => {
        const graphics = this.props.view.graphics;
        const indexIvalid = -1;
        const poligonos = this.state.tableDataFilter.filter(({ coluna }) => coluna === 'poligono');
        const poligonyIndex = poligonos.findIndex(({ id: index }) => index === id);
        const notFound = poligonyIndex === indexIvalid;
        if (notFound) {
            console.log('Não foi encontrado o poligono');
            return;
        }
        const poligony = graphics.items.find((_, index) => index === poligonyIndex);
        graphics.remove(poligony);
    }

    excludeExpression = async (id) => {
        if (this.state.tableDataFilter.length > 0) {
            const { coluna } = this.state.tableDataFilter.filter(item => item.id === id)[0];
            if (coluna === 'poligono') {
                this.removeGraphic(id);
            }
            this.setState({
                tableDataFilter: this.state.tableDataFilter.filter(item => item.id !== id),
                //resultsFP: []
            });
        }
    }

    handleFilter = async () => {

        let today = new Date();
        let day = today.getDate() >= 9 ? today.getDate() : "0" + today.getDate();
        let month = today.getMonth() >= 9 ? today.getMonth() + 1 : "0" + Number(today.getMonth() + 1);

        let geti;
        if (this.state.datainicial) {
            let dayi = this.state.datainicial.getDate() >= 9 ? this.state.datainicial.getDate() : "0" + this.state.datainicial.getDate();
            let monthi = this.state.datainicial.getMonth() >= 9 ? this.state.datainicial.getMonth() + 1 : "0" + Number(this.state.datainicial.getMonth() + 1);
            geti = dayi + "/" + monthi + "/" + this.state.datainicial.getFullYear();
        } else {
            geti = day + "/" + month + "/" + today.getFullYear();
        }

        let getf;
        if (this.state.datafinal) {
            let dayf = this.state.datafinal.getDate() >= 9 ? this.state.datafinal.getDate() : "0" + this.state.datafinal.getDate();
            let monthf = this.state.datafinal.getMonth() >= 9 ? this.state.datafinal.getMonth() + 1 : "0" + Number(this.state.datafinal.getMonth() + 1);
            getf = dayf + "/" + monthf + "/" + this.state.datafinal.getFullYear();
        } else {
            getf = day + "/" + month + "/" + today.getFullYear();
        }

        const dataForm = {
            datainicial: geti,
            datafinal: getf,
            filtro: this.state.tableDataFilter,
            uemail: localStorage.getItem('email'),
            usalt: localStorage.getItem('salt')
        };


        fetch(this.config.appurl + "admin/pesquisas/filtrar", {
            method: 'POST',
            body: JSON.stringify(dataForm),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                //loading
                this.setState(
                    {
                        alertMessageStatus: true,
                        alertMessage: <section><strong>Carregando...</strong><br /><ClipLoader size={28} color={"#2a3850"} /></section>,
                        alertType: 'primary',
                        btnDisabled: true
                    }
                )
            )
            .then(
                (response) => {
                    if (response.error) {
                        this.setState(
                            {
                                alertMessageStatus: true,
                                alertMessage: ReactHtmlParser(response.message),
                                alertType: 'danger',
                                btnDisabled: false
                            }
                        );
                    } else {
                        this.setState(
                            {
                                alertMessageStatus: true,
                                alertMessage: ReactHtmlParser("<strong>Resultados Gerados com Sucesso!</strong>"),
                                alertType: 'success',
                                btnDisabled: false,
                                tableDataResults: response.pesquisas,
                                sum_areas: response.sum_areas,
                                sum_ponderada: response.sum_ponderada,
                                ponderada: response.ponderada
                            }
                        );

                        //console.log(response)

                        setTimeout(() => {
                            this.setState({ alertMessageStatus: false })
                        }, 3000);

                        this.showConsultas(response.pesquisas);

                    }
                },
                (error) => {
                    this.setState({
                        alertMessageStatus: true,
                        alertMessage: "Ops aconteceu um erro na operação: " + error,
                        alertType: 'danger'
                    });
                }
            )
    }

    selectPesquisasMercado = async () => {

        /** ESRI */
        const [FeatureLayer] = await loadModules([
            'esri/layers/FeatureLayer'
        ], { css: true });

        let main_view = this.props.view;
        let pesquisas;

        //recupera todas as layers e sublayers
        let findLayer = main_view.map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        }).find(function (layer) {
            //console.log(layer.title)
            return layer.title === "Agrupamento das Pesquisas de Mercado";
        });

        if (findLayer) {
            pesquisas = findLayer;
        } else {
            pesquisas = new FeatureLayer({
                title: "Agrupamento das Pesquisas de Mercado",
                url: "https://arcgis.tecgeobr.com.br/server/rest/services/amostras_ofertas_14122021/FeatureServer",
                renderer: {
                    type: "simple",
                    field: "mag",
                    symbol: {
                        type: "simple-marker",
                        size: 4,
                        color: "#69dcff",
                        outline: {
                            color: "rgba(0, 139, 174, 0.5)",
                            width: 5
                        }
                    }
                }
            });

            main_view.map.add(pesquisas);
        }

        if (pesquisas) {
            pesquisas
                .when()
                .then(this.generateClusterConfig)
                .then(async (featureReduction) => {

                    // sets generated cluster configuration on the layer
                    pesquisas.featureReduction = featureReduction;

                    // the layer view is needed for querying clusters
                    await main_view.whenLayerView(pesquisas);

                    // Disable clustering when user zooms beyond a 1:50,000 scale level
                    // Re-enable clustering when the user zooms out to a scale smaller than 1:50,000
                    main_view.watch("scale", function (scale) {
                        pesquisas.featureReduction = main_view.scale > 6000 ? featureReduction : null;
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    }

    generateClusterConfig = async () => {
        return {
            type: "cluster",
            popupTemplate: {
                title: "Resumo",
                content: "Este agrupamento possui {cluster_count} pesquisas."
            },
            clusterRadius: "100px",
            clusterMinSize: "24px",
            clusterMaxSize: "60px",
            labelingInfo: [{
                //deconflictionStrategy: "none",
                labelExpressionInfo: {
                    expression: "Text($feature.cluster_count, '#,###')"
                },
                symbol: {
                    type: "text",
                    color: "#004a5d",
                    font: {
                        weight: "bold",
                        family: "Noto Sans",
                        size: "12px"
                    }
                },
                labelPlacement: "center-center",
            }]
        };
    }

    filtroProximidade = async () => {
        /**
         * Incluir ferramenta de desenho
         * Se transmissões tiverem os geocodigos na região desenhada
         */

        /** ESRI */
        const [SketchViewModel, GraphicsLayer] = await loadModules([
            "esri/widgets/Sketch/SketchViewModel",
            "esri/layers/GraphicsLayer",
        ], { css: true });

        //Desenhar Poligono
        this.proximidade = new GraphicsLayer();
        // console.log(this.proximidade);


        this.sketch = new SketchViewModel({
            layer: this.proximidade,
            view: this.props.view,
            polygonSymbol: {
                type: "simple-fill",
                style: "cross",
                color: "#c00812",
                outline: {
                    width: 3,
                    style: "solid",
                    color: "#c00812"
                }
            }
        });


        this.polycoord = [];

        this.sketch.on("create", this.onGraphicCreate);

        try {
            if (this.startButtonsPM) {
                this.startButtonsPM.current.style.display = "block";
            }

            if (this.actionButtonsPM) {
                this.actionButtonsPM.current.style.display = "none";
            }
        } catch (e) {
            console.log(e)
        }

    }

    onGraphicCreate = async (event) => {
        if (event.state === "complete") {
            this.polycoord.push(event);
            this.startButtonsPM.current.style.display = "none";
            this.actionButtonsPM.current.style.display = "block";
            this.setState({ doneButtonFP: false });
            if (event.graphic) {
                // console.log({ graphic: event.graphic });
                this.props.view.graphics.add(event.graphic);
                this.setState({
                    poligonos: [
                        ...this.state.poligonos,
                        event.graphic
                    ]
                });
                this.selectFeaturesPesquisaMercado(event.graphic.geometry)
            }
        }
    }

    selectFeaturesPesquisaMercado = async (geometry) => {
        /** ESRI */
        const [FeatureLayer, Query, Graphic] = await loadModules([
            'esri/layers/FeatureLayer',
            "esri/tasks/support/Query",
            "esri/Graphic",
        ], { css: true });

        let lotes_siat = new FeatureLayer({
            url: "https://arcgis.tecgeobr.com.br/server/rest/services/amostras_ofertas_14122021/FeatureServer",
        });

        lotes_siat.load();

        this.polycoord = [];

        const query = new Query();
        query.where = "";
        query.geometry = geometry;
        query.outFields = ["f14_geocode"];

        const finalResult = await lotes_siat.queryFeatures(query);
        const geocodesFP = finalResult.features
            .map(({ attributes }) => attributes.f14_geocode);
        // for (let i = 0; i < finalResult.features.length; i++) {
        //     geocodesFP.push(finalResult.features[i].attributes.f14_geocode)
        // }

        //this.resultsFP = [...new Set(featuresRes)]
        this.setState({
            resultsFP: [...this.state.resultsFP, geocodesFP]
        });
        //console.log(this.state.resultsFP)

    }

    drawPolygonFiltroProximidade = () => {
        if (this.polycoord.length >= 1) {
            this.sketch.cancel();
        } else {
            this.sketch.create("polygon");
        }
        this.startButtonsPM.current.style.display = "none";
        this.actionButtonsPM.current.style.display = "block";
        this.setState({ doneButtonFP: true });
    }

    cancelFiltroProximidade = () => {
        this.sketch.cancel();
        this.startButtonsPM.current.style.display = "block";
        this.actionButtonsPM.current.style.display = "none";
    }

    doneFiltroProximidade = () => {

        let setid = 1;
        if (this.state.tableDataFilter.length > 0) {
            setid = this.state.tableDataFilter.length + 1;
        }

        const new_exression = {
            id: setid,
            coluna: "poligono",
            operador: "=",
            valor: this.state.resultsFP[this.state.resultsFP.length - 1].length + " Feições",
            geocodigos: this.state.resultsFP[this.state.resultsFP.length - 1]
        }

        this.setState({
            tableDataFilter: [...this.state.tableDataFilter, new_exression]
        });

        this.sketch.complete();
        try {
            if (this.startButtonsPM) {
                this.startButtonsPM.current.style.display = "block";
            }

            if (this.actionButtonsPM) {
                this.actionButtonsPM.current.style.display = "none";
            }
        } catch (e) {
            console.log(e)
        }

    }

    handleZoom = async (where) => {
        /**
         * Zoom nos resultados da pesquisa
         * Pintar todos os lotes pesquisados
         * Posicionar extent na pesquisa
         */
        //console.log(where)

        /** ESRI 
        const [Graphic] = await loadModules([
            "esri/Graphic"
        ], { css: true });*/

        let main_view = this.props.view;
        let pesquisas;

        //recupera todas as layers e sublayers
        let findLayer = main_view.map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        }).find(function (layer) {
            //console.log(layer.title)
            return layer.title === "Agrupamento das Pesquisas de Mercado";
        });


        pesquisas = findLayer;
        pesquisas.definitionExpression = where;
        pesquisas.renderer = {
            type: "simple",
            field: "mag",
            symbol: {
                type: "simple-marker",
                size: 4,
                color: "#e26161",
                outline: {
                    color: "rgba(255, 6, 6, 0.5)",
                    width: 5
                }
            }
        }

        const queryParams = pesquisas.createQuery();
        queryParams.where = where;
        queryParams.outSpatialReference = main_view.spatialReference;
        queryParams.returnGeometry = true;
        queryParams.returnQueryGeometry = true;

        //implementa o highlight
        // main_view.graphics = null;

        //Zoom nos resultados
        pesquisas.queryExtent(queryParams).then(function (results) {
            main_view.goTo(results.extent);
        });

    }

    handleExport = async () => {
        console.log("Exportar resultados para CSV")
    }

    showConsultas = async (dados) => {

        this.panelConsultas.current.style.display = "block";
        this.panelFilter.current.style.display = "none";
        this.panelCadastro.current.style.display = "none";

        /**
        * Pegar todos os resultados da pesquisa e pintar os lotes
        */

        //console.log(dados)

        let make_where = "";

        if (typeof dados !== "undefined") {
            if (dados.length > 0) {
                //var last = dados.pop()
                for (let i = 0; i < dados.length; i++) {
                    if (dados.length > 1) {
                        make_where += " f14_geocode = '" + dados[i].f14_geocode + "' OR "
                    }
                }
                make_where += " f14_geocode = '" + dados[dados.length - 1].f14_geocode + "' ";
                this.handleZoom(make_where)
            }
        }
        //console.log(make_where)
    }

    clearFilters = async () => {
        this.props.view.graphics = null;
        this.setState(
            {
                tableDataResults: [],
                tableDataFilter: [],
                sum_areas: 0,
                sum_ponderada: 0,
                ponderada: 0
            }
        );

        let main_view = this.props.view;
        let pesquisas;

        //recupera todas as layers e sublayers
        let findLayer = main_view.map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        }).find(function (layer) {
            //console.log(layer.title)
            return layer.title === "Agrupamento das Pesquisas de Mercado";
        });


        pesquisas = findLayer;
        pesquisas.definitionExpression = "1=1";
        pesquisas.renderer = {
            type: "simple",
            field: "mag",
            symbol: {
                type: "simple-marker",
                size: 4,
                color: "#69dcff",
                outline: {
                    color: "rgba(0, 139, 174, 0.5)",
                    width: 5
                }
            }
        }


        const queryParams = pesquisas.createQuery();
        queryParams.where = "1=1";
        queryParams.outSpatialReference = main_view.spatialReference;
        queryParams.returnGeometry = true;
        queryParams.returnQueryGeometry = true;

        //Zoom nos resultados
        pesquisas.queryExtent(queryParams).then(function (results) {
            main_view.goTo(results.extent);
        });


    }

    selectPesquisaFeature = async (objectId, amostras) => {
        let view = this.props.view;
        // query feature from the server
        if (this.highlight) {
            this.highlight.remove();
        }
        //console.log(objectId)
        if (!objectId) {
            return;
        }
        const settings = {
            objectIds: [objectId],
            outFields: ["*"],
            returnGeometry: true
        };
        const results = await amostras.queryFeatures(settings);
        if (results.features.length === 0) {
            return;
        }
        this.editFeature = results.features[0];
        view.whenLayerView(this.editFeature.layer).then(function (layerView) {
            this.highlight = layerView.highlight(this.editFeature);
        });
    }


    openAlertCalc = (action) => {
        this.setState({ alertCalc: action })
    }

    openModalGraficos = (action) => {
        this.setState({ modalGraficos: action })
    }

    showFilter = async () => {
        this.panelConsultas.current.style.display = "none";
        this.panelFilter.current.style.display = "block";
        this.panelCadastro.current.style.display = "none";
    }

    /**
     * Métodos do componente de cadastro de amostras
     */

    showCadastro = async () => {
        this.panelCadastro.current.style.display = "block";
        this.panelConsultas.current.style.display = "none";
        this.panelFilter.current.style.display = "none";

        this.panelCadastroTools.current.style.display = "block";
        this.panelSelectAmostra.current.style.display = "none";
        this.panelFormAmostra.current.style.display = "none";

        this.setState({
            listarAmostras: null,
            selectAmostraText: "Selecione uma amostra para editar.",
        })

        document.getElementById("tecgeo__viewDiv").style.cursor = "default";

        if (this.highlight) {
            this.highlight.remove();
        }
    }

    showFormAmostra = async (action, amostra) => {
        this.panelCadastroTools.current.style.display = "none";

        //Verificar a ação e decidir pela adição ou edição
        if (action === "edit") {
            this.setState({
                showFormAmostras: 'block',
                formAmostraText: 'Editar valores da amostra',
                listarAmostras: null,
            })
            this.panelSelectAmostra.current.style.display = "none";
            this.panelFormAmostra.current.style.display = "block";
            document.getElementById("tecgeo__viewDiv").style.cursor = "default";

            //realiza o highlight da feature e popula o formulário
            this.highlightPesquisaFeature(amostra[0].attributes.objectid, amostra[0].layer);

        } else {
            this.setState({
                formAmostraText: 'Incluir nova amostra',
                selectAmostraText: "Clique no mapa para incluir um ponto.",
                listarAmostras: null,
            })
            //antes do clique do usuário esconde o formulário
            this.panelSelectAmostra.current.style.display = "block";
            this.panelFormAmostra.current.style.display = "none";
            this.addAmostra();
        }
    }

    editAmostra = () => {
        try {
            this.setState({
                amostra_new_geometry: null,
                amostra_action: 'edit',
            });

            //Popular o formulário
            if (this.editFeature) {
                this.setState({
                    amostra_natureza: this.editFeature.attributes.f2_natureza,
                    amostra_tipologia: this.editFeature.attributes.f3_tipologi,
                    amostra_fonte: this.editFeature.attributes.f4_fonteinf,
                    amostra_endereco: this.editFeature.attributes.f7_endereco,
                    amostra_url: this.editFeature.attributes.f8_url_,
                    amostra_area_terreno: this.editFeature.attributes.f16_areater,
                    amostra_valor: this.editFeature.attributes.f39_preço_,
                });
            }

            //Implementa o drag de um novo ponto para capturar a geometria na edição
            let draggingGraphic;
            let tempGraphic;
            let newGraphic;
            let handler = this.props.view.on("drag", (event) => {
                if (event.action === 'start') {
                    this.props.view.hitTest(event).then((response) => {
                        // If a user clicks on a feature, select the feature.
                        if (response.results.length === 0) {
                            console.log("Sem resultados");
                        } else {
                            //iterar todas as layers do click no mapa
                            response.results.map((item) => {
                                //mapear apenas as layers com url das amostras
                                if (item.graphic.layer.url === 'https://arcgis.tecgeobr.com.br/server/rest/services/amostras_ofertas_14122021/FeatureServer') {
                                    // se a layer for amostra, o atributo será o objectid 
                                    if (item.graphic.attributes.objectid) {
                                        //console.log('Amostra', item)
                                        event.stopPropagation();
                                        this.props.view.graphics.removeAll();
                                        draggingGraphic = item.graphic;

                                    }
                                }
                            });
                        }
                    });
                } else if (event.action === 'update') {
                    // on drag update events, only continue if a draggingGraphic is set
                    if (draggingGraphic) {
                        event.stopPropagation();

                        // if there is a tempGraphic, remove it
                        if (tempGraphic) {
                            this.props.view.graphics.remove(tempGraphic);
                        } else {
                            // if there is no tempGraphic, this is the first update event, so remove original graphic
                            this.props.view.graphics.remove(draggingGraphic);
                        }
                        // create new temp graphic and add it
                        tempGraphic = draggingGraphic.clone();
                        tempGraphic.symbol = {
                            type: "simple-marker",
                            size: 6,
                            color: "#69dcff",
                            outline: {
                                color: "rgba(0, 139, 174, 0.5)",
                                width: 5
                            }
                        }
                        tempGraphic.geometry = this.props.view.toMap(event);
                        this.props.view.graphics.add(tempGraphic);
                    }

                } else if (event.action === 'end') {
                    document.getElementById("tecgeo__viewDiv").style.cursor = "default";
                    // on drag end, continue only if there is a draggingGraphic
                    if (draggingGraphic) {
                        event.stopPropagation();
                        // rm temp
                        if (tempGraphic) this.props.view.graphics.remove(tempGraphic);
                        // create new graphic based on original dragging graphic
                        if (newGraphic) this.props.view.graphics.remove(newGraphic);
                        newGraphic = draggingGraphic.clone();
                        newGraphic.symbol = {
                            type: "simple-marker",
                            size: 6,
                            text: "Nova Posição",
                            color: "#32a852",
                            outline: {
                                color: "rgba(36, 140, 64, 0.5)",
                                width: 5
                            }
                        }
                        newGraphic.geometry = tempGraphic.geometry.clone();

                        // add replacement graphic
                        this.props.view.graphics.add(newGraphic);

                        this.setState({
                            amostra_new_geometry: newGraphic
                        });
                        handler.remove();

                        // reset vars
                        draggingGraphic = null;
                        tempGraphic = null;
                        newGraphic = null;
                    }
                }

            })

        } catch (e) {
            console.log(e)
            this.showModalFeedbackAmostra("Erro: Não foi possível popular o formulário", true);
        }
    }

    addAmostra = async () => {
        this.setState({
            amostra_new_geometry: null,
            amostra_action: 'add',
            amostra_natureza: "",
            amostra_tipologia: "",
            amostra_fonte: "",
            amostra_endereco: "",
            amostra_url: "",
            amostra_area_terreno: "",
            amostra_valor: "",
            amostra_area_unidade: '',
        });

        this.props.view.graphics.removeAll();

        const [Graphic] = await loadModules([
            'esri/Graphic'
        ]);

        document.getElementById("tecgeo__viewDiv").style.cursor = "crosshair";

        // With the selected template item, listen for the view's click event and create feature
        const handler = this.props.view.on("click", async (event) => {
            // remove click event handler once user clicks on the view
            // to create a new feature
            handler.remove();
            event.stopPropagation();

            //limpar formulário
            let point;
            if (event.mapPoint) {
                point = event.mapPoint.clone();
                point.z = undefined;
                point.hasZ = false;

                // Create a new feature using one of the selected
                this.editFeature = new Graphic({
                    geometry: point,
                    attributes: {
                        f2_natureza: "",
                        f3_tipologi: "",
                        f4_fonteinf: "",
                        f5_datapesq: "",
                        f7_endereco: "",
                        f8_url_: "",
                        f16_areater: 0,
                        f39_preço_: 0,
                    },
                    symbol: {
                        type: "simple-marker",
                        size: 4,
                        color: "#69dcff",
                        outline: {
                            color: "rgba(0, 139, 174, 0.5)",
                            width: 5
                        }
                    },
                });

                this.props.view.graphics.add(this.editFeature);

                const formDataSearchSC = new FormData();
                formDataSearchSC.append('f', 'json');
                formDataSearchSC.append('where', "1=1");
                formDataSearchSC.append('inSR', this.editFeature.geometry.spatialReference.wkid);
                formDataSearchSC.append('outSR', this.editFeature.geometry.spatialReference.wkid);
                formDataSearchSC.append('geometryType', 'esriGeometryPoint');//esriGeometryEnvelope (Se for linhas e pontos), esriGeometryPoint (Se for poligonos)
                formDataSearchSC.append('spatialRel', 'esriSpatialRelIntersects');
                formDataSearchSC.append('geometry', this.editFeature.geometry.x + "," + this.editFeature.geometry.y);
                formDataSearchSC.append('outFields', "*");
                formDataSearchSC.append('returnGeometry', 'true');

                const url = 'https://arcgis.tecgeobr.com.br/server/rest/services/Hosted/CARTOGRAFIA/FeatureServer/4/query';

                const responseArcgis = await fetch(url, {
                    method: 'POST',
                    body: formDataSearchSC
                });

                const layerLotes = await responseArcgis.json();
                if (layerLotes.features.length > 0) {
                    const feature = layerLotes.features[0].attributes;
                    const geocode = feature.codi_cart;
                    const bairro = feature.desc_bair;
                    // console.log(feature);

                    // let dataForm = {
                    //     uemail: localStorage.getItem('email'),
                    //     usalt: localStorage.getItem('salt'),
                    //     Codi_Imov: feature.codi_imov //"450310079"
                    // }

                    // let unidades = await fetch(this.config.appurl + 'lotes/listar/unidades', {
                    //     method: 'POST',
                    //     body: JSON.stringify(dataForm),
                    //     headers: {
                    //         "Content-Type": "application/json"
                    //     }
                    // })

                    // let sgeo = await unidades.json();
                    // if (typeof sgeo.rows !== 'undefined' && sgeo.rows.length > 0) {
                    //     const row = sgeo.rows[0];
                    //     const length = row.length;

                    //     let VV_TOTAL_IMOVEL = '';
                    //     let AreaTerreno = '';
                    //     let NumeCep = '';
                    //     let AreaTotal = '';
                    //     let AreaPredial = '';
                    //     let Classificacao = '';
                        
                    //     for (let i = 0; i < length; i++) {
                    //         const { field, value } = row[i];
                    //         VV_TOTAL_IMOVEL = field === 'VV_TOTAL_IMOVEL' ? value : VV_TOTAL_IMOVEL;
                    //         AreaTerreno = field === 'Area_Terreno' ? value : AreaTerreno;
                    //         NumeCep = field === 'Nume_Cep' ? value : NumeCep;
                    //         AreaTotal = field === 'Area_Total' ? value : AreaTotal;
                    //         AreaPredial = field === 'Area_Predial' ? value : AreaPredial;
                    //         Classificacao = field === 'Classificacao' ? value : Classificacao;

                    //     }
                    //     this.setState({
                    //         amostra_area_terreno: AreaTerreno,
                    //         amostra_valor: VV_TOTAL_IMOVEL,
                    //         amostra_tipologia: Classificacao
                    //     });
                    //     this.editFeature.attributes['f16_areater'] = AreaTotal
                    //     this.editFeature.attributes['f17_arecon'] = AreaPredial
                    // }

                    this.setState({
                        amostra_natureza: feature.tipo_imove,
                        amostra_endereco: `${feature.abre_tplo} ${feature.desc_logr}, ${feature.nume_imov}`,
                    });
                    const setor = geocode.slice(0, 2);
                    const quadra = geocode.slice(2, 5);
                    const lote = geocode.slice(5, 9);
                    this.editFeature.attributes['f14_geocode'] = geocode;
                    this.editFeature.attributes['f11_setor_'] = setor;
                    this.editFeature.attributes['f12_quadra_'] = quadra;
                    this.editFeature.attributes['f13_lote_'] = lote;
                    this.editFeature.attributes['f10_bairro_'] = bairro;

                    this.panelSelectAmostra.current.style.display = "none";
                    this.panelFormAmostra.current.style.display = "block";
                    this.setState({
                        showFormAmostras: 'block',
                    })
                    document.getElementById("tecgeo__viewDiv").style.cursor = "default";
                }

            } else {
                console.error("event.mapPoint is not defined");
                this.showModalFeedbackAmostra("Erro: event.mapPoint não foi definida", true);
            }
        });

    }

    saveAmostra = async (event) => {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            // form is invalid! so we show error messages
            this.setState({ setValidated: true });
            return;
        }
        // form is valid! We can parse and submit data
        this.setState({ setValidated: false });

        //Date.now().toLocaleString("en-US", {timeZone: "America/Sao_Paulo"})
        //moment.tz(moment(Date.now()), 'America/Sao_Paulo').format('YYYY-MM-DD HH:mm:sss')
        let dNow = Date.now();
        const getSite = /^https?:\/\/([a-z\.]*).*/
        //set atributos em editfeature
        this.editFeature.attributes.f2_natureza = this.state.amostra_natureza;
        this.editFeature.attributes.f3_tipologi = this.state.amostra_tipologia;
        this.editFeature.attributes.f4_fonteinf = this.state.amostra_fonte;
        this.editFeature.attributes.f9_site_ = this.state.amostra_url.replace(getSite, (_, site) => site);
        this.editFeature.attributes.f7_endereco = this.state.amostra_endereco;
        this.editFeature.attributes.f8_url_ = this.state.amostra_url;
        this.editFeature.attributes.f16_areater = this.state.amostra_area_terreno;
        this.editFeature.attributes.f39_preço_ = this.state.amostra_valor;
        this.editFeature.attributes.f17_areacon = this.state.amostra_area_unidade;
        this.editFeature.attributes.f6_tipopesq  = 'OFERTA';
        this.editFeature.attributes.f5_datapesq = dNow.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });

        //se amostra_new_geometry existe inclui no array do apply edits
        if (this.state.amostra_new_geometry) {
            this.editFeature.geometry = this.state.amostra_new_geometry.geometry;
            console.log(this.state.amostra_new_geometry.geometry);
        }

        let edits;
        if (this.state.amostra_action === "edit") {
            edits = {
                updateFeatures: [this.editFeature],
            };
        } else {
            edits = {
                addFeatures: [this.editFeature],
            };
        }

        this.applyEdits(edits);
        document.getElementById("tecgeo__viewDiv").style.cursor = "default";

    }

    loadingView = async () => {
        this.props.view.goTo({ zoom: 16 }, { duration: 500 });
    }

    applyEdits = async (params) => {

        const [FeatureLayer] = await loadModules([
            "esri/layers/FeatureLayer"
        ]);

        const featureLayer = new FeatureLayer({
            url: "https://arcgis.tecgeobr.com.br/server/rest/services/amostras_ofertas_14122021/FeatureServer",
            outFields: ["*"],
        });

        featureLayer
            .applyEdits(params)
            .then((editsResult) => {
                //console.log("Resultado da Edição", editsResult)
                //se foi adição
                if (editsResult.addFeatureResults.length === 1) {
                    if (editsResult.addFeatureResults[0].error) {
                        this.showModalFeedbackAmostra("Erro: Aconteceu um erro na inserção da amostra", true);
                    } else {
                        this.showModalFeedbackAmostra("Amostra Salva com sucesso!", true);
                        this.cancelarFormularioAmostra();
                    }
                }

                //se foi Edição
                if (editsResult.updateFeatureResults.length === 1) {
                    if (editsResult.updateFeatureResults[0].error) {
                        this.showModalFeedbackAmostra("Erro: Aconteceu um erro na edição da amostra", true);
                    } else {
                        this.showModalFeedbackAmostra("Amostra Editada com sucesso!", true);
                        this.cancelarFormularioAmostra();
                    }
                }

                //se foi deleção
                if (editsResult.deleteFeatureResults.length === 1) {
                    if (editsResult.deleteFeatureResults[0].error) {
                        this.showModalFeedbackAmostra("Erro: Aconteceu um erro na inserção da amostra", true);
                    } else {
                        this.showModalFeedbackAmostra("Amostra Removida com sucesso!", true);
                        this.cancelarFormularioAmostra();
                        this.showModalDeletarAmostra(false)
                    }
                }
            })
            .catch((error) => {
                console.log("error = ", error);
                this.showModalFeedbackAmostra("Erro: Aconteceu um erro no apply edits", true);
            });

        //recarregar a layer após as edições
        this.loadingView()
    }

    deleteAmostra = () => {
        //console.log("editFeature selecionada",this.editFeature);
        if (this.editFeature) {
            const edits = {
                deleteFeatures: [this.editFeature]
            };

            this.applyEdits(edits);
        } else {
            console.log("Não foi possível deletar a amostra pois ela não existe")
            this.showModalFeedbackAmostra("Erro: Não foi possível deletar a amostra pois ela não existe", true);
            this.cancelarFormularioAmostra();
        }

        document.getElementById("tecgeo__viewDiv").style.cursor = "default";
    }

    showModalDeletarAmostra = (action) => {
        this.setState({ amostra_modal_deletar: action })
    }

    cancelarFormularioAmostra = () => {
        //Limpar formulário e voltar a tela de seleção
        this.setState({
            amostra_natureza: "",
            amostra_tipologia: "",
            amostra_fonte: "",
            amostra_endereco: "",
            amostra_url: "",
            amostra_area_terreno: "",
            amostra_valor: "",
        });

        this.showCadastro();
    }

    showModalFeedbackAmostra = (msg, action) => {
        this.setState({
            amostra_modal_feedback: action,
            amostra_modal_feedback_msg: msg,
        })
    }

    selectAmostra = async () => {
        this.panelCadastroTools.current.style.display = "none";
        this.panelSelectAmostra.current.style.display = "block";
        this.panelFormAmostra.current.style.display = "none";

        this.selectExistingFeature();
    }

    setListAmostras = (amostras) => {
        this.panelSelectAmostra.current.style.display = "block";
        this.panelFormAmostra.current.style.display = "none";
        //console.log("Lista", amostras)

        try {
            if (amostras.length > 0) {

                this.setState({
                    selectAmostraText: `Selecione uma das ${amostras.length} amostras para editar`,
                })

                let list = <section>
                    <ListGroup className='listAmostras'>
                        {
                            amostras.map((item) => {
                                return <ListGroup.Item key={item.attributes.objectid} action onClick={() => this.showFormAmostra("edit", [item])}>{`Amostra ${item.attributes.objectid}`}</ListGroup.Item>;
                            })
                        }
                    </ListGroup>
                </section>;

                this.setState({ listarAmostras: list });
            }

        } catch (e) {
            console.log(e)
            this.showModalFeedbackAmostra("Erro: Não foi possível gerar a lista de amostras", true);
        }
    }

    // Check if a user clicked on a feature.
    selectExistingFeature = () => {
        let amostras = [];
        this.props.view.on("click", (event) => {
            //handler.remove();
            event.stopPropagation();
            amostras = [];
            if (document.getElementById("tecgeo__viewDiv").style.cursor != "crosshair") {
                this.props.view.hitTest(event).then((response) => {
                    // If a user clicks on a feature, select the feature.
                    if (response.results.length === 0) {
                        amostras = [];
                        console.log("Sem resultados");
                    } else {
                        //iterar todas as layers do click no mapa
                        response.results.map((item) => {
                            //mapear apenas as layers com url das amostras
                            if (item.graphic.layer.url === 'https://arcgis.tecgeobr.com.br/server/rest/services/amostras_ofertas_14122021/FeatureServer') {

                                // se a layer for amostra, o atributo será o objectid 
                                if (item.graphic.attributes.objectid) {
                                    // console.log('Amostra', item)
                                    amostras.push(item.graphic)
                                }
                                // se a layer for cluster, o atributo será o cluster_count, mostrar popup com o valores do cluster
                                else if (item.graphic.attributes.cluster_count) {
                                    console.log('Cluster com', item.graphic.attributes.cluster_count)
                                }
                                else {
                                    amostras = [];
                                }
                            }
                        });

                        //se length de amostras for maior que um monta uma lista de botões de acesso as layers
                        if (amostras.length > 1) {
                            this.setListAmostras(amostras)
                        }
                        //se for apenas uma amostra abre o formulário com a função a baixo
                        else if (amostras.length === 1) {
                            this.showFormAmostra("edit", amostras)
                        } else {
                            amostras = [];
                        }
                    }

                });
            }
        });
    }

    highlightPesquisaFeature = async (objectId, amostra) => {
        let view = this.props.view;
        // query feature from the server
        if (this.highlight) {
            this.highlight.remove();
        }

        if (objectId) {
            amostra.queryFeatures({
                objectIds: [objectId],
                outFields: ["*"],
                returnGeometry: true
            })
                .then((results) => {
                    if (results.features.length > 0) {
                        this.editFeature = results.features[0];

                        //popular o formulário aqui
                        this.editAmostra();

                        //highlight the feature on the view
                        view.whenLayerView(this.editFeature.layer).then((layerView) => {
                            this.highlight = layerView.highlight(this.editFeature);
                        });
                    }
                });
        }

    }

    /**Fim dos métodos do componente de cadastros de amostras */


    closeAlertMessage = () => {
        this.setState(
            {
                alertMessageStatus: false
            }
        );
    }

    tableRowClicked = (rows) => {
        //console.log(rows)
        let make_where = "";

        if (typeof rows !== "undefined") {
            if (rows.length > 0) {
                //var last = dados.pop()
                for (let i = 0; i < rows.length; i++) {
                    if (rows.length > 1) {
                        make_where += " f14_geocode = '" + rows[i].f14_geocode + "' OR "
                    }
                }
                make_where += " f14_geocode = '" + rows[rows.length - 1].f14_geocode + "' ";
                this.handleZoom(make_where)
            }
        }
    }

    componentWillUnmount() {
        if (!this.props.view) {
            return;
        }
        this.props.view.graphics.removeAll();

        const { map } = this.props.view;

        const getLayerAndSublayers = map.layers.flatten(function (item) {
            return item.layers || item.sublayers;
        })
        const layerAmostraDePesquisa = getLayerAndSublayers.find(function (layer) {
            return layer.title === "Agrupamento das Pesquisas de Mercado";
        });

        this.props.view.map.remove(layerAmostraDePesquisa);

        // const layerPoligonoProximidade = getLayerAndSublayers.find(function(layer){
        //     return layer.title === "Polígono de Proximidade";
        // });

        // this.props.view.map.remove(layerPoligonoProximidade);
    }


    render() {

        let valorInput;

        let tipologiasCount = {
            APARTAMENTO: [],
            CASA: [],
            TERRENO: [],
            BARRACO: [],
            TELHEIRO: [],
            SALA_CONJUNTO: [],
            INDUSTRIA: [],
            LOJA: [],
            DEPOSITO: [],
            COM_RESIDENCIA: [],
            OUTROS: [],
            TERRITORIAL: [],
            PREDIAL: []
        }

        const years = [];
        let today = new Date();
        let end = today.getFullYear() + 1;
        let step = 1;
        for (let i = 1990; i < end; i += step) {
            years.push(i);
        }

        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];

        switch (this.state.coluna) {
            case 'tipo_imovel':
                valorInput = <Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                    <option disabled hidden value=''>-</option>
                    <option value="PREDIAL">PREDIAL</option>
                    <option value="TERRITORIAL">TERRITORIAL</option>
                </Form.Control>
                break;
            case 'area_construida':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'area_terreno':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            case 'preco':
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;
            default:
                valorInput = <FormControl type="number" size="sm" name="valor" value={this.state.valor || ""} onChange={this.handleChange} required />
                break;

        }

        let alertcalc;
        if (this.state.alertCalc) {
            alertcalc = <Modal
                size="sm"
                show={this.state.alertCalc}
                onHide={e => this.openAlertCalc(!this.state.alertCalc)}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Atenção:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.alertCalcContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => this.openAlertCalc(!this.state.alertCalc)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        } else {
            alertcalc = null
        }

        const csvData = [
            [
                "f14_geocode",
                "f2_natureza",
                "f3_tipologi",
                "f4_fonteinf",
                "f5_datapesq",
                "f6_tipopesq",
                "f7_endereco",
                "f8_url_",
                "f9_site_",
                "f10_bairro_",
                "f11_setor_",
                "f12_quadra_",
                "f13_lote_",
                "f15_face_qu",
                "f16_areater",
                "f17_areacon",
                "f19_frentet",
                "f39_preço_",
                "f37_coord_e",
                "f38_coord_n"
            ]
        ];

        if (this.state.tableDataResults.length > 0) {
            for (let v = 0; v < this.state.tableDataResults.length; v++) {
                csvData.push([
                    this.state.tableDataResults[v].f14_geocode,
                    this.state.tableDataResults[v].f2_natureza,
                    this.state.tableDataResults[v].f3_tipologi,
                    this.state.tableDataResults[v].f4_fonteinf,
                    this.state.tableDataResults[v].f5_datapesq,
                    this.state.tableDataResults[v].f7_endereco,
                    this.state.tableDataResults[v].f8_url_,
                    this.state.tableDataResults[v].f9_site_,
                    this.state.tableDataResults[v].f10_bairro_,
                    this.state.tableDataResults[v].f11_setor_,
                    this.state.tableDataResults[v].f12_quadra_,
                    this.state.tableDataResults[v].f13_lote_,
                    this.state.tableDataResults[v].f15_face_qu,
                    this.state.tableDataResults[v].f16_areater,
                    this.state.tableDataResults[v].f17_areacon,
                    this.state.tableDataResults[v].f19_frentet,
                    this.state.tableDataResults[v].f39_preço_,
                    this.state.tableDataResults[v].f37_coord_e,
                    this.state.tableDataResults[v].f38_coord_n
                ])

                switch (this.state.tableDataResults[v].f3_tipologi) {
                    case 'APARTAMENTO':
                        tipologiasCount.APARTAMENTO.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'CASA':
                        tipologiasCount.CASA.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'BARRACO':
                        tipologiasCount.BARRACO.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'TELHEIRO':
                        tipologiasCount.TELHEIRO.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'SALA/CONJUNTO':
                        tipologiasCount.SALA_CONJUNTO.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'INDÚSTRIA':
                        tipologiasCount.INDUSTRIA.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'LOJA':
                        tipologiasCount.LOJA.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'DEPÓSITO':
                        tipologiasCount.DEPOSITO.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'COM/RESIDÊNCIA':
                        tipologiasCount.COM_RESIDENCIA.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'TERRENO':
                        tipologiasCount.TERRENO.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    default:
                        tipologiasCount.OUTROS.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                }

                switch (this.state.tableDataResults[v].f2_natureza) {
                    case 'TERRITORIAL':
                        tipologiasCount.TERRITORIAL.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                    case 'PREDIAL':
                        tipologiasCount.PREDIAL.push(this.state.tableDataResults[v].f14_geocode)
                        break;
                }


            }

        }

        let excelDataResumo = [
            {
                soma_areas: this.state.sum_areas ? this.state.sum_areas.toFixed(2) + " m²" : 0 + " m²",
                soma_ponderacao: this.state.sum_ponderada ? this.state.sum_ponderada.toFixed(2) : 0,
                valor_medio_ponderado: this.state.ponderada ? this.state.ponderada.toFixed(2) : 0
            }
        ];

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        let ExcelSheetGen =
            <ExcelFile element={<Dropdown.Item eventKey="1" onClick={() => this.handleExport()}>Para Excel</Dropdown.Item>}>
                <ExcelSheet data={this.state.tableDataResults} name="Pesquisas de Mercado">
                    <ExcelColumn label="Geocode" value="f14_geocode" />
                    <ExcelColumn label="Natureza" value="f2_natureza" />
                    <ExcelColumn label="Tipologia" value="f3_tipologi" />
                    <ExcelColumn label="Fonte" value="f4_fonteinf" />
                    <ExcelColumn label="Data da Pesquisa" value="f5_datapesq" />
                    <ExcelColumn label="Tipo" value="f6_tipopesq" />
                    <ExcelColumn label="Endereço" value="f7_endereco" />
                    <ExcelColumn label="URL" value="f8_url_" />
                    <ExcelColumn label="Site" value="f9_site_" />
                    <ExcelColumn label="Bairro" value="f10_bairro_" />
                    <ExcelColumn label="Setor" value="f11_setor_" />
                    <ExcelColumn label="Quadra" value="f12_quadra_" />
                    <ExcelColumn label="Lote" value="f13_lote_" />
                    <ExcelColumn label="Face de Quadra" value="f15_face_qu" />
                    <ExcelColumn label="Área do Terreno" value="f16_areater" />
                    <ExcelColumn label="Área Construída" value="f17_areacon" />
                    <ExcelColumn label="Testada" value="f19_frentet" />
                    <ExcelColumn label="Valor" value="f39_preço_" />
                    <ExcelColumn label="Coord E" value="f37_coord_e" />
                    <ExcelColumn label="Coord N" value="f38_coord_n" />

                </ExcelSheet>
                <ExcelSheet data={excelDataResumo} name="Quadro Resumo">
                    <ExcelColumn label="Soma total das Áreas - AI (m²)" value="soma_areas" />
                    <ExcelColumn label="Soma da Ponderação (AI x VM)" value="soma_ponderacao" />
                    <ExcelColumn label="Valor Médio Ponderado (VMP)" value="valor_medio_ponderado" />
                </ExcelSheet>

            </ExcelFile>

        //console.log(this.state.tableDataResults)

        const dataChart1 = {
            labels: [
                'APARTAMENTO', 'CASA', 'TERRENO', 'BARRACO', 'TELHEIRO', 'SALA/CONJUNTO', 'INDÚSTRIA',
                'LOJA', 'DEPÓSITO', 'COM/RESIDÊNCIA', 'OUTROS'
            ],
            datasets: [
                {
                    label: 'Tipologia',
                    data: [
                        tipologiasCount.APARTAMENTO.length,
                        tipologiasCount.CASA.length,
                        tipologiasCount.TERRENO.length,
                        tipologiasCount.BARRACO.length,
                        tipologiasCount.TELHEIRO.length,
                        tipologiasCount.SALA_CONJUNTO.length,
                        tipologiasCount.INDUSTRIA.length,
                        tipologiasCount.LOJA.length,
                        tipologiasCount.DEPOSITO.length,
                        tipologiasCount.COM_RESIDENCIA.length,
                        tipologiasCount.OUTROS.length,
                    ],
                    backgroundColor: [
                        'rgba(13, 179, 158, 0.2)',
                        'rgba(249, 65, 68, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 25, 19, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(45, 45, 99, 0.2)',
                        'rgba(248, 150, 30, 0.2)',
                        'rgba(132, 165, 157, 0.2)',
                        'rgba(116, 0, 184, 0.2)',
                        'rgba(50, 48, 49, 0.2)',
                        'rgba(115, 93, 120, 0.2)',
                    ],
                    borderColor: [
                        'rgba(13, 179, 158, 1)',
                        'rgba(249, 65, 68, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 25, 19, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(45, 45, 99, 1)',
                        'rgba(248, 150, 30, 1)',
                        'rgba(132, 165, 157, 1)',
                        'rgba(116, 0, 184, 1)',
                        'rgba(50, 48, 49, 1)',
                        'rgba(115, 93, 120, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }

        const dataChart2 = {
            labels: ['Tipo de Imóvel'],
            datasets: [
                {
                    label: 'PREDIAL',
                    data: [tipologiasCount.PREDIAL.length],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1
                },
                {
                    label: 'TERRITORIAL',
                    data: [tipologiasCount.TERRITORIAL.length],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1
                }

            ],
        }

        const optionsCharts = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        }

        let modalgraficos;
        if (this.state.modalGraficos) {
            modalgraficos = <Modal
                show={this.state.modalGraficos}
                onHide={e => this.openModalGraficos(!this.state.modalGraficos)}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Gráficos:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="secondary" className="rowSpacing">
                        Pesquisas de Mercado por Tipologia
                    </Alert>
                    <section>
                        <Pie data={dataChart1} />
                    </section>
                    <hr />
                    <Alert variant="secondary" className="rowSpacing">
                        Pesquisas de Mercado por Tipo de Imóvel
                    </Alert>
                    <section>
                        <Bar data={dataChart2} options={optionsCharts} />
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={e => this.openModalGraficos(!this.state.modalGraficos)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        } else {
            modalgraficos = null
        }

        let modaldeletaramostra;
        if (this.state.amostra_modal_deletar) {
            modaldeletaramostra = <Modal
                show={true}
                onHide={() => this.showModalDeletarAmostra(false)}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Deseja mesmo excluir esta amostra?</Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.showModalDeletarAmostra(false)}>
                        Não
                    </Button>
                    <Button className="danger" onClick={() => this.deleteAmostra()}>
                        Sim
                    </Button>

                </Modal.Footer>
            </Modal>
        } else {
            modaldeletaramostra = null;
        }

        let modalfeedbackamostra;
        if (this.state.amostra_modal_feedback) {
            modalfeedbackamostra = <Modal
                show={true}
                onHide={() => this.showModalFeedbackAmostra("", false)}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Mensagem</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <strong>{this.state.amostra_modal_feedback_msg}</strong>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.showModalFeedbackAmostra("", false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        } else {
            modalfeedbackamostra = null;
        }

        return (
            <section id="pesquisaMercadoContainer">

                <ButtonGroup size="sm">
                    <Button variant="secondary" onClick={() => this.showFilter()}> Filtrar</Button>
                    <Button disabled={this.state.btnDisabled} variant="danger" onClick={() => this.showConsultas()}>Resultados</Button>
                    <Button variant="success" onClick={() => this.showCadastro()}>Cadastrar</Button>
                </ButtonGroup>

                <section className="pesquisasRowSpacing" ref={this.panelFilter}>

                    <Form onSubmit={e => this.includeExpressions(e)} noValidate className={this.state.setValidated ? 'is-invalid was-validated form-control:invalid' : ''}>
                        <Alert variant={this.state.alertType} show={this.state.alertMessageStatus} onClose={() => this.closeAlertMessage()} dismissible>
                            {this.state.alertMessage}
                        </Alert>
                        <Row>
                            <Col>
                                {/*<FormControl size="sm" as={CurrencyFormat} format="##/##/####" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']} name="datainicial" value={this.state.datainicial || this.state.todayis} onChange={this.handleChange} placeholder={this.state.todayis} required />*/}
                                <Form.Label><strong>Data Inicial</strong></Form.Label>
                                <FormControl size="sm" as={DatePicker}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >

                                            <Button size="sm" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                <MdNavigateBefore size={16} color="#fafafa" />
                                            </Button>
                                            <Form.Control as="select" size="sm"
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Form.Control as="select" size="sm"
                                                value={months[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Button size="sm" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                <MdNavigateNext size={16} color="#fafafa" />
                                            </Button>
                                        </div>
                                    )}
                                    locale="pt"
                                    withPortal
                                    selected={this.state.datainicial}
                                    dateFormat="dd/MM/yyyy"
                                    name="datainicial"
                                    value={this.state.datainicial}
                                    onChange={date => this.handleDateChange(date, 'datainicial')}
                                    required
                                />
                            </Col>
                            <Col>
                                {/*<FormControl size="sm" as={CurrencyFormat} format="##/##/####" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}  name="datafinal" value={this.state.datafinal || this.state.todayis} onChange={this.handleChange} placeholder={this.state.todayis} required />*/}
                                <Form.Label><strong>Data Final</strong></Form.Label>
                                <FormControl size="sm" as={DatePicker}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button size="sm" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                <MdNavigateBefore size={16} color="#fafafa" />
                                            </Button>
                                            <Form.Control as="select" size="sm"
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Form.Control as="select" size="sm"
                                                value={months[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </Form.Control>

                                            <Button size="sm" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                <MdNavigateNext size={16} color="#fafafa" />
                                            </Button>
                                        </div>
                                    )}
                                    locale="pt"
                                    withPortal
                                    selected={this.state.datafinal}
                                    dateFormat="dd/MM/yyyy"
                                    name="datafinal"
                                    value={this.state.datafinal}
                                    onChange={date => this.handleDateChange(date, 'datafinal')}
                                    required
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Coluna</Form.Label>
                                    <Form.Control as="select" size="sm" value={this.state.coluna || ''} name="coluna" onChange={this.handleChange} required>
                                        <option disabled hidden value=''>-</option>
                                        <option value="tipo_imovel">Tipo do Imóvel (Natureza)</option>
                                        <option value="tipologia">Tipologia</option>
                                        <option value="area_construida">Área Construída</option>
                                        <option value="area_terreno">Área do Terreno</option>
                                        <option value="valor">Valor da Avaliação</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Operador</Form.Label>
                                    <Form.Control as="select" size="sm" value={this.state.operador || ''} name="operador" onChange={this.handleChange} required>
                                        <option disabled hidden value=''>-</option>
                                        <option value="=">Igual</option>
                                        <option value="<>">Diferente</option>
                                        {
                                            this.state.coluna === "area_construida" ||
                                                this.state.coluna === "area_terreno" ||
                                                this.state.coluna === "valor" ? (
                                                <option value=">=">Maior ou igual</option>
                                            ) : null
                                        }
                                        {
                                            this.state.coluna === "area_construida" ||
                                                this.state.coluna === "area_terreno" ||
                                                this.state.coluna === "valor" ? (
                                                <option value="<=">Menor ou igual</option>
                                            ) : null
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Valor</Form.Label>
                                    {this.state.coluna !== 'tipologia' && valorInput}
                                    {this.state.coluna === 'tipologia' && (<Form.Control as="select" value={this.state.valor || ''} name="valor" onChange={this.handleChange} size="sm" required custom>
                                        <option disabled hidden value=''>-</option>
                                        {this.state.topologyValueSelectBox && this.state.topologyValueSelectBox.map(item => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </Form.Control>)}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button disabled={this.state.btnDisabled} variant="success" size="sm" type="submit" block>
                            <FaPlusCircle size={16} color="#fafafa" />
                            {'  '}
                            Incluir Expressão
                        </Button>

                    </Form>
                    <hr />

                    <Button ref={this.startButtonsPM} disabled={this.state.btnDisabled} onClick={() => this.drawPolygonFiltroProximidade()} variant="secondary" size="sm" block>
                        <FaDrawPolygon size={16} color="#fafafa" />
                        {'  '}
                        Pesquisa por Proximidade
                    </Button>

                    <Alert variant="secondary" ref={this.actionButtonsPM}>
                        <strong>Realize o desenho do polígono.</strong>
                        <hr />
                        <ButtonGroup size="sm">
                            <Button onClick={() => this.cancelFiltroProximidade()} variant="danger">Cancelar</Button>
                            <Button disabled={this.state.doneButtonFP} onClick={() => this.doneFiltroProximidade()} variant="success">Incluir Polígono</Button>
                        </ButtonGroup>
                    </Alert>


                    <hr />

                    <section className="pesquisaList pesquisasRowSpacing">
                        <DataTable
                            striped={true}
                            noHeader={true}
                            columns={this.columnsFilter}
                            data={this.state.tableDataFilter}
                            customStyles={this.customStylesResults}
                            progressPending={this.state.isLoaded}
                            dense
                            pagination
                            paginationComponentOptions={{ rowsPerPageText: '', rangeSeparatorText: 'de', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
                            highlightOnHover
                        />
                    </section>

                    <ButtonGroup size="sm">
                        <Button disabled={this.state.btnDisabled} onClick={() => this.clearFilters()} variant="danger">
                            <FaTrashAlt size={16} color="#fafafa" />
                            {'  '}
                            Limpar Filtros
                        </Button>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.handleFilter()} variant="success">
                            <FaSearchLocation size={16} color="#fafafa" />
                            {'  '}
                            Consultar
                        </Button>
                    </ButtonGroup>

                </section>

                <section className="pesquisasRowSpacing" ref={this.panelConsultas}>
                    <DataTable
                        dense
                        striped={true}
                        noHeader={true}
                        columns={this.columnsResults}
                        data={this.state.tableDataResults}
                        customStyles={this.customStylesResults}
                        progressPending={this.state.isLoaded}
                        progressComponent={<WidgetLoading />}
                        pagination
                        paginationTotalRows={this.state.totalRows}
                        paginationComponentOptions={{ rowsPerPageText: '', rangeSeparatorText: 'de', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'Todos' }}
                        highlightOnHover={true}
                        selectableRowsHighlight={true}
                        selectableRows
                        onSelectedRowsChange={row => this.tableRowClicked(row.selectedRows)}
                        pointerOnHover={true}
                    />


                    <hr />

                    <ButtonGroup size="sm" style={{ padding: '2px' }}>
                        <OverlayTrigger trigger="click" placement="left" overlay={<Tooltip>Soma total das Áreas dos Imóveis<br /> em m²</Tooltip>}>
                            <Button variant="secondary"> AI (m²):<br />
                                {this.state.sum_areas ? this.state.sum_areas.toFixed(2) + " m²" : 0 + " m²"}
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger trigger="click" placement="left" overlay={<Tooltip>Ponderação<br /> Área do Imóvel x Valor Metro Quadrado do Imóvel</Tooltip>}>
                            <Button variant="primary"> (AI x VM):<br />
                                <CurrencyFormat value={this.state.sum_ponderada ? this.state.sum_ponderada.toFixed(2) : 0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger trigger="click" placement="left" overlay={<Tooltip>Valor Médio Ponderado (VMP) do m² por Proximidade Espacial<br /> (Ponderação / Total AI) </Tooltip>}>
                            <Button variant="success"> (VMP):<br />
                                <CurrencyFormat value={this.state.ponderada ? this.state.ponderada.toFixed(2) : 0} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                            </Button>
                        </OverlayTrigger>
                    </ButtonGroup>
                    <hr />


                    <ButtonGroup size="sm" style={{ padding: '5px' }}>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.clearFilters()} variant="danger">
                            <FaTrashAlt size={16} color="#fafafa" />
                            {'  '}
                            Limpar
                        </Button>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.showConsultas(this.state.tableDataResults)} variant="success">
                            <FaSearchLocation size={16} color="#fafafa" />
                            {'  '}
                            Zoom
                        </Button>
                        <DropdownButton
                            disabled={this.state.btnDisabled}
                            variant="primary"
                            size="sm"
                            as={ButtonGroup}
                            title={<strong><FaFileCsv size={16} color="#fafafa" /> Exportar</strong>}
                            key={"left"}
                            id={`dropdown-button-drop-left`}
                            drop={"left"}
                        >

                            {ExcelSheetGen}
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="2" as={CSVLink} data={csvData} onClick={() => this.handleExport()} >Para CSV</Dropdown.Item>
                        </DropdownButton>
                        <Button disabled={this.state.btnDisabled} onClick={() => this.openModalGraficos(true)} variant="secondary">
                            <FaChartBar size={16} color="#fafafa" />
                            {'  '}
                            Gráficos
                        </Button>
                    </ButtonGroup>
                </section>

                {/**
                 * Componente de Cadastro de Amostras;
                 * TODO: Separar o componente
                */}
                <section id="panelCadastro" className="pesquisasRowSpacing" ref={this.panelCadastro}>
                    <div id="panelCadastroTools" ref={this.panelCadastroTools}>
                        <Button onClick={this.selectAmostra} variant="outline-primary" size="sm" block><FaMousePointer size={16} color="#b6def3" /> {' '}Editar Amostra</Button>{' '}
                        <Button onClick={() => this.showFormAmostra("create")} variant="outline-success" size="sm" block><FaPlus size={16} color="#a7cd55" /> {' '}Criar Amostra</Button>{' '}
                    </div>
                    <div id="panelSelectAmostra" ref={this.panelSelectAmostra}>
                        <strong className="panelAmostrasTitle">{this.state.selectAmostraText}</strong>
                        {this.state.listarAmostras}
                    </div>
                    <div id="panelFormAmostra" ref={this.panelFormAmostra}>
                        <strong className="panelAmostrasTitle">{this.state.formAmostraText}</strong>
                        <section style={{ display: this.state.showFormAmostras }}>
                            <hr />
                            <Form onSubmit={e => this.saveAmostra(e)} noValidate className={this.state.setValidated ? 'is-invalid was-validated form-control:invalid' : ''}>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Natureza</Form.Label>
                                            <Form.Control as="select" size="sm" value={this.state.amostra_natureza || ''} name="amostra_natureza" onChange={this.handleChange} required>
                                                <option disabled hidden value=''>-</option>
                                                <option value="TERRITORIAL">TERRITORIAL</option>
                                                <option value="PREDIAL">PREDIAL</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Tipologia</Form.Label>
                                            <Form.Control as="select" size="sm" value={this.state.amostra_tipologia || ''} name="amostra_tipologia" onChange={this.handleChange} required>
                                                <option disabled hidden value=''>-</option>
                                                <option value="APARTAMENTO">APARTAMENTO</option>
                                                <option value="CASA">CASA</option>
                                                <option value="TERRENO">TERRENO</option>
                                                <option value="SALA COMERCIAL">SALA COMERCIAL</option>
                                                <option value="GALPÃO">GALPÃO</option>
                                                <option value="BARRACO">BARRACO</option>
                                                <option value="TELHEIRO">TELHEIRO</option>
                                                <option value="EDICULA">EDICULA</option>
                                                <option value="LOJA">LOJA</option>
                                                <option value="COM/RESIDÊNCIA">COM/RESIDÊNCIA</option>
                                                <option value="POSTO DE SERVIÇO">POSTO DE SERVIÇO</option>
                                                <option value="EDF. COMERCIAL">EDF. COMERCIAL</option>
                                                <option value="INDÚSTRIA">INDÚSTRIA</option>
                                                <option value="COBERTURA METÁLICA">COBERTURA METÁLICA</option>
                                                <option value="GARAGEM">GARAGEM</option>
                                                <option value="DEPÓSITO">DEPÓSITO</option>
                                                <option value="IGREJA/TEMPLO">IGREJA/TEMPLO</option>
                                                <option value="SOBRADO">SOBRADO</option>
                                                <option value="PALAFITA">PALAFITA</option>
                                                <option value="MOCAMBO/SIMILAR">MOCAMBO/SIMILAR</option>
                                                <option value="FINANCEIRA">FINANCEIRA</option>
                                                <option value="ESCOLA/CRECHE">ESCOLA/CRECHE</option>
                                                <option value="HOTEL">HOTEL</option>
                                                <option value="CLUBE/ENTRETEN.">CLUBE/ENTRETEN.</option>
                                                <option value="POSTO DE COMBUSTÍVEL">POSTO DE COMBUSTÍVEL</option>
                                                <option value="HOSPITAL/CLINICA">HOSPITAL/CLINICA</option>
                                                <option value="EDIFICIO GARAGEM">EDIFICIO GARAGEM</option>
                                                <option value="ESPECIAL">ESPECIAL</option>
                                                <option value="OUTROS">OUTROS</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Fonte de Pesquisa</Form.Label>
                                            <Form.Control as="select" size="sm" value={this.state.amostra_fonte || ''} name="amostra_fonte" onChange={this.handleChange} required>
                                                <option disabled hidden value=''>-</option>
                                                <option value="INTERNET">INTERNET</option>
                                                <option value="NESTORIA">NESTORIA</option>
                                                <option value="IMOBILIARIA">IMOBILIARIA</option>
                                                <option value="OLX">OLX</option>
                                                <option value="SARU">SARU</option>
                                                <option value="SITE">SITE</option>
                                                <option value="CAMPO">CAMPO</option>
                                                <option value="O-CORREOTOR">O-CORREOTOR</option>
                                                <option value="OUTROS">OUTROS</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Endereço</Form.Label>
                                            <Form.Control type="text" size="sm" placeholder="Endereço" name="amostra_endereco" value={this.state.amostra_endereco || ""} onChange={this.handleChange} minLength={5} maxLength={254} required />
                                            <Form.Control.Feedback type="invalid">
                                                Defina o Endereço da amostra
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control type="text" size="sm" placeholder="URL" name="amostra_url" value={this.state.amostra_url || ""} onChange={this.handleChange} minLength={5} maxLength={254} required />
                                            <Form.Control.Feedback type="invalid">
                                                Defina a URL da amostra
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Área do Terreno (m²)</Form.Label>
                                            <Form.Control type="number" size="sm" placeholder="Área do terreno" name="amostra_area_terreno" value={this.state.amostra_area_terreno || ""} onChange={this.handleChange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Defina a área do terreno
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Área Unidade (m²)</Form.Label>
                                            <Form.Control type="number" size="sm" placeholder="Área da unidade" name="amostra_area_unidade" value={this.state.amostra_area_unidade || ""} onChange={this.handleChange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Defina a área da unidade
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Valor (R$)</Form.Label>
                                            <Form.Control type="number" size="sm" placeholder="Valor" name="amostra_valor" value={this.state.amostra_valor || ""} onChange={this.handleChange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Defina o valor da amostra
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button disabled={this.state.btnDisabled} variant="success" size="sm" type="submit" block>
                                    <FaPlusCircle size={16} color="#fafafa" />
                                    {'  '}
                                    Salvar Amostra
                                </Button>
                                <Button onClick={() => this.cancelarFormularioAmostra()} disabled={this.state.btnDisabled} variant="secondary" size="sm" block>
                                    <FaChevronLeft size={16} color="#fafafa" />
                                    {'  '}
                                    Cancelar
                                </Button>
                                <hr />
                                <Button onClick={() => this.showModalDeletarAmostra(true)} disabled={this.state.btnDisabled} variant="danger" size="sm" block>
                                    <FaRegWindowClose size={16} color="#fafafa" />
                                    {'  '}
                                    Remover Amostra
                                </Button>
                            </Form>
                        </section>
                    </div>
                </section>

                {alertcalc}
                {modalgraficos}
                {modaldeletaramostra}
                {modalfeedbackamostra}

            </section>

        );
    }
}


export default PesquisaMercado;


