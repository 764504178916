import React from 'react';
import {Button, Table, Navbar}  from "react-bootstrap";
import PageLoading from '../../components/PageLoading';
import { Redirect  } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';
import './style.css';
import Config from '../../config';

class PrintPdf extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            logged: "loading",
            error: null,
            isLoaded: true 
        };

        //Configuracoes
        this.config = Config.configs()
    }

    componentDidMount() {
        fetch(this.config.appurl+"geoportal/logged", {
          method: 'POST',
          body: JSON.stringify({email:localStorage.getItem('email'), salt:localStorage.getItem('salt')}),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => response.json())
        .then(
          //loading
          this.setState({logged: "loading"})
        )
        .then(
          (response) => {
            if(response.error){
              this.setState({logged: "no"})
              //limpar a sessão
              localStorage.clear();
            }else{
              this.setState({logged: "yes"})
              
             
             
            }
          }
        )
    }

    renderPDF = () =>{
        window.print();
    }

    populateTables = () =>{

        let attr = JSON.parse(localStorage.getItem('DadosRelatorio'));

        if(attr){
            if(attr.valormedio){
                let variaveis = attr.valormedio.variaveis;

                let Renda = variaveis.RENDA ? <CurrencyFormat value={variaveis.RENDA} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                : "*Variável Indefinida";

                //RESET CORES
                this.setState({
                    COORD_N_MODEL : "",
                    COORD_E_MODEL : "",
                    AREA_UNID_MODEL : "",
                    QTD_PAV_MODEL : "",
                    ANDAR_MODEL : "",
                    PADRAO_MODEL : "",
                    IDADE_MODEL : "",
                    TIPO_VIA_MODEL : "",
                    RENDA_MODEL : "",
                    GS_VIA_MODEL : "",
                    ESTRUTURA_MODEL : "",
                    AREA_TER_MODEL : "",
                    FRENTE_TER_MODEL : "",
                    AREA_CONST_MODEL : "",
                    PAVIMENTA_VIA_MODEL : "",
                    USO_IMOVEL_MODEL: "",
                })

                this.setState({
                    SEQUENCIAL: variaveis.SEQUENCIAL ? Number(variaveis.SEQUENCIAL).toFixed(0) : "*Variável Indefinida",
                    TIPOLOGIA: variaveis.TIPOLOGIA ? variaveis.TIPOLOGIA : "*Variável Indefinida",
                    LOGRADOURO: variaveis.LOGRADOURO ? variaveis.LOGRADOURO : "*Variável Indefinida",
                    COMPLEMENTO: variaveis.COMPLEMENTO ? variaveis.COMPLEMENTO : "*Variável Indefinida",
                    ANDAR: variaveis.ANDAR ? variaveis.ANDAR : "*Variável Indefinida",
                    QTD_PAV: variaveis.QTD_PAV ? variaveis.QTD_PAV : "*Variável Indefinida",
                    TIPO_VIA: variaveis.TIPO_VIA ? variaveis.TIPO_VIA : "*Variável Indefinida",
                    TIPO_VIA_TEXTO: variaveis.TIPO_VIA_TEXTO ? variaveis.TIPO_VIA_TEXTO : "*Variável Indefinida",
                    COORD_E: variaveis.COORD_E ? Number(variaveis.COORD_E).toFixed(2) : "*Variável Indefinida",
                    COORD_N: variaveis.COORD_N ? Number(variaveis.COORD_N).toFixed(2) : "*Variável Indefinida",
                    IDADE: variaveis.IDADE ? variaveis.IDADE : "*Variável Indefinida",
                    AREA_CONST: variaveis.AREA_CONST ? variaveis.AREA_CONST : "*Variável Indefinida",
                    AREA_TER: variaveis.AREA_TER ? variaveis.AREA_TER : "*Variável Indefinida",
                    AREA_TOTAL_CONST: variaveis.AREA_TOTAL_CONST ? variaveis.AREA_TOTAL_CONST : "*Variável Indefinida",
                    AREA_UNID: variaveis.AREA_UNID ? variaveis.AREA_UNID : "*Variável Indefinida",
                    FRENTE_TER: variaveis.FRENTE_TER ? variaveis.FRENTE_TER : "*Variável Indefinida",
                    PAVIMENTA_VIA: variaveis.PAVIMENTA_VIA ? variaveis.PAVIMENTA_VIA : "*Variável Indefinida",
                    PAVIMENTA_VIA_TEXTO: variaveis.PAVIMENTA_VIA_TEXTO ? variaveis.PAVIMENTA_VIA_TEXTO : "*Variável Indefinida",
                    USO_IMOVEL: variaveis.USO_IMOVEL ? variaveis.USO_IMOVEL : "*Variável Indefinida",
                    USO_IMOVEL_TEXTO: variaveis.USO_IMOVEL_TEXTO ? variaveis.USO_IMOVEL_TEXTO : "*Variável Indefinida",
                    ESTRUTURA: variaveis.ESTRUTURA ? variaveis.ESTRUTURA : "*Variável Indefinida",
                    ESTRUTURA_TEXTO: variaveis.ESTRUTURA_TEXTO ? variaveis.ESTRUTURA_TEXTO : "*Variável Indefinida",
                    GS_VIA: variaveis.GS_VIA ? variaveis.GS_VIA : "*Variável Indefinida",
                    GS_VIA_TEXTO: variaveis.GS_VIA_TEXTO ? variaveis.GS_VIA_TEXTO : "*Variável Indefinida",
                    NATUREZA: variaveis.NATUREZA ? variaveis.NATUREZA : "*Variável Indefinida",
                    PADRAO: variaveis.PADRAO ? variaveis.PADRAO : "*Variável Indefinida",
                    PADRAO_TEXTO: variaveis.PADRAO_TEXTO ? variaveis.PADRAO_TEXTO : "*Variável Indefinida",
                    RENDA: Renda,
                    btnZoomGeocode:variaveis.GEOCODE
                })

               

                /**Definir as cores das variáveis que são essenciais no modelo */
                if(variaveis.MODELO === "CalcAptAcima4"){
                    this.setState({
                        COORD_N_MODEL : "headTableBlue",
                        COORD_E_MODEL : "headTableBlue",
                        AREA_UNID_MODEL : "headTableBlue",
                        QTD_PAV_MODEL : "headTableBlue",
                        ANDAR_MODEL : "headTableBlue",
                        PADRAO_MODEL : "headTableBlue",
                        IDADE_MODEL : "headTableBlue",
                        TIPO_VIA_MODEL : "headTableBlue",
                        RENDA_MODEL : "headTableBlue"
                    })
                }

                if(variaveis.MODELO === "CalcAptAte4"){
                    this.setState({
                        COORD_E_MODEL : "headTableBlue",
                        AREA_UNID_MODEL : "headTableBlue",
                        QTD_PAV_MODEL : "headTableBlue",
                        ANDAR_MODEL : "headTableBlue",
                        PADRAO_MODEL : "headTableBlue",
                        IDADE_MODEL : "headTableBlue",
                        GS_VIA_MODEL : "headTableBlue",
                        RENDA_MODEL : "headTableBlue",
                        ESTRUTURA_MODEL : "headTableBlue"
                    })
                }

                if(variaveis.MODELO === "CalcCasa"){
                    this.setState({
                        COORD_N_MODEL : "headTableBlue",
                        COORD_E_MODEL : "headTableBlue",
                        AREA_TER_MODEL : "headTableBlue",
                        FRENTE_TER_MODEL : "headTableBlue",
                        AREA_CONST_MODEL : "headTableBlue",
                        PADRAO_MODEL : "headTableBlue",
                        IDADE_MODEL : "headTableBlue",
                        PAVIMENTA_VIA_MODEL : "headTableBlue",
                        USO_IMOVEL_MODEL: "headTableBlue",
                        RENDA_MODEL : "headTableBlue"
                    })
                }

               
            }
            if(attr.valormedioevo){
                let variaveis_evo = attr.valormedioevo.variaveis;

                let RendaEvo = variaveis_evo.RENDA ? <CurrencyFormat value={variaveis_evo.RENDA} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                : "*Variável Indefinida";

                let CubEvo = variaveis_evo.CUB ? <CurrencyFormat value={variaveis_evo.CUB} displayType={'text'} thousandSeparator={true} prefix={'R$ '} />
                : "*Variável Indefinida";

                this.setState({
                    COORD_N_MODEL_EVO : "",
                    COORD_E_MODEL_EVO : "",
                    AREA_TER_MODEL_EVO : "",
                    FRENTE_TER_MODEL_EVO : "",
                    TIPO_VIA_MODEL_EVO : "",
                    GUIA_SARJETA_MODEL_EVO : "",
                    COEF_APROV_MODEL_EVO : "",
                    ZONA_MODEL_EVO : "",
                    ALT_MAX_CONST_MODEL_EVO: "",
                    RENDA_MODEL_EVO : "",

                    PAVIMENTOS_MODEL_EVO : "",
                    ESTADO_CONSERVACAO_MODEL_EVO : "",
                    TIPOLOGIA_MODEL_EVO : "",
                    PADRAO_MODEL_EVO : "",
                    AREA_CONST_MODEL_EVO : "",
                    IDADE_MODEL_EVO : "",
                    CUB_MODEL_EVO:"",
                    DEPRECIACAO_MODEL_EVO:""
                })

                this.setState({
                    SEQUENCIAL_EVO: variaveis_evo.SEQUENCIAL ? Number(variaveis_evo.SEQUENCIAL).toFixed(0) : "*Variável Indefinida",
                    TIPOLOGIA_EVO: variaveis_evo.TIPOLOGIA ? variaveis_evo.TIPOLOGIA : "*Variável Indefinida",
                    COORD_E_EVO: variaveis_evo.COORD_E ? Number(variaveis_evo.COORD_E).toFixed(2) : "*Variável Indefinida",
                    COORD_N_EVO: variaveis_evo.COORD_N ? Number(variaveis_evo.COORD_N).toFixed(2) : "*Variável Indefinida",
                    LOGRADOURO_EVO: variaveis_evo.LOGRADOURO ? variaveis_evo.LOGRADOURO : "*Variável Indefinida",
                    COMPLEMENTO_EVO: variaveis_evo.COMPLEMENTO ? variaveis_evo.COMPLEMENTO : "*Variável Indefinida",
                    GUIA_SARJETA_TEXTO_EVO: variaveis_evo.GUIA_SARJETA_TEXTO ? variaveis_evo.GUIA_SARJETA_TEXTO : "*Variável Indefinida",
                    GUIA_SARJETA_EVO: variaveis_evo.GUIA_SARJETA ? variaveis_evo.GUIA_SARJETA : "*Variável Indefinida",
                    PAVIMENTOS_EVO: variaveis_evo.PAVIMENTOS ? variaveis_evo.PAVIMENTOS : "*Variável Indefinida",
                    TIPO_VIA_TEXTO_EVO: variaveis_evo.TIPO_VIA_TEXTO ? variaveis_evo.TIPO_VIA_TEXTO : "*Variável Indefinida",
                    TIPO_VIA_EVO: variaveis_evo.TIPO_VIA ? variaveis_evo.TIPO_VIA : "*Variável Indefinida",
                    NATUREZA_EVO: variaveis_evo.NATUREZA ? variaveis_evo.NATUREZA : "*Variável Indefinida",
                    IDADE_EVO: variaveis_evo.IDADE ? variaveis_evo.IDADE : "*Variável Indefinida",
                    USO_IMOVEL_TEXTO_EVO: variaveis_evo.USO_IMOVEL_TEXTO ? variaveis_evo.USO_IMOVEL_TEXTO : "*Variável Indefinida",
                    ESTADO_CONSERVACAO_EVO: variaveis_evo.ESTADO_CONSERVACAO ? variaveis_evo.ESTADO_CONSERVACAO : "*Variável Indefinida",
                    PADRAO_EVO: variaveis_evo.PADRAO ? variaveis_evo.PADRAO: "*Variável Indefinida",
                    AREA_CONST_EVO: variaveis_evo.AREA_CONST ? variaveis_evo.AREA_CONST : "*Variável Indefinida",
                    AREA_TER_EVO: variaveis_evo.AREA_TER ? variaveis_evo.AREA_TER : "*Variável Indefinida",
                    AREA_TOTAL_CONST_EVO: variaveis_evo.AREA_TOTAL_CONST ? variaveis_evo.AREA_TOTAL_CONST : "*Variável Indefinida",
                    FRENTE_TER_EVO: variaveis_evo.FRENTE_TER ? variaveis_evo.FRENTE_TER : "*Variável Indefinida",
                    CUB_EVO: CubEvo,
                    DEPRECIACAO_EVO: variaveis_evo.DEPRECIACAO ? variaveis_evo.DEPRECIACAO : "*Variável Indefinida",
                    ZONA_EVO: variaveis_evo.ZONA ? variaveis_evo.ZONA : "*Variável Indefinida",
                    COEF_APROV_EVO: variaveis_evo.COEF_APROV ? variaveis_evo.COEF_APROV : "*Variável Indefinida",
                    ALT_MAX_CONST_EVO: variaveis_evo.ALT_MAX_CONST ? variaveis_evo.ALT_MAX_CONST : "*Variável Indefinida",
                    RENDA_EVO: RendaEvo,
                    btnZoomGeocode:variaveis_evo.GEOCODE
                })

                /**Definir as cores das variáveis que são essenciais no modelo */
                if(variaveis_evo.MODELO_EVO === "CalcModeloEvolutivo"){
                    this.setState({
                        COORD_N_MODEL_EVO : "headTableBlue",
                        COORD_E_MODEL_EVO : "headTableBlue",
                        AREA_TER_MODEL_EVO : "headTableBlue",
                        FRENTE_TER_MODEL_EVO : "headTableBlue",
                        TIPO_VIA_MODEL_EVO : "headTableBlue",
                        GUIA_SARJETA_MODEL_EVO : "headTableBlue",
                        COEF_APROV_MODEL_EVO : "headTableBlue",
                        ZONA_MODEL_EVO : "headTableBlue",
                        ALT_MAX_CONST_MODEL_EVO: "headTableBlue",
                        RENDA_MODEL_EVO : "headTableBlue",

                        PAVIMENTOS_MODEL_EVO : "headTablePurple",
                        ESTADO_CONSERVACAO_MODEL_EVO : "headTablePurple",
                        TIPOLOGIA_MODEL_EVO : "headTablePurple",
                        PADRAO_MODEL_EVO : "headTablePurple",
                        AREA_CONST_MODEL_EVO : "headTablePurple",
                        IDADE_MODEL_EVO : "headTablePurple",
                        CUB_MODEL_EVO:"headTablePurple",
                        DEPRECIACAO_MODEL_EVO:"headTablePurple"
                    })
                }

                
            }
           

        }
        

    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    render() {

        let content;

        if(this.state.logged === "yes"){
        content = <>
            <Navbar bg="light" variant="light">
                <Navbar.Brand href="#home">
                    <img 
                    src="/logo.png" 
                    alt="Logo PMJG"
                    width="62"
                    height="62"
                    className="d-inline-block align-center printme"
                    />
                    {' '}
                    Geoportal de ITBI | Relatório do Cálculo de Avaliação do Imóvel
                </Navbar.Brand>
                <Button className="no-printme" variant="primary" onClick={() => this.renderPDF()}>
                    Imprimir
                </Button>
            </Navbar>
            
            
            <hr/>


            <h3>Modelo Comparativo</h3>
            <hr/>
            <Table striped bordered hover size="sm" responsive="sm">
                <thead>
                    <tr>
                        <th colSpan="2">Dados para geração do modelo</th>
                    </tr>
                </thead>
                <tbody className="tableFont">
                    {/*dataTableComparativo*/}
                </tbody>
            </Table>

                    
            <Table hover striped bordered size="sm" responsive="sm">
                <thead>
                    <tr>
                        <th colSpan="4">Estimativa de valores</th>
                    </tr>
                    <tr>
                        <td>Valor</td>
                        <td className="headTableYellow ">Mínimo</td>
                        <td className="headTableGreen">Médio</td>
                        <td className="headTableRed">Máximo</td>
                    </tr>
                </thead>
                <tbody className="tableFont">
                    {/*dataTableValComparativo*/}
                </tbody>
            </Table>

            <h3>Modelo Evolutivo</h3>
            <hr/>
            <Table striped bordered hover size="sm" responsive="sm">
                <thead>
                    <tr>
                        <th colSpan="2">Dados para geração do modelo</th>
                    </tr>
                </thead>
                <tbody className="tableFont">
                    {/*dataTableEvolutivo*/}
                </tbody>
            </Table>


            <Table hover striped bordered size="sm" responsive="sm">
                <thead>
                    <tr>
                        <th colSpan="4">Estimativa de valores</th>
                    </tr>
                    <tr>
                        <td>Valor</td>
                        <td className="headTableYellow ">Mínimo</td>
                        <td className="headTableGreen">Médio</td>
                        <td className="headTableRed">Máximo</td>
                    </tr>
                </thead>
                <tbody className="tableFont">
                    {/*dataTableValEvolutivo*/}
                </tbody>
            </Table>
            
        </>
        }else if(this.state.logged === "loading"){
        content = <PageLoading />;
        }else{
        content = <Redirect push to="/"/>;
        }

        return (
        <>
            {content}
        </>
        );
    
    }  
}


export default PrintPdf;